<template xmlns:svg="http://www.w3.org/1999/xhtml">
  <svg
    viewBox="0 0 399.7 209.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <svg:style>
      {{ style }}
    </svg:style>

    <path
      d="M73.7,198.5L73.7,198.5c-39.54,0-71.6-32.06-71.6-71.6v-53.5c0-39.54,32.06-71.6,71.6-71.6h0
  c39.54,0,71.6,32.06,71.6,71.6v53.5C145.3,166.45,113.24,198.5,73.7,198.5z"
      fill="#FFFFFF"
    />
    <g>
      <g>
        <path
          clip-rule="evenodd"
          d="M266.37,105.93v9.91h2.1h0.56h1.83h0.56h1.83h0.56h1.83h0.56
      h1.82h0.56h1.83h0.56h1.83h0.56h1.83h0.56h1.83h0.56h1.82h0.56h1.82h0.56h1.82h0.56h1.83h0.56h1.83h0.56h1.83h0.56h1.82h0.56h1.82
      h0.56h1.83h0.56h1.83h0.56h2.11v-9.91H266.37z M313.53,115.34h-1.55v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-0.91h-0.56
      v0.91h-1.82v-2.33h-0.56v2.33h-1.82v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-2.33h-0.56v2.33
      h-1.82v-0.91h-0.56v0.91h-1.82v-0.91h-0.56v0.91h-1.82v-0.91h-0.56v0.91h-1.83v-2.33h-0.56v2.33h-1.83v-0.91h-0.56v0.91h-1.83
      v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.82v-2.33h-0.56v2.33h-1.83v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-0.91
      h-0.56v0.91h-1.55v-8.91h46.6V115.34z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.54,111.14c0.04,0.03,0.06,0.08,0.06,0.14
      c0.01,0.21-0.18,0.26-0.33,0.26c-0.18,0-0.35-0.08-0.34-0.28c0-0.07,0.03-0.11,0.07-0.14v-2.76c-0.04-0.03-0.04-0.09-0.04-0.13
      c0-0.2,0.16-0.27,0.3-0.27c0.15,0,0.32,0.07,0.32,0.22c0,0.08-0.03,0.13-0.04,0.15v0.84h0.01c0.05-0.08,0.14-0.19,0.29-0.32
      l0.7-0.66c0.07-0.16,0.15-0.23,0.3-0.23c0.07,0,0.3,0.06,0.3,0.24c-0.01,0.09-0.11,0.24-0.24,0.26l-0.96,0.89l1.36,1.75
      c0.11,0.02,0.19,0.11,0.19,0.17c0,0.12-0.17,0.28-0.36,0.28c-0.22,0-0.29-0.1-0.29-0.18l-1.02-1.36c-0.13-0.17-0.22-0.3-0.27-0.42
      h-0.01V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M275.25,109.52c0,1.21-0.74,2.11-1.64,2.11
      c-0.78,0-1.03-0.83-1.03-1.44c0-1.1,0.75-2.37,1.59-2.37C274.73,107.81,275.25,108.28,275.25,109.52 M273.14,110.12
      c0,0.5,0.15,1.09,0.59,1.09c0.47,0,0.98-0.72,0.98-1.67c0-0.86-0.22-1.3-0.6-1.3C273.68,108.23,273.14,109.23,273.14,110.12z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M276.36,111.14c0.02,0.06,0.04,0.13,0.04,0.18
      c0,0.14-0.13,0.23-0.28,0.23c-0.27,0-0.36-0.08-0.36-0.21c0-0.04,0.02-0.16,0.06-0.17v-1.25c-0.06-0.04-0.1-0.12-0.1-0.17
      c0-0.12,0-0.22,0.1-0.25v-1.13c-0.03-0.06-0.05-0.12-0.05-0.16c0-0.14,0.17-0.23,0.37-0.23c0.08,0,0.25,0.1,0.25,0.22
      c0,0.07,0,0.13-0.05,0.17v1.05l1.29-0.07v-0.97c-0.05-0.05-0.05-0.14-0.05-0.19c0-0.14,0.17-0.22,0.31-0.22
      c0.12,0,0.31,0.11,0.31,0.2c0,0.06-0.01,0.11-0.04,0.16v2.83c0.02,0.04,0.04,0.11,0.04,0.17c0,0.12-0.18,0.21-0.32,0.21
      c-0.18,0-0.32-0.1-0.32-0.25c0-0.06,0.04-0.15,0.07-0.18v-1.31l-1.29,0.08V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M280.01,111.16c0.02,0.03,0.06,0.08,0.06,0.14
      c0,0.14-0.1,0.25-0.37,0.25c-0.18,0-0.26-0.08-0.26-0.22c0-0.08,0.01-0.14,0.04-0.19v-2.6l-0.5,0.11
      c-0.05,0.03-0.11,0.06-0.19,0.06c-0.16,0-0.26-0.09-0.26-0.23c0-0.15,0.11-0.26,0.23-0.26c0.1,0,0.13,0.02,0.16,0.03l0.59-0.13
      c0.07-0.07,0.3-0.11,0.41-0.07l0.64-0.12c0.07-0.03,0.12-0.04,0.18-0.04c0.13,0,0.24,0.07,0.24,0.25c0,0.13-0.1,0.25-0.24,0.25
      c-0.08,0-0.13-0.02-0.18-0.04l-0.55,0.11V111.16z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M281.26,110.86v-2.51c-0.11-0.18,0.04-0.35,0.25-0.35
      c0.24,0,0.26,0.2,0.26,0.3v2.61c0.05,0.13,0.18,0.26,0.38,0.26c0.64,0,0.93-0.42,0.93-0.57v-2.28c-0.04-0.03-0.06-0.07-0.06-0.12
      c0-0.14,0.15-0.24,0.34-0.24c0.12,0,0.25,0.07,0.25,0.21c0,0.04,0,0.08-0.03,0.11v2.39c0,0.1-0.1,0.37-0.26,0.52
      c-0.34,0.28-0.72,0.44-1.17,0.44C281.45,111.62,281.27,111.18,281.26,110.86"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M284.35,110.86v-2.51c-0.1-0.18,0.04-0.35,0.25-0.35
      c0.24,0,0.26,0.2,0.26,0.3v2.61c0.05,0.13,0.18,0.26,0.38,0.26c0.64,0,0.93-0.42,0.93-0.57v-2.28c-0.04-0.03-0.06-0.07-0.06-0.12
      c0-0.14,0.15-0.24,0.34-0.24c0.12,0,0.25,0.07,0.25,0.21c0,0.04,0,0.08-0.03,0.11v2.39c0,0.1-0.1,0.37-0.26,0.52
      c-0.34,0.28-0.72,0.44-1.17,0.44C284.54,111.62,284.36,111.18,284.35,110.86"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M288,111.14l0.77-0.11c0.05-0.04,0.16-0.07,0.2-0.07
      c0.12,0,0.27,0.05,0.27,0.24c0,0.19-0.1,0.28-0.24,0.28c-0.04,0-0.12-0.01-0.17-0.03l-0.88,0.12c-0.06,0.01-0.17,0.03-0.25,0.03
      c-0.18,0-0.31-0.07-0.31-0.23c0-0.1,0.04-0.16,0.09-0.19v-2.77c-0.02-0.04-0.05-0.1-0.05-0.14c0-0.22,0.16-0.29,0.33-0.29
      c0.14,0,0.3,0.05,0.3,0.29c0,0.05-0.04,0.11-0.06,0.15V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M290.26,111.14l0.77-0.11c0.05-0.04,0.16-0.07,0.21-0.07
      c0.11,0,0.26,0.05,0.26,0.24c0,0.19-0.11,0.28-0.24,0.28c-0.05,0-0.11-0.01-0.17-0.03l-0.88,0.12c-0.06,0.01-0.17,0.03-0.25,0.03
      c-0.18,0-0.32-0.07-0.32-0.23c0-0.1,0.04-0.16,0.09-0.19v-2.77c-0.02-0.04-0.04-0.1-0.04-0.14c0-0.22,0.15-0.29,0.33-0.29
      c0.15,0,0.3,0.05,0.3,0.29c0,0.05-0.04,0.11-0.06,0.15V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M292.51,111.11c0.04,0.05,0.08,0.11,0.08,0.18
      c0,0.12-0.13,0.26-0.34,0.26c-0.22,0-0.36-0.1-0.36-0.26c0-0.07,0.04-0.14,0.09-0.2v-2.64c-0.05-0.03-0.07-0.1-0.07-0.16
      c0-0.18,0.2-0.31,0.38-0.31c0.24,0,0.31,0.17,0.31,0.3c0,0.07-0.02,0.14-0.09,0.21V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M295.43,108.5c0,0.12-0.04,0.23-0.24,0.23
      c-0.23,0-0.32-0.09-0.31-0.2c-0.05-0.09-0.19-0.16-0.36-0.16c-0.43,0-0.89,0.45-0.89,0.86c0,0.21,0.08,0.29,0.22,0.29
      c0.13,0,0.31-0.04,0.57-0.11c0.25-0.08,0.43-0.12,0.67-0.12c0.41,0,0.71,0.24,0.71,0.72c0,0.31-0.07,0.57-0.22,0.78
      c-0.35,0.5-1.08,0.82-1.63,0.82c-0.64,0-0.86-0.53-0.86-0.7c0-0.15,0.12-0.3,0.3-0.3c0.19,0,0.21,0.22,0.27,0.35
      c0.06,0.15,0.17,0.24,0.34,0.24c0.37,0,0.88-0.26,1.11-0.6c0.11-0.15,0.13-0.36,0.13-0.57c0-0.25-0.11-0.32-0.28-0.32
      c-0.19,0-0.34,0.05-0.6,0.12c-0.25,0.08-0.44,0.11-0.66,0.11c-0.35,0-0.64-0.17-0.64-0.65c0-0.6,0.69-1.33,1.57-1.33
      C295.05,107.96,295.43,108.29,295.43,108.5"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M296.87,111.08l1.18-0.08c0.05-0.05,0.11-0.08,0.18-0.08
      c0.2,0,0.33,0.13,0.33,0.25c0,0.22-0.18,0.26-0.48,0.26l-1.21,0.08c-0.04,0.05-0.15,0.09-0.33,0.09c-0.15,0-0.28-0.09-0.28-0.26
      c0-0.09,0.04-0.18,0.08-0.23v-1.06c-0.07-0.07-0.11-0.16-0.11-0.24c0-0.1,0.06-0.2,0.11-0.24v-0.91
      c-0.08-0.04-0.13-0.11-0.13-0.19c0-0.1,0.04-0.16,0.09-0.19c-0.02-0.03-0.04-0.07-0.04-0.1c0-0.11,0.15-0.19,0.33-0.19
      c0.17,0,0.27,0.07,0.28,0.18l0.97-0.09c0.05-0.03,0.14-0.08,0.21-0.08c0.23,0,0.34,0.11,0.34,0.24c0,0.18-0.12,0.3-0.33,0.3
      c-0.08,0-0.12-0.01-0.19-0.03l-0.99,0.1v0.93l0.75-0.1c0.06-0.04,0.13-0.08,0.2-0.08c0.2,0,0.32,0.14,0.32,0.26
      c0,0.19-0.13,0.27-0.35,0.27c-0.05,0-0.08-0.02-0.12-0.04l-0.81,0.11V111.08z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M301.23,108.5c0,0.12-0.05,0.23-0.24,0.23
      c-0.23,0-0.32-0.09-0.32-0.2c-0.05-0.09-0.18-0.16-0.36-0.16c-0.44,0-0.89,0.45-0.89,0.86c0,0.21,0.08,0.29,0.22,0.29
      c0.13,0,0.3-0.04,0.57-0.11c0.25-0.08,0.43-0.12,0.67-0.12c0.41,0,0.71,0.24,0.71,0.72c0,0.31-0.07,0.57-0.22,0.78
      c-0.35,0.5-1.08,0.82-1.63,0.82c-0.64,0-0.86-0.53-0.86-0.7c0-0.15,0.12-0.3,0.3-0.3c0.19,0,0.21,0.22,0.26,0.35
      c0.06,0.15,0.18,0.24,0.34,0.24c0.38,0,0.88-0.26,1.11-0.6c0.11-0.15,0.14-0.36,0.14-0.57c0-0.25-0.11-0.32-0.28-0.32
      c-0.19,0-0.34,0.05-0.6,0.12c-0.25,0.08-0.45,0.11-0.66,0.11c-0.35,0-0.64-0.17-0.64-0.65c0-0.6,0.69-1.33,1.56-1.33
      C300.85,107.96,301.23,108.29,301.23,108.5"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M303.21,111.16c0.02,0.03,0.06,0.08,0.06,0.14
      c0,0.14-0.1,0.25-0.37,0.25c-0.18,0-0.26-0.08-0.26-0.22c0-0.08,0.01-0.14,0.04-0.19v-2.6l-0.49,0.11
      c-0.05,0.03-0.11,0.06-0.19,0.06c-0.16,0-0.26-0.09-0.26-0.23c0-0.15,0.12-0.26,0.23-0.26c0.1,0,0.13,0.02,0.16,0.03l0.59-0.13
      c0.07-0.07,0.3-0.11,0.41-0.07l0.64-0.12c0.07-0.03,0.11-0.04,0.19-0.04c0.12,0,0.23,0.07,0.23,0.25c0,0.13-0.1,0.25-0.23,0.25
      c-0.08,0-0.13-0.02-0.18-0.04l-0.56,0.11V111.16z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.01,111.11c0.04,0.05,0.08,0.11,0.08,0.18
      c0,0.12-0.14,0.26-0.34,0.26c-0.22,0-0.36-0.1-0.36-0.26c0-0.07,0.04-0.14,0.08-0.2v-2.64c-0.05-0.03-0.06-0.1-0.06-0.16
      c0-0.18,0.2-0.31,0.38-0.31c0.24,0,0.31,0.17,0.31,0.3c0,0.07-0.02,0.14-0.09,0.21V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.67,111.26c0-0.15,0.12-0.35,0.35-0.35
      c0.16,0,0.27,0.09,0.27,0.31c0,0.17-0.1,0.35-0.35,0.35C305.81,111.56,305.67,111.47,305.67,111.26"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M307.53,111.11c0.03,0.04,0.08,0.12,0.08,0.21
      c0,0.16-0.18,0.25-0.39,0.25c-0.22-0.01-0.32-0.07-0.32-0.24c0-0.12,0.04-0.2,0.1-0.26v-1.05c-0.07-0.06-0.11-0.16-0.11-0.24
      c0-0.08,0.05-0.21,0.11-0.26v-0.83c-0.05-0.01-0.12-0.14-0.12-0.23c0-0.1,0.03-0.17,0.12-0.18c-0.02-0.19,0.18-0.28,0.36-0.28
      c0.12,0,0.23,0.07,0.18,0.18l0.95-0.15c0.11-0.01,0.15-0.06,0.24-0.06c0.16,0,0.32,0.08,0.31,0.23c0,0.23-0.08,0.32-0.31,0.32
      c-0.08,0-0.14-0.02-0.22-0.05l-0.96,0.16v0.88l0.87-0.14c0.04-0.04,0.12-0.08,0.26-0.08c0.11,0,0.22,0.17,0.22,0.26
      c0,0.25-0.09,0.3-0.27,0.3c-0.07,0-0.14,0-0.22-0.04l-0.85,0.14V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M310.01,111.11c0.04,0.05,0.08,0.11,0.08,0.18
      c0,0.12-0.13,0.26-0.34,0.26c-0.22,0-0.36-0.1-0.36-0.26c0-0.07,0.04-0.14,0.09-0.2v-2.64c-0.06-0.03-0.07-0.1-0.07-0.16
      c0-0.18,0.2-0.31,0.38-0.31c0.24,0,0.31,0.17,0.31,0.3c0,0.07-0.02,0.14-0.09,0.21V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <g>
        <path
          clip-rule="evenodd"
          d="M325.44,79.7l-0.33-0.11c-0.21-0.06-0.43-0.11-0.65-0.14
      c-0.16-0.02-0.3-0.01-0.43,0.11c1.3,1.1,1.54,3.92,1.53,5.27c0,1.36-0.44,4.24-1.64,5.32l-0.13,0.11
      c-0.12,0.11-0.02,0.14,0.07,0.14c0.25,0.01,0.54-0.05,0.89-0.12l0.23-0.06c0.09-0.03,0.19-0.06,0.28-0.09
      c1.23-0.51,2.18-2.44,2.18-5.3C327.44,82.01,326.59,80.2,325.44,79.7 M323.99,88.52c0.48-1.09,0.8-2.48,0.82-3.7
      c0.02-1.75-0.26-3.26-0.74-4.29c-0.32-0.68-0.72-1.18-1.19-1.36c-0.11-0.05-0.4-0.11-0.66-0.13c-0.35-0.03-0.65-0.06-0.9,0.13
      c1.36,1.13,1.69,4.24,1.69,5.61c0,0.56-0.1,1.48-0.2,1.98c-0.09,0.41-0.26,1.07-0.41,1.03c-0.02-0.01-0.04-0.03-0.04-0.15
      l0.05-0.8c0.02-0.46,0.01-0.99-0.03-1.75c-0.03-0.56-0.1-1.07-0.19-1.59c-0.11-0.73-0.28-1.41-0.48-2.01
      c-0.41-1.21-0.97-2.12-1.6-2.43c-0.14-0.06-0.23-0.1-0.39-0.12c-0.16-0.01-0.26-0.01-0.41-0.01c-0.4-0.01-0.87,0.02-1.11,0.16v0.1
      c1.38,0.83,2.2,3.85,2.41,6.35l0.07,0.98c0.01,0.38,0.02,0.75,0,1.2c-0.05,0.81-0.09,1.07-0.19,1.57
      c-0.04,0.22-0.09,0.42-0.15,0.64l-0.13,0.38l-0.08,0.23c-0.04,0.1,0.05,0.14,0.09,0.15c0.34,0.07,0.9,0.02,1.21-0.01
      c0.3-0.03,0.57-0.09,0.86-0.21c0.48-0.19,0.74-0.4,0.97-0.67c0.19-0.23,0.36-0.5,0.53-0.81
      C323.86,88.83,323.99,88.52,323.99,88.52z M319.91,89.18c-0.21,0.02-0.41,0.04-0.61,0.05c-0.18,0-0.31,0-0.52-0.02
      c-0.18-0.02-0.21-0.07-0.1-0.33l0.11-0.32c0.32-1.05,0.51-2.23,0.51-3.72c0-2.83-0.79-5.28-1.97-5.64
      c-0.1-0.03-0.18-0.05-0.31-0.05l-0.35,0.01c-0.39,0.04-0.69,0.1-1,0.34c0.02,0.03,0.07,0.06,0.16,0.11
      c1.12,0.79,1.56,3.61,1.54,5.24c-0.02,1.18-0.19,3.28-0.92,4.54c-0.14,0.25-0.32,0.49-0.55,0.68l-0.16,0.13
      c-0.1,0.08-0.05,0.11-0.01,0.15l0.17,0.07c0.05,0.02,0.12,0.04,0.21,0.06c0.71,0.14,1.34,0.23,2.69,0.26
      c0.35,0,0.63-0.07,0.77-0.21c0.19-0.21,0.29-0.39,0.43-1.01c0-0.01,0.03-0.17,0.03-0.27C320.01,89.2,320.01,89.17,319.91,89.18z
       M314.64,87.55c-0.32,0-0.55-1.08-0.55-2.56c0-1.47,0.23-2.55,0.55-2.55c0.32,0,0.55,1.08,0.55,2.56
      C315.19,86.47,314.96,87.55,314.64,87.55z M314.71,79.7c-1.09,0-1.92,2.36-1.92,5.16c0,2.81,0.83,5.16,1.92,5.16
      c1.09,0.01,1.92-2.35,1.92-5.16C316.62,82.07,315.8,79.71,314.71,79.7z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M313.21,92.39c0.11-0.02,0.25-0.03,0.4-0.03
      c0.23,0,0.4,0.03,0.53,0.15c0.12,0.11,0.17,0.27,0.17,0.43c0,0.21-0.07,0.36-0.17,0.47c-0.12,0.13-0.32,0.18-0.48,0.18h-0.08v0.74
      h-0.38V92.39z M313.59,93.28l0.06,0c0.19,0,0.28-0.13,0.28-0.32c0-0.17-0.07-0.31-0.25-0.31l-0.09,0.02V93.28z M315.7,93.31
      c0,0.74-0.26,1.04-0.63,1.04c-0.44,0-0.62-0.48-0.62-1.01c0-0.54,0.21-1,0.65-1C315.56,92.34,315.7,92.86,315.7,93.31z
       M314.84,93.34c0,0.44,0.08,0.67,0.24,0.67c0.16,0,0.22-0.29,0.22-0.69c0-0.35-0.05-0.66-0.22-0.66
      C314.92,92.67,314.84,92.92,314.84,93.34z M315.9,93.93c0.08,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22
      c0-0.14-0.09-0.21-0.25-0.32c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.57,0.62-0.57c0.13,0,0.25,0.03,0.31,0.07l-0.06,0.33
      c-0.05-0.04-0.14-0.07-0.25-0.07c-0.16,0-0.24,0.1-0.24,0.2c0,0.11,0.06,0.18,0.27,0.31c0.26,0.16,0.36,0.36,0.36,0.56
      c0,0.36-0.27,0.59-0.65,0.59c-0.16,0-0.31-0.04-0.37-0.07L315.9,93.93z M317.09,92.36h0.38v1.96h-0.38V92.36z M318.02,92.71h-0.35
      v-0.35h1.08v0.35h-0.35v1.61h-0.38V92.71z M318.95,92.36h0.39v1.96h-0.39V92.36z M319.68,92.36h0.38v1.96h-0.38V92.36z
       M320.68,94.32l-0.44-1.96h0.42l0.14,0.84c0.04,0.21,0.08,0.45,0.11,0.68l0.1-0.69l0.13-0.83h0.42l-0.45,1.96H320.68z
       M321.72,92.36h0.38v1.96h-0.38V92.36z M322.4,93.93c0.08,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22
      c0-0.14-0.09-0.21-0.26-0.32c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.57,0.62-0.57c0.13,0,0.25,0.03,0.31,0.07l-0.06,0.33
      c-0.05-0.04-0.14-0.07-0.25-0.07c-0.16,0-0.24,0.1-0.24,0.2c0,0.11,0.05,0.18,0.27,0.31c0.26,0.16,0.36,0.36,0.36,0.56
      c0,0.36-0.27,0.59-0.65,0.59c-0.16,0-0.31-0.04-0.38-0.07L322.4,93.93z M324.42,93.46h-0.45v0.54h0.5v0.33h-0.89v-1.96h0.85v0.33
      h-0.47v0.45h0.45V93.46z M324.7,93.93c0.07,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22c0-0.14-0.09-0.21-0.26-0.32
      c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.57,0.62-0.57c0.13,0,0.25,0.03,0.31,0.07l-0.06,0.33
      c-0.06-0.04-0.14-0.07-0.25-0.07c-0.17,0-0.25,0.1-0.25,0.2c0,0.11,0.06,0.18,0.28,0.31c0.26,0.16,0.35,0.36,0.35,0.56
      c0,0.36-0.26,0.59-0.65,0.59c-0.16,0-0.31-0.04-0.38-0.07L324.7,93.93z M326.1,92.71h-0.35v-0.35h1.08v0.35h-0.35v1.61h-0.38
      V92.71z M327.03,92.36h0.39v1.96h-0.39V92.36z M313.17,96.84c0.08,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22
      c0-0.15-0.09-0.21-0.25-0.32c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.58,0.62-0.58c0.12,0,0.25,0.04,0.31,0.07l-0.06,0.33
      c-0.06-0.03-0.14-0.06-0.25-0.06c-0.17,0-0.24,0.1-0.24,0.21c0,0.11,0.06,0.17,0.27,0.3c0.26,0.16,0.36,0.36,0.36,0.56
      c0,0.36-0.27,0.6-0.65,0.6c-0.16,0-0.31-0.04-0.38-0.08L313.17,96.84z M314.73,95.27v1.28c0,0.28,0.09,0.37,0.19,0.37
      c0.11,0,0.19-0.08,0.19-0.37v-1.28h0.38v1.21c0,0.5-0.19,0.78-0.57,0.78c-0.41,0-0.57-0.29-0.57-0.78v-1.21H314.73z M316.98,96.22
      c0,0.74-0.26,1.04-0.63,1.04c-0.45,0-0.62-0.48-0.62-1.01c0-0.54,0.21-1,0.65-1C316.84,95.25,316.98,95.77,316.98,96.22z
       M316.12,96.26c0,0.44,0.08,0.67,0.23,0.67c0.16,0,0.22-0.29,0.22-0.69c0-0.35-0.05-0.66-0.22-0.66
      C316.2,95.58,316.12,95.83,316.12,96.26z M318.42,96.55c-0.01-0.19-0.03-0.44-0.02-0.65l-0.13,0.6l-0.17,0.71h-0.27l-0.16-0.69
      c-0.04-0.16-0.09-0.41-0.12-0.61l-0.04,0.66l-0.03,0.68h-0.33l0.14-1.96h0.4l0.16,0.71c0.06,0.25,0.11,0.47,0.14,0.69
      c0.03-0.22,0.08-0.44,0.13-0.69l0.15-0.71h0.4l0.12,1.96h-0.34L318.42,96.55z M319.39,96.78l-0.09,0.45h-0.37l0.43-1.96h0.46
      l0.38,1.96h-0.37l-0.08-0.45H319.39z M319.7,96.49l-0.07-0.41l-0.06-0.44l-0.08,0.44l-0.07,0.41H319.7z M320.41,95.27h0.38v1.64
      h0.5v0.32h-0.88V95.27z M321.85,96.78l-0.09,0.45h-0.37l0.43-1.96h0.46l0.38,1.96h-0.37l-0.08-0.45H321.85z M322.17,96.49
      l-0.06-0.41l-0.06-0.44l-0.07,0.44l-0.08,0.41H322.17z M322.87,95.27h0.38v1.96h-0.38V95.27z M323.6,97.24v-1.96h0.35l0.3,0.77
      l0.21,0.57c-0.01-0.21-0.03-0.55-0.03-0.91v-0.44h0.33v1.96h-0.35l-0.3-0.75c-0.06-0.16-0.16-0.41-0.2-0.59
      c0,0.19,0.02,0.5,0.02,0.89v0.44H323.6z M325.93,96.37h-0.45v0.53h0.5v0.34h-0.88v-1.96h0.85v0.33h-0.47v0.45h0.45V96.37z
       M326.24,97.24v-1.96h0.35l0.31,0.77l0.21,0.57c0-0.21-0.03-0.55-0.03-0.91v-0.44h0.34v1.96h-0.35l-0.3-0.75
      c-0.06-0.16-0.16-0.41-0.2-0.59c0,0.19,0.02,0.5,0.02,0.89v0.44H326.24z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M346.42,83.94c-0.28,0.21-0.53,0.33-0.56,0.28
      c-0.03-0.05,0.16-0.25,0.43-0.45c0.28-0.2,0.53-0.32,0.56-0.27C346.88,83.53,346.69,83.74,346.42,83.94 M346.9,83.56l0-0.03
      l-0.01-0.06l-0.01-0.06l-0.01-0.02l-0.01-0.01l-0.01-0.01l-0.01,0h-0.01l-0.02,0l-0.01,0h-0.01l-0.01,0l-0.02,0.01l-0.06,0.03
      l-0.09,0.05l-0.04,0.03l-0.04,0.02l-0.03,0.03l-0.05,0.04l-0.04,0.02l-0.05,0.04l-0.11,0.08l-0.16,0.12l-0.04,0.03l-0.05,0.04
      l-0.05,0.04l-0.03,0.03l-0.03,0.03l-0.04,0.04l-0.03,0.04l-0.02,0.03l-0.01,0.02l-0.01,0.02l0,0.02l0,0.02l0,0.02l0,0.02
      l0.01,0.01l0.01,0.01l0.03,0.01l0.03,0.01l0.03,0.01l0.69,0.7l0.76-0.66L346.9,83.56z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M347.26,86.43l0,0.02l-0.01,0.04l0,0.04l-0.01,0.05l-0.01,0.05
      l-0.01,0.05l-0.01,0.04l-0.01,0.05l-0.01,0.06l-0.01,0.06l-0.03,0.11l-0.04,0.1l-0.01,0.06l-0.02,0.06l-0.02,0.06l-0.03,0.07
      l-0.02,0.04l-0.01,0.04l-0.02,0.04l-0.02,0.05l-0.02,0.04l-0.02,0.05l-0.02,0.04l-0.02,0.04l-0.03,0.05l-0.03,0.05l-0.03,0.05
      l-0.02,0.03l-0.02,0.04l-0.03,0.04l-0.03,0.04l-0.03,0.03l-0.02,0.03l-0.03,0.03l-0.03,0.03l-0.23-0.2l-0.19-0.17l-0.18-0.15
      l-3.65,3.15l0.01,0l0.07,0.02l0.08,0.03l0.15,0.05l0.02,0.01l0.02,0.03l0.01,0.04l0.01,0.04l0.01,0.04l0.01,0.04l0.01,0.03l0,0.04
      l0.01,0.04l0.01,0.04l0,0.04l0,0.03l0,0.06l0,0.05v0.05v0.03v0.03v0.03l0,0.03l0,0.04l0,0.02l-0.01,0.03l0,0.02l-0.01,0.03l0,0.03
      l-0.01,0.04l-0.01,0.03l-0.01,0.03l-0.01,0.04l-0.01,0.03l-0.01,0.03l-0.01,0.03l-0.01,0.03l-0.02,0.03l-0.02,0.03l-0.02,0.03
      l-0.02,0.04l-0.03,0.05l-0.11,0.18l-0.13,0.22l-0.04,0.06l-0.02,0.04l-0.02,0.04l-0.02,0.05l-0.02,0.04l-0.01,0.05l-0.02,0.05
      l-0.01,0.05l-0.01,0.05l-0.01,0.05l-0.01,0.06l-0.01,0.07l0,0.06l0,0.07l0,0.05v0.07l0,0.06l0,0.06l0,0.07l0.01,0.07l0.01,0.06
      l0.02,0.08l0.01,0.06l0.02,0.07l0.02,0.07l0.02,0.06l0.03,0.07l0.04,0.1l0.05,0.11l0.05,0.1l0.06,0.12l0.05,0.11l0.06,0.12
      l0.06,0.12l0.06,0.12l0.05,0.11c0.22,0.04,0.45,0.07,0.69,0.1c0.23,0.02,0.45,0.03,0.68,0.03c0.88,0,1.73-0.16,2.52-0.45
      l-0.04-0.09l-0.12-0.25l-0.06-0.13l-0.05-0.1l-0.02-0.05l-0.01-0.03l-0.01-0.02l0-0.02l0-0.01l0.1-0.09l0.09-0.08l0.1-0.08
      l0.1-0.08l0.1-0.08l0.05-0.04l0.05-0.04l0.05-0.05l0.05-0.04l0.05-0.05l0.05-0.05l0.04-0.05l0.03-0.03l0.02-0.02l0.02-0.03
      l0.02-0.03l0.03-0.03l0.02-0.03l0.03-0.04l0.03-0.04l0.03-0.05l0.03-0.05l0.02-0.05l0.04-0.05l0.03-0.05l0.02-0.04l0.02-0.04
      l0.02-0.05l0.02-0.04l0.02-0.05l0.02-0.05l0.02-0.05l0.01-0.04l0.02-0.05l0.01-0.04l0.01-0.04l0.01-0.04l0.01-0.04l0.01-0.04
      l0-0.03l0.01-0.04l0.01-0.05l0-0.05l0-0.05l0-0.06v-0.05v-0.05v-0.04v-0.05l0-0.06l0-0.06l-0.01-0.06l-0.01-0.07l-0.01-0.06
      l-0.01-0.07l-0.02-0.06l-0.02-0.06l-0.02-0.07l-0.02-0.05l-0.02-0.06l-0.02-0.04l-0.02-0.04l-0.02-0.04l-0.02-0.03l-0.02-0.04
      l-0.03-0.06l-0.04-0.06l-0.07-0.11l-0.07-0.11l-0.07-0.11l-0.07-0.1l-0.15-0.22l-0.16-0.22l-0.08-0.11l-0.07-0.09l-0.05-0.06
      l-0.05-0.06l-0.05-0.07l-0.05-0.05l-0.02-0.06l-0.02-0.06l0.13,0.05l0.13,0.04l0.18,0.05l0.17,0.05l0.03,0l0.03,0h0.03h0.02h0.02
      l0.02,0l0.02,0l0.02-0.01l0.01,0l0.02,0l0.01-0.01l0.01-0.01l0.02-0.01l0.01-0.01l0.01-0.01l0.01-0.02l0.01-0.01l0.01-0.02
      l0.01-0.03l0.02-0.03l0.01-0.02l0.01-0.03l0.01-0.03l0.01-0.03l0.01-0.05l0.02-0.06l0.03-0.1l0.02-0.08l0.02-0.08l0.02-0.08
      l0.02-0.09l0.02-0.1l0.06-0.2l0.03-0.11l0.08-0.33l0.05-0.28l0.03-0.16l0.01-0.12l0.02-0.12l0.01-0.12l0.01-0.09l0.01-0.09
      l0.01-0.11l0-0.07l0-0.07l0-0.08l0-0.09l0.01-0.08l0.01-0.09l0-0.06l0.01-0.08l0.02-0.08l0.01-0.06l0.01-0.06l0.01-0.06l0-0.05
      l0-0.07l0-0.07l0-0.11L347.26,86.43z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M341.62,89.26l-0.07-0.03l-0.06-0.02l-0.06-0.03l-0.06-0.03
      l-0.06-0.02l-0.05-0.02l-0.06-0.02l-0.07-0.02l-0.01-0.02l0-0.01l0-0.01v-0.01l0-0.01l0.01-0.01l0.03-0.01l0.04-0.03l0.04-0.03
      l0.03-0.03l0.03-0.02l0.03-0.02l0.02-0.01l0.02-0.02l0.02-0.01l0.02-0.02l0.01-0.01l0.03-0.02l0.04-0.03l0.02-0.02l0.03-0.02
      l0.04-0.03l0.03-0.02l0.03-0.02l0.02-0.02l0.02-0.02l0.03-0.02l0.02-0.02l0.02-0.02l0.02-0.02l0.01-0.01l0.02-0.03l0.02-0.02
      l0.01-0.02l0.02-0.03l0.02-0.03l0.04-0.05l0.01-0.02l0.01-0.01l0.01-0.01l0.01-0.01H342h0.01l0.01,0h0.01l0.02,0.01l0.04,0.04
      l0.04,0.04l0.04,0.04l0.03,0.04l0.03,0.04l0.03,0.05l0.03,0.04l0.02,0.05l0.03,0.06l2.55-2.2l0,0l-0.06-0.05l-0.05-0.04
      l-0.05-0.04l-0.05-0.03l-0.04-0.03l-0.04-0.03l-0.04-0.02l-0.03-0.01l-0.04-0.02l-0.04-0.01l-0.02,0l-0.03,0l-0.03,0l-0.03,0
      l-0.06-0.02l-0.05-0.02l-0.04-0.02l-0.04-0.02l-0.03-0.01l-0.01-0.01l-0.01-0.01l-0.01-0.01l-0.01-0.01L344.07,86l-0.01-0.02
      l0-0.02l-0.01-0.03l-0.01-0.06l-0.01-0.07l-0.02-0.14l0-0.06l0-0.07l-0.01-0.07v-0.07v-0.07v-0.06l0.01-0.04l0.01-0.04l0.01-0.02
      l0.01-0.03l0.03-0.04l0.02-0.03l0.02-0.02l0.04-0.02L344.2,85l0.04-0.01l0.04-0.01l0.04-0.02l0.05-0.01l0.05-0.02l0.12,0h0.12
      l0.05,0h0.03l0.03,0l0.06,0l0.04,0l0.02,0l0.02,0l0.02-0.01l0.02-0.01l0.01,0l0.02-0.02l0.01-0.02l0.01-0.01L345,84.8l0-0.01
      l0.01-0.02l0.01-0.02v-0.02v-0.15l0-0.14l0.01,0l0.02,0l0.01,0l0.01-0.01l0.01-0.01l0.01-0.01l0.01-0.01l0-0.01l0-0.01l0-0.01
      l0-0.01v-0.02l0-0.02l-0.01-0.01l-0.01-0.01l-0.02-0.01l-0.02-0.01l-0.02-0.01l-0.03-0.01l0.03-0.02l0.03-0.02l0.01-0.01
      l0.02-0.02l0.01-0.01l0-0.01l0-0.01v-0.01v-0.01v-0.01l0-0.01v0l0-0.01l-0.01,0h-0.01h-0.01h-0.01h-0.02l0.01-0.04l0.04-0.01
      l0.04-0.01l0.04-0.01l0.03-0.01l0.03-0.01l0.02-0.01l0.02-0.01l0.01-0.01l0.01-0.01l0.01-0.01l0.01-0.01l0.01-0.02l0-0.01l0-0.01
      l0-0.02l0-0.02v-0.02v-0.02l-0.01-0.02l-0.01-0.02l-0.18-0.23l-0.02-0.03l-0.02-0.03l-0.02-0.03l-0.01-0.03l-0.03-0.06l-0.01-0.03
      l-0.01-0.03l-0.01-0.07l0-0.04v-0.03v-0.03v-0.04v-0.04l0-0.04l0-0.03v-0.02l0.25-0.06c-0.05-0.41-0.3-0.79-0.66-1.01
      c-0.17-0.1-0.35-0.17-0.54-0.21l-0.01,0l-0.05-0.02l-0.05-0.01l-0.03-0.01l-0.07-0.02l-0.06-0.01l-0.06-0.01l-0.07-0.01l-0.1,0
      l-0.09-0.01l-0.1,0h-0.09h-0.07l-0.08,0l-0.07,0.01l-0.06,0.01l-0.07,0.01l-0.07,0.02l-0.07,0.01l-0.05,0.01l-0.07,0.02
      l-0.05,0.02l-0.04,0.02l-0.04,0.02l-0.03,0.01l-0.04,0.02l-0.04,0.02l-0.03,0.02l-0.03,0.02l-0.04,0.03l-0.04,0.04l-0.05,0.05
      l-0.03,0.04l-0.03,0.04l-0.02,0.03l-0.04,0.05l-0.03,0.05l-0.03,0.05l-0.03,0.05l-0.02,0.06l-0.03,0.06l-0.01,0.02
      c0.01,0.87-0.28,2.38-0.89,2.98c0.28,0.09,0.58,0.11,0.87,0.07c0.1,0.36-0.02,0.17-0.22,0.49c-0.15,0.23-0.13,0.43-0.33,0.61
      l-0.35,0.48l-0.36,0.5l-0.09,0.13l-0.07,0.09l-0.07,0.1l-0.08,0.11l-0.07,0.1l-0.06,0.08l-0.07,0.11l-0.32,0.49l-0.07,0.11
      l-0.02,0.04l-0.02,0.05l-0.04,0.07l-0.01,0.03L339.72,89l-0.02,0.05l-0.02,0.05l-0.02,0.05l-0.01,0.04l0,0.03l0,0.04l0,0.04v0.03
      v0.03l0,0.03l0,0.04l0.01,0.05l0,0.02l0.01,0.03l0.01,0.02l0.01,0.02l0.01,0.02l0.02,0.03l0.05,0.05l0.04,0.04l0.04,0.04
      l0.04,0.04l0.04,0.03l0.04,0.04l0.04,0.03l0.05,0.03l0.2,0.11l0.21,0.12l0.08,0.04l0.03,0.01L341.62,89.26L341.62,89.26z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M351.46,88.7c-0.38,4.16-4.06,7.22-8.23,6.85
      c-1.75-0.16-3.3-0.91-4.49-2.03l11.4-9.84C351.12,85.1,351.63,86.85,351.46,88.7 M336.39,87.32c0.38-4.16,4.06-7.23,8.22-6.84
      c1.91,0.17,3.59,1.04,4.81,2.33l-11.45,9.88C336.82,91.22,336.21,89.32,336.39,87.32z M344.77,78.8
      c-5.09-0.47-9.59,3.28-10.06,8.37c-0.46,5.09,3.28,9.59,8.37,10.06c5.09,0.47,9.59-3.28,10.06-8.37
      C353.6,83.76,349.86,79.26,344.77,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M392.42,88.7c-0.38,4.16-4.06,7.22-8.22,6.85
      c-4.16-0.39-7.23-4.07-6.85-8.23c0.38-4.16,4.06-7.23,8.22-6.84C389.73,80.85,392.8,84.53,392.42,88.7 M385.72,78.8
      c-5.09-0.47-9.59,3.28-10.06,8.37c-0.46,5.09,3.28,9.59,8.37,10.06c5.09,0.47,9.59-3.28,10.06-8.37
      C394.56,83.76,390.81,79.26,385.72,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <polygon
          clip-rule="evenodd"
          fill="#FFFFFF"
          fill-rule="evenodd"
          points="381.05,86.45 379.87,86.71 379.44,84.92 381.6,84.33
      383.44,84.33 383.44,91.64 381.05,91.64     "
        />
        <path
          clip-rule="evenodd"
          d="M387.92,86.59v-0.02c0-0.36-0.3-0.68-0.76-0.68
      c-0.46,0-0.76,0.32-0.76,0.68v0.02c0,0.4,0.31,0.71,0.76,0.71C387.61,87.3,387.92,87,387.92,86.59 M388,89.37v-0.02
      c0-0.39-0.33-0.71-0.84-0.71c-0.51,0-0.84,0.32-0.84,0.71v0.02c0,0.36,0.28,0.72,0.84,0.72C387.72,90.09,388,89.72,388,89.37z
       M384,89.64v-0.02c0-0.88,0.52-1.42,1.3-1.74c-0.55-0.28-1.06-0.78-1.06-1.58v-0.02c0-1.19,1.19-2.03,2.91-2.03
      c1.72,0,2.91,0.84,2.91,2.03v0.02c0,0.8-0.51,1.3-1.06,1.58c0.74,0.3,1.31,0.82,1.31,1.74v0.02c0,1.26-1.29,2.13-3.16,2.13
      C385.29,91.77,384,90.89,384,89.64z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M369.25,87.8c0,0.32-0.26,0.59-0.59,0.59
      c-0.32,0-0.58-0.27-0.58-0.59c0-0.32,0.26-0.59,0.58-0.59C368.99,87.21,369.25,87.47,369.25,87.8 M368.49,86.46
      c0,0.01,0,0.03-0.01,0.04c-0.01,0.01-0.02,0.02-0.03,0.02h-0.8L364.77,89l5.38,0.01c0.07,0,0.12-0.05,0.13-0.12
      c0.01-0.09,0.23-2.22-1.02-2.46c-0.08-0.34-0.2-0.69-0.33-1.03l-0.63,0.54L368.49,86.46z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M360.12,88.39c-0.32,0-0.59-0.27-0.59-0.59
      c0-0.32,0.26-0.59,0.59-0.59c0.33,0,0.59,0.27,0.59,0.59C360.71,88.12,360.45,88.39,360.12,88.39 M360.49,86.52
      c-0.01,0-0.03,0-0.03-0.02c-0.01-0.01-0.01-0.02-0.01-0.04c0.03-0.11,0.75-2.58,1.27-2.76c0.51-0.17,2.58-0.19,2.67-0.19h0.52
      c0.97,0,2.13,0.04,2.35,0.2c0.1,0.07,0.26,0.37,0.43,0.74l0.52-0.45c-0.37-0.6-0.73-1.02-0.89-1.02c-0.17,0-1.53-0.13-2.91-0.13
      c-1.39,0-2.79,0.13-2.94,0.13c-0.3,0-1.58,1.87-1.95,3.47c-1.24,0.24-1.02,2.36-1.01,2.46c0,0.07,0.06,0.12,0.13,0.12l3.76-0.01
      l2.88-2.49H360.49z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M358.76,89.31c-0.07,0-0.13,0.06-0.13,0.13v0.85
      c0,0.07,0.06,0.13,0.13,0.13h0.53v0.84c0,0.13,0.04,0.25,0.11,0.35l2.66-2.3H358.76z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M368.19,90.42v0.84c0,0.35,0.28,0.63,0.63,0.63h0.42
      c0.35,0,0.63-0.28,0.63-0.63v-0.84h0.19c0.07,0,0.13-0.06,0.13-0.13v-0.85c0-0.07-0.06-0.13-0.13-0.13h-5.65l-1.29,1.11H368.19z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M371.94,88.7c-0.38,4.16-4.06,7.22-8.22,6.85
      c-1.75-0.16-3.31-0.91-4.49-2.03l11.4-9.84C371.6,85.1,372.11,86.85,371.94,88.7 M356.87,87.32c0.38-4.16,4.06-7.23,8.22-6.84
      c1.91,0.17,3.59,1.04,4.81,2.33l-11.45,9.88C357.3,91.22,356.68,89.32,356.87,87.32z M365.24,78.8
      c-5.09-0.47-9.58,3.28-10.06,8.37c-0.46,5.09,3.29,9.59,8.37,10.06c5.09,0.47,9.59-3.28,10.06-8.37
      C374.08,83.76,370.33,79.26,365.24,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.13,90.64c0-0.21-0.16-0.27-0.34-0.27h-0.35v0.9h0.2v-0.36
      h0.1l0.19,0.36h0.24l-0.23-0.38C270.06,90.86,270.13,90.77,270.13,90.64 M269.76,90.74h-0.12v-0.21h0.13
      c0.07,0,0.16,0.01,0.16,0.11C269.92,90.74,269.83,90.74,269.76,90.74z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M269.77,89.95c-0.49,0-0.88,0.39-0.88,0.88
      c0,0.49,0.4,0.88,0.88,0.88c0.49,0,0.89-0.39,0.89-0.88C270.66,90.34,270.26,89.95,269.77,89.95 M269.77,91.54
      c-0.39,0-0.71-0.32-0.71-0.71c0-0.39,0.32-0.71,0.71-0.71c0.39,0,0.71,0.32,0.71,0.71C270.48,91.22,270.16,91.54,269.77,91.54z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M280.9,85.08v-5.31h-8.28v-0.59l-1.11-0.43v13.72
      c-2.03,0.19-3.56,1.2-3.56,2.39c0,1.32,1.84,2.4,4.11,2.4c2.27,0,4.11-1.07,4.11-2.4c0-1.19-1.54-2.2-3.56-2.39v-7.4H280.9z
       M276.22,79.95h4.5v1.8h-4.5V79.95z M276.22,83.1h4.5v1.8h-4.5V83.1z M272.61,79.95h2.25v1.8h-2.25V79.95z M272.61,83.1h2.25v1.8
      h-2.25V83.1z M275.01,94.86c0,0.83-1.32,1.5-2.96,1.5c-1.63,0-2.96-0.67-2.96-1.5c0-0.82,1.33-1.5,2.96-1.5
      C273.69,93.37,275.01,94.04,275.01,94.86z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.15,87.99c0.01-1.3-0.31-2.53-0.89-3.68
      c-0.49-0.95-1.14-1.76-1.94-2.45c-0.87-0.75-1.86-1.29-2.96-1.61c-0.66-0.19-1.34-0.32-2.02-0.33c-2.78-0.05-5.03,1.03-6.73,3.21
      c-1.03,1.32-1.59,2.85-1.65,4.53c-0.09,2.62,0.87,4.81,2.83,6.52c1.41,1.23,3.08,1.9,4.96,1.95c2.59,0.07,4.76-0.88,6.46-2.84
      C304.51,91.77,305.14,89.98,305.15,87.99 M296.55,78.76h0.95l0.05,0.02l0.37,0.02c0.15,0.01,0.3,0.01,0.44,0.04
      c0.28,0.05,0.55,0.1,0.82,0.18c0.29,0.07,0.59,0.17,0.87,0.25c0.37,0.11,0.7,0.27,1.05,0.43c0.41,0.2,0.8,0.42,1.17,0.68
      c0.35,0.24,0.69,0.5,1,0.8c0.22,0.21,0.45,0.41,0.65,0.64c0.3,0.36,0.58,0.73,0.85,1.11c0.17,0.24,0.32,0.51,0.46,0.78
      c0.13,0.23,0.24,0.47,0.34,0.71c0.09,0.22,0.17,0.44,0.25,0.67c0.05,0.14,0.08,0.28,0.12,0.42c0.05,0.16,0.1,0.32,0.13,0.48
      l0.07,0.36c0.03,0.18,0.07,0.36,0.09,0.54c0.03,0.24,0.06,0.49,0.06,0.73c0.01,0.39,0.01,0.78-0.02,1.16
      c-0.02,0.3-0.08,0.61-0.13,0.9c-0.07,0.33-0.13,0.66-0.23,0.99c-0.12,0.39-0.27,0.78-0.44,1.15c-0.17,0.37-0.38,0.74-0.59,1.1
      c-0.13,0.22-0.28,0.43-0.44,0.63c-0.23,0.29-0.46,0.58-0.71,0.85c-0.21,0.23-0.45,0.44-0.69,0.64c-0.22,0.19-0.45,0.37-0.69,0.54
      c-0.23,0.16-0.47,0.31-0.71,0.45c-0.29,0.16-0.6,0.32-0.9,0.46c-0.28,0.12-0.57,0.23-0.87,0.32c-0.32,0.1-0.64,0.19-0.96,0.27
      c-0.23,0.05-0.45,0.08-0.68,0.1c-0.34,0.04-0.67,0.06-1,0.08c-0.25,0.01-0.5,0-0.76-0.02c-0.26-0.01-0.51-0.02-0.76-0.06
      c-0.31-0.05-0.61-0.11-0.92-0.19c-0.2-0.05-0.4-0.11-0.61-0.18c-0.29-0.1-0.59-0.19-0.87-0.32c-0.35-0.16-0.69-0.34-1.02-0.53
      c-0.25-0.15-0.5-0.31-0.73-0.48c-0.27-0.2-0.53-0.43-0.78-0.64c-0.16-0.14-0.31-0.29-0.45-0.44c-0.16-0.17-0.31-0.34-0.45-0.52
      c-0.14-0.17-0.28-0.34-0.4-0.52c-0.16-0.24-0.32-0.5-0.48-0.76c-0.1-0.16-0.18-0.33-0.27-0.5c-0.07-0.16-0.15-0.31-0.22-0.47
      c-0.1-0.22-0.18-0.44-0.26-0.66c-0.05-0.15-0.09-0.3-0.13-0.45l-0.12-0.49l-0.07-0.37c-0.03-0.18-0.06-0.37-0.08-0.55
      c-0.02-0.2-0.03-0.4-0.05-0.6l-0.02-0.02v-0.97l0.04-0.4c0.02-0.17,0.03-0.34,0.06-0.51c0.04-0.22,0.07-0.43,0.12-0.65
      c0.06-0.23,0.12-0.45,0.2-0.68c0.11-0.33,0.22-0.66,0.36-0.98c0.16-0.35,0.34-0.69,0.54-1.03c0.14-0.25,0.3-0.5,0.48-0.72
      c0.21-0.27,0.43-0.54,0.66-0.79c0.14-0.16,0.28-0.3,0.44-0.44c0.16-0.16,0.33-0.31,0.52-0.45c0.23-0.19,0.47-0.37,0.72-0.54
      c0.48-0.32,0.98-0.61,1.52-0.81c0.35-0.13,0.7-0.27,1.05-0.39c0.19-0.06,0.38-0.1,0.58-0.15l0.34-0.07
      c0.18-0.03,0.37-0.06,0.55-0.09c0.19-0.02,0.39-0.03,0.59-0.04L296.55,78.76z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M298.17,94.7c1.32-0.22,2.47-0.8,3.43-1.72
      c1.38-1.31,2.07-2.93,2.09-4.84c-1.45-0.08-2.77,0.27-3.95,1.11c-1.16,0.84-1.92,1.96-2.37,3.31l-0.01-0.03
      c0.02-1.36-0.05-2.72,0.04-4.08c0.02-0.22,0.08-0.43,0.18-0.62c0.15-0.28,0.37-0.47,0.6-0.66c0.19-0.15,0.4-0.3,0.58-0.47
      c0.21-0.2,0.36-0.44,0.46-0.71c0.05-0.13,0.11-0.27,0.14-0.41c0.03-0.16,0.05-0.33,0.06-0.5h-0.89c-0.14,0-0.14,0-0.14-0.13v-1.61
      c0-0.07,0-0.15-0.03-0.21c-0.07-0.16-0.23-0.21-0.37-0.18c-0.16,0.03-0.26,0.16-0.25,0.32c0,0.56,0,1.13,0,1.69
      c0,0.09-0.02,0.12-0.12,0.12h-1.18h-0.15v-0.1v-1.72c0-0.14-0.1-0.28-0.24-0.31c-0.14-0.04-0.29,0.02-0.35,0.15
      c-0.03,0.06-0.05,0.14-0.05,0.22v1.67c0,0.08-0.02,0.1-0.1,0.1h-0.5h-0.43c0.01,0.13,0.01,0.25,0.04,0.36
      c0.05,0.19,0.1,0.38,0.17,0.55c0.12,0.31,0.31,0.58,0.55,0.8c0.22,0.19,0.46,0.36,0.69,0.55c0.19,0.16,0.34,0.34,0.44,0.56
      c0.14,0.29,0.19,0.61,0.19,0.92c0.01,1.21,0,2.43,0,3.64v0.1l-0.02,0.01c-0.43-1.35-1.21-2.47-2.35-3.31
      c-1.16-0.85-2.48-1.19-3.93-1.12c-0.01,1.13,0.26,2.18,0.78,3.15c0.67,1.25,1.65,2.2,2.93,2.84
      C295.4,94.76,296.76,94.95,298.17,94.7"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <path
        clip-rule="evenodd"
        d="M266.57,190.91v-0.77h0.66c0.51,0,0.9-0.43,0.9-0.91
    c0-0.49-0.4-0.91-0.9-0.91h-1.32v2.58H266.57z M266.57,188.97h0.66c0.15,0,0.26,0.12,0.26,0.27c0,0.15-0.12,0.27-0.26,0.27h-0.66
    V188.97z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M268.67,190.48h0.85l0.16,0.43h0.71l-0.97-2.58h-0.67l-0.93,2.58
    h0.7L268.67,190.48z M269.28,189.84h-0.39l0.19-0.56L269.28,189.84z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M271.09,190.91v-0.86h0.34l0.49,0.86h0.81l-0.55-0.94
    c0.27-0.1,0.5-0.42,0.5-0.77c0-0.52-0.4-0.87-0.86-0.87h-1.38v2.58H271.09z M271.09,188.96h0.67c0.15,0,0.27,0.08,0.27,0.24
    c0,0.15-0.13,0.24-0.27,0.24h-0.67V188.96z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M273.51,190.48h0.85l0.16,0.43h0.71l-0.97-2.58h-0.67l-0.93,2.58
    h0.71L273.51,190.48z M274.12,189.84h-0.39l0.19-0.56L274.12,189.84z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M276.08,190.97c0.6,0,1.03-0.43,1.03-1.01
    c0-0.11-0.01-0.24-0.02-0.3l-1.34-0.52c0.02-0.16,0.17-0.29,0.37-0.29c0.19,0,0.33,0.13,0.37,0.32l0.61-0.13
    c-0.09-0.43-0.47-0.79-1-0.79c-0.59,0-1.01,0.47-1.01,1.04l0.01,0.23l1.36,0.52c-0.03,0.19-0.16,0.32-0.36,0.32
    c-0.19,0-0.34-0.12-0.4-0.31l-0.61,0.13C275.19,190.59,275.54,190.97,276.08,190.97"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="278.32,190.91 278.32,188.96 278.95,188.96
    278.95,188.33 277.03,188.33 277.03,188.96 277.66,188.96 277.66,190.91   "
      />
      <path
        clip-rule="evenodd"
        d="M279.42,190.48h0.85l0.16,0.43h0.71l-0.97-2.58h-0.67l-0.93,2.58
    h0.71L279.42,190.48z M280.03,189.84h-0.39l0.19-0.56L280.03,189.84z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="283.99,190.91 283.99,190.28 282.59,190.28
    282.59,189.94 283.67,189.94 283.67,189.31 282.59,189.31 282.59,188.96 283.99,188.96 283.99,188.33 281.94,188.33 281.94,190.91
      "
      />
      <path
        clip-rule="evenodd"
        d="M284.82,190.91v-1.21l1.42,1.26v-2.63h-0.64v1.21
    c-0.13-0.11-1.32-1.17-1.42-1.26v2.63H284.82z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M287.17,190.91v-1.21l1.41,1.26v-2.63h-0.64v1.21
    c-0.13-0.11-1.31-1.17-1.42-1.26v2.63H287.17z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="290.93,190.91 290.93,190.28 289.53,190.28
    289.53,189.94 290.61,189.94 290.61,189.31 289.53,189.31 289.53,188.96 290.93,188.96 290.93,188.33 288.88,188.33 288.88,190.91
      "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="291.75,190.91 291.75,189.7 293.17,190.96 293.17,188.33
    292.53,188.33 292.53,189.54 291.11,188.28 291.11,190.91   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="294.58,190.97 295.56,188.33 295.05,188.33
    294.08,190.97   "
      />
      <path
        clip-rule="evenodd"
        d="M298.58,188.97c0-0.36-0.31-0.65-0.66-0.65h-1.48v2.58h1.47
    c0.46,0,0.79-0.35,0.79-0.77c0-0.36-0.19-0.57-0.4-0.63C298.46,189.42,298.58,189.22,298.58,188.97 M297.07,189.89h0.73
    c0.11,0,0.2,0.08,0.2,0.2c0,0.1-0.07,0.2-0.2,0.2h-0.73V189.89z M297.07,188.95h0.69c0.11,0,0.17,0.07,0.17,0.16
    c0,0.09-0.06,0.16-0.17,0.16h-0.69V188.95z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M299.51,190.48h0.85l0.16,0.43h0.71l-0.97-2.57h-0.67l-0.93,2.57
    h0.7L299.51,190.48z M298.79,188.34c0,0.16,0.13,0.29,0.29,0.29c0.16,0,0.28-0.13,0.28-0.29c0-0.16-0.12-0.29-0.28-0.29
    C298.92,188.05,298.79,188.18,298.79,188.34z M300.48,188.34c0,0.16,0.12,0.29,0.28,0.29c0.16,0,0.29-0.13,0.29-0.29
    c0-0.16-0.13-0.29-0.29-0.29C300.6,188.05,300.48,188.18,300.48,188.34z M300.12,189.85h-0.39l0.19-0.55L300.12,189.85z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M302.14,190.97c0.6,0,1.03-0.43,1.03-1.01
    c0-0.11-0.01-0.24-0.02-0.3l-1.34-0.52c0.02-0.16,0.17-0.29,0.37-0.29c0.19,0,0.33,0.13,0.37,0.32l0.61-0.13
    c-0.09-0.43-0.47-0.79-1-0.79c-0.6,0-1.02,0.47-1.02,1.04l0.01,0.23l1.36,0.52c-0.03,0.19-0.16,0.32-0.35,0.32
    c-0.19,0-0.34-0.12-0.4-0.31l-0.61,0.13C301.26,190.59,301.61,190.97,302.14,190.97"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="304.39,190.91 304.39,188.96 305.02,188.96
    305.02,188.33 303.1,188.33 303.1,188.96 303.73,188.96 303.73,190.91   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="306.47,190.91 306.47,189.96 307.54,189.96
    307.54,189.32 306.47,189.32 306.47,188.96 307.86,188.96 307.86,188.33 305.81,188.33 305.81,190.91   "
      />
      <path
        clip-rule="evenodd"
        d="M309.12,190.97c0.74,0,1.34-0.6,1.34-1.35
    c0-0.76-0.6-1.36-1.35-1.36c-0.74,0-1.34,0.6-1.34,1.36C307.77,190.37,308.37,190.97,309.12,190.97 M307.84,188.16
    c0,0.16,0.12,0.28,0.29,0.28c0.16,0,0.28-0.12,0.28-0.28c0-0.16-0.13-0.29-0.29-0.29C307.97,187.87,307.84,188,307.84,188.16z
     M309.84,188.16c0,0.16,0.13,0.28,0.29,0.28c0.16,0,0.28-0.12,0.28-0.28c0-0.16-0.13-0.29-0.29-0.29
    C309.97,187.87,309.84,188,309.84,188.16z M309.11,188.9c0.39,0,0.69,0.32,0.69,0.72c0,0.4-0.3,0.71-0.69,0.71
    c-0.39,0-0.69-0.31-0.69-0.71C308.43,189.22,308.73,188.9,309.11,188.9z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M311.27,190.91v-0.86h0.34l0.49,0.86h0.82l-0.55-0.94
    c0.27-0.1,0.5-0.42,0.5-0.77c0-0.52-0.4-0.87-0.87-0.87h-1.38v2.58H311.27z M311.27,188.96h0.67c0.15,0,0.27,0.08,0.27,0.24
    c0,0.15-0.13,0.24-0.27,0.24h-0.67V188.96z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="315.12,190.91 315.12,190.28 313.72,190.28
    313.72,189.94 314.8,189.94 314.8,189.31 313.72,189.31 313.72,188.96 315.12,188.96 315.12,188.33 313.07,188.33 313.07,190.91
    "
      />
      <path
        clip-rule="evenodd"
        d="M315.81,190.27h-0.61v0.64h0.61V190.27z M315.81,189.11h-0.61
    v0.64h0.61V189.11z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <g>
        <path
          clip-rule="evenodd"
          d="M290.04,169.3c0.31,0.41,0.58,0.83,0.81,1.26
      c0.23,0.43,0.42,0.86,0.58,1.3c0.16,0.44,0.27,0.9,0.35,1.35c0.08,0.46,0.11,0.91,0.11,1.36c-0.01,0.46-0.05,0.92-0.14,1.39
      c-0.09,0.46-0.21,0.91-0.38,1.37c-0.17,0.46-0.39,0.91-0.65,1.35c-0.26,0.45-0.56,0.88-0.9,1.31c-0.27,0.33-0.56,0.65-0.87,0.96
      c-0.31,0.31-0.64,0.6-0.99,0.88c-0.35,0.29-0.73,0.56-1.12,0.81c-0.39,0.25-0.8,0.49-1.23,0.72c-0.43,0.22-0.88,0.43-1.34,0.63
      c-0.46,0.19-0.93,0.37-1.42,0.53c-0.49,0.16-1,0.3-1.52,0.43c-0.52,0.12-1.05,0.23-1.59,0.32l-0.43,0.06l-0.43,0.06l-0.43,0.04
      l-0.43,0.04l-0.43,0.03l-0.44,0.02l-0.43,0.01l-0.43,0.01c-0.4,0-0.8-0.01-1.19-0.03c-0.4-0.02-0.79-0.06-1.18-0.1
      c-0.39-0.04-0.78-0.09-1.16-0.16c-0.38-0.06-0.76-0.14-1.14-0.22c-0.38-0.09-0.75-0.18-1.12-0.29c-0.37-0.1-0.73-0.22-1.08-0.35
      c-0.36-0.13-0.7-0.26-1.04-0.4c-0.34-0.15-0.68-0.3-1.01-0.46l-0.09-0.04l-0.08-0.04l-0.06,0.07l-0.06,0.07l-0.93,0.99l-0.92,0.99
      l-1.45-2.92l-1.44-2.93l4.17-0.01l4.17-0.01l-0.88,0.95l-0.88,0.95l-0.06,0.06l-0.06,0.07l0.08,0.04l0.09,0.04
      c0.27,0.14,0.55,0.26,0.83,0.37c0.28,0.12,0.57,0.23,0.86,0.33l0.89,0.28c0.3,0.08,0.6,0.16,0.91,0.23
      c0.31,0.07,0.62,0.13,0.93,0.18c0.32,0.06,0.63,0.09,0.96,0.13c0.32,0.03,0.64,0.06,0.96,0.08c0.32,0.02,0.65,0.02,0.98,0.02h0.36
      l0.36-0.01l0.36-0.02l0.36-0.03l0.36-0.03l0.36-0.04l0.36-0.04l0.36-0.06c0.44-0.07,0.88-0.16,1.3-0.26
      c0.43-0.1,0.84-0.21,1.25-0.35c0.41-0.13,0.8-0.28,1.18-0.43c0.38-0.16,0.75-0.33,1.1-0.52c0.36-0.18,0.7-0.37,1.02-0.58
      c0.32-0.21,0.64-0.43,0.93-0.66c0.29-0.23,0.57-0.47,0.83-0.72c0.26-0.25,0.5-0.51,0.73-0.78c0.16-0.2,0.31-0.4,0.46-0.6
      c0.14-0.2,0.27-0.41,0.39-0.61c0.12-0.21,0.23-0.42,0.32-0.63c0.09-0.21,0.18-0.43,0.25-0.64c0.08-0.21,0.14-0.43,0.19-0.65
      c0.06-0.22,0.09-0.43,0.12-0.65c0.03-0.22,0.05-0.44,0.06-0.65c0.01-0.22,0-0.44-0.01-0.66c-0.02-0.2-0.04-0.41-0.07-0.6
      c-0.03-0.2-0.07-0.4-0.13-0.6c-0.05-0.2-0.11-0.39-0.18-0.58l-0.23-0.58l-0.28-0.55l-0.33-0.54l-0.38-0.53l-0.43-0.5l1.41-0.01
      H290.04z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M263.38,178.63l1.53-0.01l-0.26-0.36l-0.24-0.37l-0.22-0.38
      l-0.19-0.38l-0.16-0.38l-0.15-0.4l-0.12-0.4l-0.09-0.4c-0.06-0.31-0.1-0.61-0.13-0.91c-0.02-0.3-0.02-0.6,0.01-0.91
      c0.02-0.3,0.06-0.59,0.13-0.88c0.06-0.29,0.14-0.58,0.25-0.87c0.1-0.28,0.22-0.56,0.36-0.84c0.14-0.27,0.3-0.55,0.47-0.81
      c0.18-0.26,0.37-0.52,0.58-0.78c0.21-0.25,0.44-0.49,0.69-0.74c0.24-0.24,0.5-0.47,0.78-0.68c0.27-0.22,0.56-0.42,0.86-0.62
      c0.3-0.2,0.6-0.38,0.92-0.55c0.32-0.17,0.64-0.34,0.98-0.48c0.34-0.15,0.68-0.29,1.04-0.42c0.36-0.13,0.73-0.25,1.09-0.35
      c0.37-0.1,0.75-0.19,1.14-0.28c0.39-0.08,0.79-0.15,1.18-0.2l0.31-0.04l0.31-0.03l0.31-0.03l0.31-0.02l0.31-0.02l0.31-0.01
      l0.31-0.01l0.31-0.01l0.88,0.02l0.87,0.07c0.29,0.03,0.58,0.07,0.87,0.11c0.29,0.04,0.57,0.09,0.86,0.14l0.84,0.19
      c0.28,0.07,0.56,0.15,0.82,0.24c0.28,0.08,0.54,0.18,0.81,0.28l0.79,0.31l0.01,0.01l0.01,0.01l0.01,0.01l0.01,0l0.01,0l0.01,0.01
      l0.01,0.01l0.01,0.01l0.03,0.01l0.03,0.02l0.08,0.03l0.08,0.03l-0.04,0.06l-0.05,0.05l-0.8,0.86l-0.8,0.86l3.88-0.01l3.88-0.01
      l-1.69-2.35l-1.68-2.35l-0.65,0.7l-0.65,0.7l-0.04,0.05l-0.05,0.06l-0.08-0.04l-0.08-0.03l-0.03-0.01l-0.03-0.02l-0.01-0.01
      l-0.01-0.01h-0.01l-0.01-0.01l-0.01,0l-0.01,0h-0.01l-0.01-0.01c-0.31-0.13-0.63-0.26-0.95-0.37c-0.32-0.12-0.64-0.23-0.97-0.32
      c-0.32-0.1-0.65-0.19-0.98-0.27c-0.33-0.08-0.67-0.16-1-0.23l-1.02-0.18l-1.03-0.13l-1.04-0.07c-0.35-0.02-0.7-0.03-1.05-0.03
      l-0.37,0.01l-0.37,0.01l-0.37,0.01l-0.37,0.03l-0.37,0.02l-0.37,0.04l-0.37,0.04l-0.37,0.04c-0.47,0.07-0.94,0.15-1.4,0.24
      c-0.46,0.09-0.91,0.2-1.36,0.32c-0.45,0.12-0.88,0.26-1.3,0.41c-0.42,0.15-0.84,0.31-1.24,0.49c-0.4,0.18-0.8,0.37-1.18,0.57
      c-0.39,0.2-0.75,0.42-1.1,0.65c-0.36,0.23-0.7,0.48-1.03,0.74c-0.33,0.25-0.64,0.53-0.94,0.81c-0.3,0.29-0.58,0.58-0.84,0.88
      c-0.26,0.3-0.49,0.61-0.71,0.92c-0.21,0.31-0.41,0.64-0.58,0.97c-0.18,0.32-0.32,0.66-0.45,1c-0.13,0.34-0.23,0.69-0.31,1.04
      c-0.08,0.35-0.14,0.7-0.18,1.06c-0.03,0.36-0.04,0.72-0.02,1.09c0.02,0.36,0.06,0.73,0.13,1.1l0.08,0.35l0.08,0.34l0.1,0.34
      l0.12,0.34l0.13,0.34l0.15,0.34l0.16,0.33l0.18,0.32H263.38z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.43,167.46c0.08-0.02,0.18-0.04,0.29-0.04l0.35-0.02
      c0.12,0,0.25,0.01,0.36,0.04c0.12,0.03,0.22,0.08,0.3,0.14c0.08,0.06,0.14,0.13,0.18,0.21c0.04,0.08,0.07,0.18,0.07,0.29
      c0,0.15-0.04,0.28-0.1,0.4c-0.06,0.12-0.14,0.22-0.24,0.29c-0.1,0.08-0.23,0.14-0.37,0.18c-0.14,0.04-0.29,0.06-0.44,0.06
      l-0.13-0.01l-0.12-0.01l-0.09,0.48l-0.09,0.47h-0.23h-0.22l0.24-1.24L270.43,167.46z M270.65,168.62l0.11,0.02l0.13,0.01
      c0.19,0,0.35-0.06,0.46-0.15c0.11-0.09,0.17-0.23,0.17-0.38c0-0.13-0.05-0.22-0.13-0.28c-0.08-0.06-0.2-0.08-0.33-0.08l-0.15,0.01
      l-0.1,0.02l-0.08,0.43L270.65,168.62z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M273.21,169.93l0.02-0.19l0.02-0.23h-0.01h-0.01
      c-0.09,0.17-0.2,0.28-0.32,0.35c-0.12,0.07-0.24,0.1-0.36,0.1c-0.16,0-0.31-0.06-0.4-0.17c-0.1-0.11-0.15-0.26-0.15-0.44
      c0-0.31,0.12-0.63,0.33-0.87c0.22-0.24,0.55-0.41,0.99-0.41c0.1,0,0.21,0.01,0.31,0.03c0.1,0.01,0.19,0.03,0.26,0.06l-0.1,0.47
      l-0.1,0.47l-0.06,0.43c-0.01,0.15-0.02,0.29-0.02,0.4h-0.2H273.21z M273.4,168.43l-0.08-0.02l-0.11-0.01
      c-0.21,0-0.39,0.12-0.52,0.28c-0.13,0.16-0.21,0.38-0.21,0.58c0,0.1,0.02,0.19,0.06,0.25c0.04,0.06,0.11,0.1,0.21,0.1
      c0.1,0,0.22-0.07,0.32-0.19c0.1-0.12,0.19-0.3,0.23-0.53l0.05-0.24L273.4,168.43z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M274.11,169.93l0.12-0.63l0.13-0.63l0.06-0.3l0.04-0.26h0.2h0.2
      l-0.02,0.16l-0.02,0.17h0.01c0.09-0.13,0.19-0.22,0.3-0.28c0.11-0.06,0.24-0.09,0.37-0.09c0.13-0.01,0.25,0.03,0.34,0.11
      c0.09,0.08,0.14,0.21,0.14,0.38l-0.01,0.15l-0.02,0.15l-0.1,0.53l-0.1,0.53h-0.23h-0.22l0.1-0.51l0.1-0.52l0.02-0.1l0.01-0.11
      c0-0.07-0.02-0.14-0.05-0.19c-0.04-0.04-0.1-0.07-0.19-0.07c-0.1,0-0.22,0.06-0.32,0.17c-0.1,0.11-0.19,0.28-0.23,0.49l-0.08,0.42
      l-0.08,0.42h-0.23H274.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.24,167.65l-0.05,0.24l-0.04,0.23h0.21h0.21l-0.03,0.17
      l-0.04,0.17h-0.21h-0.21l-0.08,0.36l-0.08,0.37l-0.02,0.13l0,0.12c0,0.06,0.01,0.09,0.04,0.12c0.02,0.03,0.07,0.04,0.13,0.04h0.08
      l0.07-0.01l-0.02,0.17l-0.02,0.17c-0.03,0.02-0.08,0.02-0.13,0.03l-0.16,0.01c-0.15,0-0.27-0.04-0.34-0.11
      c-0.07-0.07-0.11-0.16-0.11-0.26l0.01-0.15l0.03-0.18l0.08-0.41l0.08-0.4h-0.13h-0.13l0.03-0.17l0.03-0.17h0.13h0.13l0.03-0.17
      l0.03-0.17l0.24-0.07L277.24,167.65z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M278.57,167.65l-0.05,0.24l-0.04,0.23h0.21h0.21l-0.03,0.17
      l-0.03,0.17h-0.21h-0.21l-0.08,0.36l-0.07,0.37l-0.02,0.13l-0.01,0.12c0,0.06,0.01,0.09,0.04,0.12c0.03,0.03,0.07,0.04,0.13,0.04
      h0.08l0.07-0.01l-0.02,0.17l-0.02,0.18l-0.13,0.02l-0.17,0.01c-0.15,0-0.26-0.04-0.34-0.11c-0.07-0.07-0.1-0.16-0.1-0.26
      l0.01-0.15l0.03-0.18l0.08-0.41l0.08-0.4h-0.13h-0.13l0.03-0.17l0.03-0.17h0.13h0.13l0.03-0.17l0.03-0.17l0.24-0.07L278.57,167.65
      z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M278.93,169.93l0.18-0.91l0.18-0.91h0.23h0.23l-0.18,0.91
      l-0.18,0.91h-0.23H278.93z M279.59,167.85c-0.07,0-0.13-0.02-0.17-0.06c-0.04-0.04-0.06-0.1-0.06-0.17c0-0.08,0.03-0.14,0.08-0.19
      c0.05-0.05,0.12-0.08,0.19-0.08c0.07,0,0.13,0.03,0.17,0.07c0.04,0.04,0.07,0.1,0.07,0.17c0,0.08-0.03,0.14-0.08,0.19
      C279.74,167.83,279.67,167.85,279.59,167.85L279.59,167.85z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M274.22,178.58c0.08-0.02,0.18-0.03,0.29-0.04l0.34-0.02
      c0.12,0,0.25,0.01,0.36,0.04c0.12,0.03,0.22,0.08,0.3,0.14c0.08,0.05,0.14,0.12,0.18,0.2c0.04,0.08,0.07,0.18,0.07,0.29
      c0,0.15-0.04,0.29-0.1,0.4c-0.06,0.12-0.14,0.21-0.24,0.28c-0.1,0.08-0.23,0.14-0.37,0.18c-0.14,0.04-0.29,0.06-0.45,0.06
      l-0.13-0.01l-0.11-0.01l-0.09,0.48l-0.09,0.47h-0.22h-0.23l0.24-1.23L274.22,178.58z M274.43,179.74l0.1,0.02l0.13,0.01
      c0.19,0,0.35-0.06,0.46-0.15c0.11-0.09,0.18-0.23,0.18-0.38c0-0.13-0.05-0.22-0.13-0.28c-0.08-0.06-0.19-0.08-0.33-0.08
      l-0.14,0.01l-0.1,0.02l-0.08,0.42L274.43,179.74z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.01,181.05l0.01-0.19l0.02-0.22h-0.01h-0.01
      c-0.09,0.17-0.2,0.27-0.32,0.35c-0.12,0.07-0.24,0.1-0.35,0.1c-0.17,0-0.31-0.06-0.41-0.17c-0.1-0.11-0.15-0.26-0.15-0.44
      c0-0.31,0.11-0.63,0.34-0.87c0.22-0.24,0.55-0.41,0.99-0.41c0.1,0,0.21,0,0.31,0.02l0.26,0.06l-0.1,0.48l-0.09,0.47l-0.07,0.43
      l-0.02,0.39h-0.2H277.01z M277.19,179.55l-0.08-0.01l-0.1-0.01c-0.22,0-0.4,0.11-0.53,0.28c-0.13,0.16-0.21,0.38-0.21,0.59
      c0,0.09,0.02,0.18,0.06,0.24c0.04,0.07,0.11,0.1,0.21,0.1c0.1,0,0.22-0.07,0.32-0.19c0.1-0.12,0.19-0.3,0.23-0.53l0.05-0.24
      L277.19,179.55z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.92,181.05l0.12-0.63l0.13-0.63l0.06-0.3l0.04-0.26h0.2h0.2
      l-0.02,0.16l-0.02,0.17h0.01c0.09-0.13,0.19-0.22,0.3-0.28c0.12-0.06,0.24-0.09,0.37-0.09c0.13,0,0.25,0.04,0.34,0.12
      c0.09,0.08,0.14,0.2,0.14,0.38l-0.01,0.14l-0.02,0.16l-0.1,0.53l-0.1,0.53h-0.23h-0.23l0.1-0.51l0.1-0.51l0.02-0.11l0.01-0.11
      c0-0.07-0.02-0.14-0.05-0.19c-0.04-0.04-0.1-0.07-0.19-0.07c-0.1,0-0.22,0.06-0.32,0.17c-0.1,0.12-0.19,0.28-0.23,0.49l-0.08,0.42
      l-0.08,0.42h-0.23H277.92z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M281.06,178.77l-0.04,0.24l-0.05,0.23h0.21h0.22l-0.04,0.17
      l-0.03,0.16h-0.21h-0.22l-0.08,0.37l-0.07,0.37l-0.02,0.13l-0.01,0.13c0,0.05,0.02,0.09,0.04,0.12c0.03,0.03,0.07,0.05,0.13,0.05
      l0.08-0.01l0.08-0.01l-0.02,0.18l-0.02,0.17l-0.13,0.03l-0.16,0.01c-0.16,0-0.27-0.04-0.35-0.11c-0.07-0.07-0.1-0.16-0.1-0.26
      l0.01-0.15l0.03-0.18l0.08-0.4l0.08-0.41h-0.13h-0.13l0.03-0.16l0.03-0.17h0.13h0.13l0.03-0.16l0.03-0.17l0.24-0.07L281.06,178.77
      z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.1,173.57c0,0.86-0.21,1.81-0.63,2.55
      c-0.42,0.74-1.07,1.26-1.93,1.26c-0.57,0-0.97-0.22-1.23-0.58c-0.26-0.36-0.37-0.85-0.37-1.4c0-0.87,0.22-1.8,0.65-2.51
      c0.43-0.71,1.07-1.2,1.92-1.2c0.59,0,0.99,0.23,1.24,0.57C270.01,172.61,270.1,173.08,270.1,173.57 M267.14,175.44
      c0,0.2,0.02,0.45,0.09,0.64c0.08,0.19,0.22,0.34,0.46,0.34c0.42,0,0.72-0.53,0.92-1.14c0.2-0.62,0.29-1.33,0.29-1.69
      c0-0.18-0.01-0.42-0.08-0.6c-0.07-0.19-0.2-0.33-0.43-0.33c-0.41,0-0.72,0.49-0.94,1.09
      C267.25,174.34,267.14,175.03,267.14,175.44z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M269.7,178.29c0.19-0.37,0.37-0.79,0.54-1.2
      c0.17-0.41,0.32-0.82,0.43-1.21l0.66-0.04l0.66-0.04c-0.19,0.43-0.42,0.87-0.66,1.29c-0.24,0.41-0.49,0.8-0.75,1.13l-0.44,0.04
      L269.7,178.29z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M285.82,177.1c-0.14,0.08-0.33,0.14-0.56,0.2
      c-0.23,0.05-0.5,0.08-0.78,0.08c-0.67,0-1.2-0.2-1.58-0.57c-0.38-0.36-0.59-0.88-0.62-1.51h-0.31h-0.31l0.06-0.3l0.07-0.31h0.27
      h0.26l0.03-0.2l0.03-0.19h-0.26h-0.27l0.07-0.3l0.06-0.3h0.3h0.29c0.21-0.58,0.6-1.08,1.1-1.44c0.5-0.36,1.11-0.58,1.75-0.58
      c0.29,0,0.54,0.03,0.75,0.08c0.21,0.05,0.37,0.11,0.5,0.18l-0.18,0.47l-0.19,0.46c-0.08-0.04-0.2-0.08-0.36-0.11
      c-0.16-0.03-0.35-0.06-0.57-0.06c-0.34,0-0.66,0.12-0.92,0.3c-0.26,0.18-0.47,0.43-0.59,0.7h0.99h0.98l-0.07,0.3l-0.07,0.3h-1.03
      h-1.03l-0.04,0.19l-0.03,0.2h1.03h1.03l-0.07,0.31l-0.07,0.3h-0.96h-0.96c0.04,0.32,0.16,0.58,0.35,0.77
      c0.19,0.18,0.46,0.29,0.8,0.29c0.19,0,0.4-0.03,0.57-0.07c0.18-0.03,0.33-0.08,0.43-0.12l0.06,0.46L285.82,177.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <polygon
          clip-rule="evenodd"
          fill="#FFFFFF"
          fill-rule="evenodd"
          points="274.73,172.86 274.71,172.86 273.61,173.35
      273.57,172.38 275.13,171.7 276.14,171.7 275.09,177.3 273.89,177.3     "
        />
        <path
          clip-rule="evenodd"
          d="M281.56,173.52c0,1.75-0.85,3.87-2.61,3.87
      c-1.17,0-1.62-0.9-1.62-2.02c0-1.76,0.9-3.78,2.62-3.78C281.16,171.6,281.56,172.52,281.56,173.52 M278.54,175.42
      c0,0.41,0.07,0.99,0.56,0.99c0.86,0,1.23-2.13,1.23-2.88c0-0.37-0.05-0.95-0.52-0.95C278.98,172.58,278.54,174.6,278.54,175.42z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <g>
        <path
          d="M267.93,58.05c0.04,0,0.08,0.01,0.11,0.04s0.05,0.05,0.06,0.09l1.2,3.6h-0.49l-0.97-3.06
      c-0.01-0.05-0.03-0.1-0.05-0.15c-0.02-0.05-0.03-0.1-0.05-0.15h-0.15c-0.01,0.05-0.03,0.1-0.04,0.15
      c-0.01,0.05-0.03,0.1-0.05,0.15l-0.97,3.06h-0.49l1.2-3.6c0.01-0.04,0.03-0.07,0.06-0.09s0.07-0.04,0.11-0.04H267.93z
       M268.62,60.2v0.41h-1.89V60.2H268.62z"
          fill="#FFFFFF"
        />
        <path
          d="M270.03,58.03c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H270.03z M270.15,59.14v2.63h-0.45v-2.63H270.15z"
          fill="#FFFFFF"
        />
        <path
          d="M271.29,59.14l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H271.29z M272.5,59.08c0.47,0,0.71,0.24,0.71,0.72v1.97
      h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C272.1,59.12,272.3,59.08,272.5,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M275.01,59.08c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C274.5,59.12,274.73,59.08,275.01,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M277.6,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S277.36,59.08,277.6,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M280.47,59.08c0.3,0,0.54,0.04,0.73,0.13c0.18,0.09,0.31,0.24,0.39,0.44s0.12,0.47,0.12,0.81
      c0,0.33-0.04,0.6-0.12,0.81s-0.21,0.35-0.39,0.44s-0.42,0.13-0.73,0.13c-0.3,0-0.54-0.04-0.72-0.13s-0.31-0.24-0.39-0.44
      c-0.08-0.2-0.12-0.47-0.12-0.81c0-0.33,0.04-0.6,0.12-0.81c0.08-0.2,0.21-0.35,0.39-0.44C279.93,59.12,280.17,59.08,280.47,59.08z
       M280.47,59.47c-0.19,0-0.34,0.03-0.45,0.09c-0.11,0.06-0.19,0.16-0.24,0.31c-0.05,0.14-0.07,0.34-0.07,0.6
      c0,0.25,0.02,0.46,0.07,0.6c0.05,0.14,0.12,0.25,0.24,0.31c0.11,0.06,0.26,0.09,0.45,0.09c0.19,0,0.34-0.03,0.45-0.09
      c0.11-0.06,0.19-0.16,0.24-0.31c0.05-0.14,0.07-0.34,0.07-0.6c0-0.26-0.02-0.46-0.07-0.6c-0.05-0.14-0.13-0.25-0.24-0.31
      C280.81,59.5,280.66,59.47,280.47,59.47z"
          fill="#FFFFFF"
        />
        <path
          d="M283.19,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S282.95,59.08,283.19,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M286.88,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H286.88z M286.19,59.08c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C285.92,59.09,286.06,59.08,286.19,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M289.3,59.14v0.36h-1.77v-0.33l0.52-0.03H289.3z M288.47,58.37v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H288.47z"
          fill="#FFFFFF"
        />
        <path
          d="M290.03,59.4c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.13v0.29c0,0.06-0.01,0.11-0.04,0.13
      c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04s-0.04-0.07-0.04-0.13v-0.29c0-0.06,0.01-0.11,0.04-0.13
      s0.07-0.04,0.13-0.04H290.03z M290.03,61.14c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29
      c0,0.06-0.01,0.11-0.04,0.13c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13
      v-0.29c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H290.03z"
          fill="#FFFFFF"
        />
        <path
          d="M294.24,59.14l-0.96,2.52c-0.01,0.04-0.04,0.06-0.06,0.08c-0.03,0.02-0.06,0.03-0.1,0.03h-0.42
      c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.06-0.08l-0.96-2.52h0.49l0.65,1.84c0.03,0.08,0.06,0.15,0.08,0.23
      s0.05,0.15,0.08,0.23h0.07c0.03-0.08,0.06-0.15,0.08-0.23s0.05-0.15,0.08-0.23l0.65-1.84H294.24z"
          fill="#FFFFFF"
        />
        <path
          d="M295.7,59.08c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C295.19,59.12,295.42,59.08,295.7,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M298.29,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S298.05,59.08,298.29,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M300.4,58.03c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H300.4z M300.52,59.14v2.63h-0.45v-2.63H300.52z"
          fill="#FFFFFF"
        />
        <path
          d="M301.54,61.14c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
      c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
      c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
      H301.54z"
          fill="#FFFFFF"
        />
        <path
          d="M304.67,59.04c0.33,0,0.6,0.05,0.8,0.14c0.2,0.09,0.35,0.24,0.43,0.45c0.09,0.21,0.13,0.48,0.13,0.83
      c0,0.34-0.04,0.61-0.13,0.82c-0.09,0.21-0.23,0.35-0.43,0.44c-0.2,0.09-0.47,0.13-0.8,0.13c-0.33,0-0.6-0.04-0.8-0.13
      s-0.35-0.24-0.44-0.44s-0.13-0.48-0.13-0.82c0-0.35,0.04-0.62,0.13-0.83s0.24-0.35,0.44-0.45S304.34,59.04,304.67,59.04z
       M304.67,59.62c-0.14,0-0.25,0.02-0.32,0.07s-0.13,0.13-0.17,0.25c-0.03,0.12-0.05,0.29-0.05,0.51c0,0.22,0.02,0.38,0.05,0.5
      s0.09,0.2,0.17,0.25s0.19,0.07,0.32,0.07s0.25-0.02,0.32-0.07s0.13-0.13,0.17-0.25s0.05-0.29,0.05-0.5c0-0.22-0.02-0.39-0.05-0.51
      c-0.03-0.12-0.09-0.2-0.17-0.25S304.81,59.62,304.67,59.62z"
          fill="#FFFFFF"
        />
        <path
          d="M307.28,58l0.01,0.92c0,0.14-0.01,0.27-0.02,0.4c-0.02,0.13-0.04,0.23-0.06,0.32l0.08,0.07v2.07h-0.8V58
      H307.28z M308.34,59.04c0.5,0,0.76,0.25,0.76,0.74v2h-0.8v-1.75c0-0.12-0.02-0.2-0.06-0.25c-0.04-0.04-0.11-0.07-0.22-0.07
      c-0.12,0-0.23,0.02-0.36,0.06c-0.12,0.04-0.27,0.11-0.45,0.21l-0.07-0.42c0.2-0.17,0.4-0.3,0.6-0.4
      C307.94,59.08,308.14,59.04,308.34,59.04z"
          fill="#FFFFFF"
        />
        <path
          d="M310.24,59.11l0.1,0.53l0.08,0.07v2.07h-0.8v-2.67H310.24z M311.54,59.04l-0.08,0.74h-0.22
      c-0.13,0-0.26,0.02-0.39,0.05s-0.31,0.09-0.52,0.16l-0.05-0.45c0.19-0.17,0.38-0.29,0.57-0.38c0.19-0.09,0.38-0.13,0.56-0.13
      H311.54z"
          fill="#FFFFFF"
        />
        <path
          d="M313.92,60.13l-0.01,0.45l-1.19,0.01c-0.1,0-0.17,0.02-0.21,0.07c-0.04,0.05-0.06,0.11-0.06,0.19v0.13
      c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.13,0.07,0.24,0.07c0.09,0,0.2-0.02,0.31-0.06c0.11-0.04,0.22-0.09,0.33-0.17
      c0.11-0.07,0.2-0.16,0.29-0.25v0.35c-0.03,0.05-0.08,0.12-0.14,0.2c-0.06,0.08-0.14,0.16-0.24,0.24c-0.1,0.08-0.21,0.14-0.34,0.2
      c-0.13,0.05-0.28,0.08-0.44,0.08c-0.16,0-0.31-0.03-0.44-0.09c-0.13-0.06-0.23-0.14-0.31-0.25c-0.08-0.11-0.11-0.25-0.11-0.42
      v-0.28c0-0.21,0.07-0.38,0.22-0.5c0.14-0.12,0.34-0.18,0.59-0.18H313.92z M313.21,59.04c0.22,0,0.41,0.03,0.57,0.09
      c0.16,0.06,0.27,0.16,0.35,0.3c0.08,0.14,0.12,0.34,0.12,0.59v1.76h-0.64l-0.11-0.58l-0.05-0.08v-1.11c0-0.13-0.03-0.22-0.09-0.28
      s-0.17-0.09-0.34-0.09c-0.14,0-0.31,0-0.51,0.01c-0.21,0.01-0.42,0.02-0.63,0.04l-0.08-0.53c0.13-0.03,0.28-0.05,0.45-0.07
      c0.17-0.02,0.34-0.03,0.51-0.05C312.92,59.04,313.07,59.04,313.21,59.04z"
          fill="#FFFFFF"
        />
        <path
          d="M315.24,59.14l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H315.24z M316.34,59.08c0.22,0,0.39,0.06,0.51,0.18
      c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
      c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
      S316.15,59.08,316.34,59.08z M318.06,59.08c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
      c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
      c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C317.7,59.12,317.88,59.08,318.06,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M321.41,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H321.41z M320.73,59.08c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C320.46,59.09,320.6,59.08,320.73,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M322.8,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5V58H322.8z"
          fill="#FFFFFF"
        />
        <path
          d="M324.3,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5V58H324.3z"
          fill="#FFFFFF"
        />
        <path
          d="M327.26,60.15l-0.01,0.35L326,60.51c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H327.26z M326.57,59.08c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C326.3,59.09,326.44,59.08,326.57,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M329.06,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S328.82,59.08,329.06,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M331.09,61.14c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
      c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
      c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
      H331.09z"
          fill="#FFFFFF"
        />
        <path
          d="M334.25,59.02c0.35,0,0.64,0.05,0.85,0.14c0.21,0.09,0.37,0.24,0.46,0.45s0.14,0.48,0.14,0.83
      c0,0.35-0.05,0.62-0.14,0.82s-0.25,0.35-0.46,0.44c-0.21,0.09-0.5,0.14-0.85,0.14s-0.63-0.05-0.85-0.14
      c-0.21-0.09-0.37-0.24-0.46-0.44s-0.14-0.48-0.14-0.82c0-0.35,0.05-0.63,0.14-0.83s0.25-0.36,0.46-0.45
      C333.61,59.06,333.89,59.02,334.25,59.02z M334.25,59.7c-0.11,0-0.2,0.02-0.26,0.06c-0.06,0.04-0.1,0.11-0.13,0.22
      c-0.03,0.1-0.04,0.26-0.04,0.46c0,0.2,0.01,0.35,0.04,0.45c0.02,0.1,0.07,0.18,0.13,0.21c0.06,0.04,0.14,0.06,0.26,0.06
      s0.2-0.02,0.26-0.06c0.06-0.04,0.1-0.11,0.13-0.21s0.04-0.25,0.04-0.45c0-0.2-0.01-0.35-0.04-0.46s-0.07-0.18-0.13-0.22
      C334.44,59.72,334.36,59.7,334.25,59.7z"
          fill="#FFFFFF"
        />
        <path
          d="M337.01,58l0.01,0.86c0,0.15-0.01,0.3-0.03,0.44c-0.02,0.14-0.05,0.25-0.07,0.34l0.1,0.07v2.07h-0.98V58
      H337.01z M338.02,59.02c0.52,0,0.78,0.25,0.78,0.75v2.01h-0.98v-1.7c0-0.09-0.02-0.15-0.05-0.18s-0.09-0.05-0.18-0.05
      c-0.09,0-0.19,0.01-0.29,0.04c-0.1,0.03-0.23,0.07-0.39,0.14l-0.1-0.45c0.19-0.18,0.39-0.32,0.6-0.42
      C337.62,59.06,337.83,59.02,338.02,59.02z"
          fill="#FFFFFF"
        />
        <path
          d="M340,59.09l0.12,0.54l0.1,0.07v2.07h-0.98v-2.68H340z M341.3,59.02l-0.09,0.9h-0.26
      c-0.11,0-0.23,0.01-0.36,0.03c-0.13,0.02-0.29,0.06-0.49,0.11l-0.06-0.54c0.18-0.17,0.37-0.3,0.55-0.38
      c0.19-0.08,0.37-0.13,0.55-0.13H341.3z"
          fill="#FFFFFF"
        />
        <path
          d="M343.69,60.13l-0.01,0.5h-1.15c-0.08,0-0.14,0.02-0.18,0.06c-0.04,0.04-0.06,0.09-0.06,0.15v0.1
      c0,0.07,0.02,0.12,0.07,0.16c0.04,0.04,0.11,0.06,0.2,0.06s0.19-0.02,0.29-0.06s0.2-0.09,0.3-0.16c0.1-0.07,0.18-0.14,0.25-0.22
      v0.35c-0.03,0.06-0.07,0.13-0.13,0.22c-0.06,0.09-0.14,0.17-0.23,0.26c-0.09,0.09-0.21,0.16-0.34,0.22s-0.29,0.09-0.46,0.09
      c-0.16,0-0.31-0.03-0.45-0.09s-0.25-0.14-0.33-0.25c-0.08-0.11-0.12-0.25-0.12-0.42v-0.27c0-0.22,0.08-0.38,0.23-0.5
      c0.15-0.12,0.36-0.18,0.62-0.18H343.69z M342.96,59.02c0.24,0,0.45,0.03,0.61,0.1c0.17,0.07,0.29,0.17,0.38,0.32
      c0.09,0.15,0.13,0.36,0.13,0.63v1.71h-0.78l-0.13-0.58l-0.06-0.1v-1.04c0-0.11-0.03-0.19-0.08-0.24
      c-0.05-0.05-0.15-0.07-0.28-0.07c-0.14,0-0.31,0-0.52,0.01c-0.21,0.01-0.42,0.02-0.64,0.03l-0.09-0.63
      c0.13-0.03,0.29-0.05,0.47-0.07c0.18-0.02,0.36-0.04,0.54-0.05S342.83,59.02,342.96,59.02z"
          fill="#FFFFFF"
        />
        <path
          d="M346.14,59.14v0.36h-1.77v-0.33l0.52-0.03H346.14z M345.3,58.37v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H345.3z"
          fill="#FFFFFF"
        />
        <path
          d="M348.52,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H348.52z M347.84,59.08c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C347.56,59.09,347.71,59.08,347.84,59.08z M347.25,58.05
      c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13
      H347.25z M348.2,58.05c0.09,0,0.13,0.04,0.13,0.13v0.3c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3
      c0-0.09,0.04-0.13,0.13-0.13H348.2z"
          fill="#FFFFFF"
        />
        <path
          d="M349.81,59.14l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H349.81z M351.1,59.08l-0.05,0.43h-0.15
      c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
      c0.2-0.09,0.39-0.13,0.57-0.13H351.1z"
          fill="#FFFFFF"
        />
        <path
          d="M351.96,58l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
      s0.01,0.2,0.01,0.29v0.9h-0.46V58H351.96z M352.41,60.12v0.33h-0.61v-0.33H352.41z M353.66,59.14l-0.83,0.97
      c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
      l-1.04-1.51l0.96-1.12H353.66z"
          fill="#FFFFFF"
        />
        <path
          d="M354.61,58l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
      s0.01,0.2,0.01,0.29v0.9h-0.46V58H354.61z M355.05,60.12v0.33h-0.61v-0.33H355.05z M356.3,59.14l-0.83,0.97
      c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
      l-1.04-1.51l0.96-1.12H356.3z"
          fill="#FFFFFF"
        />
        <path
          d="M357.79,59.08c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C357.28,59.12,357.51,59.08,357.79,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M359.96,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5V58H359.96z"
          fill="#FFFFFF"
        />
        <path
          d="M361.04,59.14l0.7,1.96c0.02,0.05,0.04,0.11,0.05,0.16c0.02,0.05,0.03,0.1,0.04,0.16h0.12l-0.15,0.35
      h-0.14c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.06-0.09l-0.96-2.51H361.04z M363.21,59.14l-1.05,2.78
      c-0.04,0.12-0.1,0.23-0.16,0.34c-0.07,0.11-0.14,0.21-0.24,0.3s-0.2,0.17-0.32,0.22c-0.12,0.06-0.27,0.09-0.43,0.1l-0.04-0.3
      c0.21-0.07,0.38-0.16,0.49-0.28s0.21-0.25,0.28-0.42l0.13-0.31c0.03-0.07,0.06-0.15,0.09-0.24c0.03-0.09,0.06-0.16,0.08-0.23
      l0.69-1.96H363.21z"
          fill="#FFFFFF"
        />
        <path
          d="M364.5,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S364.26,59.08,364.5,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M366.52,61.14c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
      c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
      c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
      H366.52z"
          fill="#FFFFFF"
        />
        <path
          d="M368.94,58l0.01,1.04c0,0.12,0,0.23-0.01,0.33s-0.02,0.2-0.04,0.28l0.05,0.08v2.05h-0.46V58H368.94z
       M370.08,59.08c0.47,0,0.71,0.24,0.71,0.72v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.08-0.37c-0.06-0.07-0.15-0.1-0.29-0.1
      c-0.16,0-0.32,0.04-0.49,0.11c-0.16,0.08-0.35,0.19-0.58,0.33l-0.02-0.33c0.21-0.15,0.41-0.28,0.61-0.37
      C369.68,59.12,369.88,59.08,370.08,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M371.95,59.14v1.85c0,0.17,0.03,0.28,0.09,0.35c0.06,0.07,0.17,0.1,0.32,0.1c0.15,0,0.29-0.04,0.44-0.11
      s0.32-0.17,0.53-0.3l0.06,0.32c-0.21,0.16-0.4,0.28-0.6,0.37c-0.19,0.08-0.39,0.12-0.59,0.12c-0.48,0-0.72-0.24-0.72-0.72v-1.98
      H371.95z M373.74,59.14v2.63h-0.37l-0.04-0.5l-0.05-0.08v-2.05H373.74z"
          fill="#FFFFFF"
        />
        <path
          d="M374.85,59.14l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H374.85z M375.96,59.08c0.22,0,0.39,0.06,0.51,0.18
      c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
      c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
      S375.77,59.08,375.96,59.08z M377.68,59.08c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
      c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
      c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C377.31,59.12,377.49,59.08,377.68,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M381.02,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H381.02z M380.34,59.08c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C380.07,59.09,380.21,59.08,380.34,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M382.42,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5V58H382.42z"
          fill="#FFFFFF"
        />
        <path
          d="M385.38,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H385.38z M384.69,59.08c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C384.42,59.09,384.56,59.08,384.69,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M386.57,61.14c0.06,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29c0,0.06-0.01,0.11-0.04,0.13
      c-0.03,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.11-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13v-0.29
      c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H386.57z"
          fill="#FFFFFF"
        />
        <path d="M266.95,64.35v3.73h-0.48v-3.73H266.95z" fill="#FFFFFF" />
        <path
          d="M268.12,65.44l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H268.12z M269.33,65.38c0.47,0,0.71,0.24,0.71,0.72
      v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C268.93,65.42,269.13,65.38,269.33,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M271.05,66.88l0.26,0.04c-0.06,0.05-0.1,0.12-0.12,0.2c-0.02,0.08-0.02,0.15,0.02,0.21
      s0.11,0.11,0.22,0.12l0.98,0.16c0.25,0.04,0.42,0.11,0.52,0.23c0.1,0.12,0.15,0.29,0.15,0.51c0,0.21-0.04,0.37-0.12,0.49
      c-0.08,0.12-0.21,0.2-0.39,0.25c-0.18,0.05-0.43,0.08-0.75,0.08c-0.24,0-0.44-0.01-0.6-0.04c-0.16-0.03-0.29-0.07-0.39-0.13
      c-0.1-0.06-0.17-0.14-0.21-0.25c-0.04-0.1-0.06-0.23-0.06-0.38c0-0.12,0.02-0.21,0.05-0.29s0.09-0.15,0.16-0.21
      s0.17-0.12,0.29-0.18l0.32-0.18l0.21,0.08l-0.26,0.2c-0.07,0.05-0.13,0.11-0.18,0.16c-0.05,0.05-0.08,0.11-0.11,0.17
      c-0.02,0.06-0.04,0.13-0.04,0.21c0,0.12,0.02,0.22,0.07,0.28c0.05,0.07,0.13,0.11,0.25,0.13c0.12,0.02,0.28,0.04,0.5,0.04
      c0.21,0,0.38-0.01,0.49-0.04s0.2-0.07,0.25-0.13c0.05-0.07,0.07-0.16,0.07-0.28c0-0.09-0.01-0.16-0.04-0.21s-0.07-0.08-0.13-0.11
      c-0.06-0.03-0.16-0.05-0.29-0.06l-0.94-0.13c-0.11-0.01-0.2-0.05-0.27-0.11s-0.11-0.13-0.14-0.21c-0.03-0.08-0.03-0.16-0.02-0.24
      c0.01-0.08,0.04-0.16,0.08-0.23S270.99,66.92,271.05,66.88z M271.8,65.38c0.28,0,0.5,0.03,0.67,0.09s0.28,0.15,0.35,0.27
      c0.07,0.12,0.11,0.28,0.11,0.48c0,0.19-0.04,0.35-0.11,0.47c-0.07,0.12-0.19,0.22-0.35,0.28c-0.17,0.06-0.39,0.09-0.67,0.09
      c-0.28,0-0.5-0.03-0.67-0.09s-0.28-0.15-0.35-0.27c-0.07-0.12-0.11-0.28-0.11-0.47c0-0.2,0.04-0.36,0.11-0.48
      c0.07-0.12,0.19-0.21,0.35-0.27C271.3,65.41,271.52,65.38,271.8,65.38z M271.79,65.73c-0.26,0-0.45,0.04-0.55,0.11
      c-0.1,0.07-0.15,0.2-0.15,0.38c0,0.17,0.05,0.3,0.15,0.37c0.1,0.08,0.29,0.11,0.55,0.11c0.27,0,0.45-0.04,0.55-0.11
      c0.1-0.08,0.15-0.2,0.15-0.37c0-0.18-0.05-0.3-0.15-0.38C272.24,65.77,272.06,65.73,271.79,65.73z M273.23,65.44l-0.03,0.25
      l-0.67,0.05l-0.19-0.31H273.23z"
          fill="#FFFFFF"
        />
        <path
          d="M274.02,65.44l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H274.02z M275.31,65.38l-0.05,0.43h-0.15
      c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
      c0.2-0.09,0.39-0.13,0.57-0.13H275.31z"
          fill="#FFFFFF"
        />
        <path
          d="M276.73,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C276.22,65.42,276.45,65.38,276.73,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M279.31,65.38c0.17,0,0.34,0.02,0.51,0.07s0.33,0.11,0.49,0.21l-0.03,0.29c-0.17-0.05-0.32-0.09-0.45-0.12
      c-0.13-0.03-0.27-0.04-0.42-0.04c-0.15,0-0.27,0.03-0.36,0.08c-0.09,0.05-0.16,0.15-0.2,0.29s-0.06,0.34-0.06,0.6
      s0.02,0.46,0.06,0.6c0.04,0.14,0.11,0.25,0.2,0.3s0.21,0.09,0.35,0.09c0.1,0,0.2-0.01,0.28-0.04c0.08-0.03,0.17-0.07,0.27-0.13
      c0.09-0.06,0.21-0.14,0.34-0.23l0.04,0.32c-0.14,0.13-0.31,0.25-0.49,0.34c-0.18,0.09-0.38,0.14-0.59,0.14
      c-0.33,0-0.57-0.12-0.71-0.35s-0.21-0.58-0.21-1.04c0-0.34,0.04-0.61,0.11-0.81c0.07-0.2,0.18-0.34,0.33-0.43
      C278.91,65.42,279.09,65.38,279.31,65.38z M280.69,64.29v3.78h-0.36l-0.05-0.46l-0.05-0.04v-1.8l0.03-0.08
      c-0.01-0.1-0.02-0.2-0.03-0.29c0-0.09-0.01-0.19-0.01-0.3v-0.81H280.69z"
          fill="#FFFFFF"
        />
        <path
          d="M281.78,64.33c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H281.78z M281.9,65.44v2.63h-0.45v-2.63H281.9z"
          fill="#FFFFFF"
        />
        <path
          d="M283.74,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C283.23,65.42,283.46,65.38,283.74,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M285.84,65.44l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H285.84z M287.05,65.38c0.47,0,0.71,0.24,0.71,0.72
      v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C286.65,65.42,286.85,65.38,287.05,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M289.35,65.38c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S289.11,65.38,289.35,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M292.19,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C291.68,65.42,291.91,65.38,292.19,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M294.26,65.44l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H294.26z M295.55,65.38l-0.05,0.43h-0.15
      c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
      c0.2-0.09,0.39-0.13,0.57-0.13H295.55z"
          fill="#FFFFFF"
        />
        <path
          d="M296.24,65.7c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.13v0.29c0,0.06-0.01,0.11-0.04,0.13
      c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04s-0.04-0.07-0.04-0.13v-0.29c0-0.06,0.01-0.11,0.04-0.13
      s0.07-0.04,0.13-0.04H296.24z M296.24,67.44c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29
      c0,0.06-0.01,0.11-0.04,0.13c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13
      v-0.29c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H296.24z"
          fill="#FFFFFF"
        />
        <path
          d="M300.44,65.44l-0.96,2.52c-0.01,0.04-0.04,0.06-0.06,0.08c-0.03,0.02-0.06,0.03-0.1,0.03h-0.42
      c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.06-0.08l-0.96-2.52h0.49l0.65,1.84c0.03,0.08,0.06,0.15,0.08,0.23
      s0.05,0.15,0.08,0.23h0.07c0.03-0.08,0.06-0.15,0.08-0.23s0.05-0.15,0.08-0.23l0.65-1.84H300.44z"
          fill="#FFFFFF"
        />
        <path
          d="M302.76,66.45l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V67.1
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H302.76z M302.07,65.38c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C301.8,65.38,301.94,65.38,302.07,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M305.18,65.44v0.36h-1.77v-0.33l0.52-0.03H305.18z M304.35,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H304.35z"
          fill="#FFFFFF"
        />
        <path
          d="M307.13,65.44v0.36h-1.77v-0.33l0.52-0.03H307.13z M306.29,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H306.29z"
          fill="#FFFFFF"
        />
        <path
          d="M308.66,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C308.15,65.42,308.38,65.38,308.66,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M310.76,65.44l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H310.76z M311.97,65.38c0.47,0,0.71,0.24,0.71,0.72
      v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C311.58,65.42,311.78,65.38,311.97,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M313.67,67.44c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
      c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
      c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
      H313.67z"
          fill="#FFFFFF"
        />
        <path
          d="M316.33,64.29l0.01,1.56c0,0.13-0.01,0.25-0.02,0.37c-0.01,0.12-0.04,0.24-0.06,0.35
      c0.03,0.11,0.04,0.23,0.06,0.34s0.02,0.23,0.02,0.33v0.83h-0.8v-3.78H316.33z M316.7,66.35v0.49h-0.62v-0.49H316.7z M318.11,65.41
      l-0.7,0.93c-0.03,0.05-0.07,0.1-0.12,0.15s-0.09,0.08-0.12,0.1v0.02c0.03,0.02,0.07,0.06,0.12,0.11s0.09,0.12,0.12,0.17l0.79,1.18
      h-0.89l-0.91-1.5l0.82-1.17H318.11z"
          fill="#FFFFFF"
        />
        <path
          d="M319.6,65.34c0.33,0,0.6,0.05,0.8,0.14c0.2,0.09,0.35,0.24,0.43,0.45c0.09,0.21,0.13,0.48,0.13,0.83
      c0,0.34-0.04,0.61-0.13,0.82c-0.09,0.21-0.23,0.35-0.43,0.44c-0.2,0.09-0.47,0.13-0.8,0.13c-0.33,0-0.6-0.04-0.8-0.13
      s-0.35-0.24-0.44-0.44s-0.13-0.48-0.13-0.82c0-0.35,0.04-0.62,0.13-0.83s0.24-0.35,0.44-0.45S319.27,65.34,319.6,65.34z
       M319.6,65.92c-0.14,0-0.25,0.02-0.32,0.07s-0.13,0.13-0.17,0.25c-0.03,0.12-0.05,0.29-0.05,0.51c0,0.22,0.02,0.38,0.05,0.5
      s0.09,0.2,0.17,0.25s0.19,0.07,0.32,0.07s0.25-0.02,0.32-0.07s0.13-0.13,0.17-0.25s0.05-0.29,0.05-0.5c0-0.22-0.02-0.39-0.05-0.51
      c-0.03-0.12-0.09-0.2-0.17-0.25S319.74,65.92,319.6,65.92z"
          fill="#FFFFFF"
        />
        <path
          d="M322.04,65.41l0.1,0.53l0.08,0.07v2.07h-0.8v-2.67H322.04z M323.34,65.34l-0.08,0.74h-0.22
      c-0.13,0-0.26,0.02-0.39,0.05s-0.31,0.09-0.52,0.16l-0.05-0.45c0.19-0.17,0.38-0.29,0.57-0.38c0.19-0.09,0.38-0.13,0.56-0.13
      H323.34z"
          fill="#FFFFFF"
        />
        <path
          d="M324.25,65.41l0.09,0.53l0.06,0.07v2.07h-0.8v-2.67H324.25z M325.47,65.34c0.5,0,0.76,0.25,0.76,0.74v2
      h-0.8v-1.75c0-0.12-0.02-0.2-0.06-0.25s-0.12-0.07-0.22-0.07c-0.12,0-0.23,0.02-0.36,0.07c-0.12,0.04-0.27,0.11-0.45,0.21
      l-0.04-0.45c0.19-0.16,0.39-0.29,0.59-0.37C325.08,65.38,325.27,65.34,325.47,65.34z"
          fill="#FFFFFF"
        />
        <path
          d="M327.22,65.44l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H327.22z M328.32,65.38c0.22,0,0.39,0.06,0.51,0.18
      c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
      c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
      S328.14,65.38,328.32,65.38z M330.05,65.38c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
      c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
      c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C329.68,65.42,329.86,65.38,330.05,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M333.39,66.45l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V67.1
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H333.39z M332.71,65.38c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C332.44,65.38,332.58,65.38,332.71,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M334.79,64.29l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H334.79z"
          fill="#FFFFFF"
        />
        <path
          d="M337.27,65.44v0.36h-1.77v-0.33l0.52-0.03H337.27z M336.44,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H336.44z"
          fill="#FFFFFF"
        />
        <path
          d="M337.99,67.44c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
      c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
      c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
      H337.99z"
          fill="#FFFFFF"
        />
        <path
          d="M340.65,64.29l0.01,1.56c0,0.13-0.01,0.25-0.02,0.37c-0.01,0.12-0.04,0.24-0.06,0.35
      c0.03,0.11,0.04,0.23,0.06,0.34s0.02,0.23,0.02,0.33v0.83h-0.8v-3.78H340.65z M341.02,66.35v0.49h-0.62v-0.49H341.02z
       M342.43,65.41l-0.7,0.93c-0.03,0.05-0.07,0.1-0.12,0.15s-0.09,0.08-0.12,0.1v0.02c0.03,0.02,0.07,0.06,0.12,0.11
      s0.09,0.12,0.12,0.17l0.79,1.18h-0.89l-0.91-1.5l0.82-1.17H342.43z"
          fill="#FFFFFF"
        />
        <path
          d="M343.93,65.34c0.33,0,0.6,0.05,0.8,0.14c0.2,0.09,0.35,0.24,0.43,0.45c0.09,0.21,0.13,0.48,0.13,0.83
      c0,0.34-0.04,0.61-0.13,0.82c-0.09,0.21-0.23,0.35-0.43,0.44c-0.2,0.09-0.47,0.13-0.8,0.13c-0.33,0-0.6-0.04-0.8-0.13
      s-0.35-0.24-0.44-0.44s-0.13-0.48-0.13-0.82c0-0.35,0.04-0.62,0.13-0.83s0.24-0.35,0.44-0.45S343.6,65.34,343.93,65.34z
       M343.93,65.92c-0.14,0-0.25,0.02-0.32,0.07s-0.13,0.13-0.17,0.25c-0.03,0.12-0.05,0.29-0.05,0.51c0,0.22,0.02,0.38,0.05,0.5
      s0.09,0.2,0.17,0.25s0.19,0.07,0.32,0.07s0.25-0.02,0.32-0.07s0.13-0.13,0.17-0.25s0.05-0.29,0.05-0.5c0-0.22-0.02-0.39-0.05-0.51
      c-0.03-0.12-0.09-0.2-0.17-0.25S344.06,65.92,343.93,65.92z"
          fill="#FFFFFF"
        />
        <path
          d="M346.36,65.41l0.1,0.53l0.08,0.07v2.07h-0.8v-2.67H346.36z M347.66,65.34l-0.08,0.74h-0.22
      c-0.13,0-0.26,0.02-0.39,0.05s-0.31,0.09-0.52,0.16l-0.05-0.45c0.19-0.17,0.38-0.29,0.57-0.38c0.19-0.09,0.38-0.13,0.56-0.13
      H347.66z"
          fill="#FFFFFF"
        />
        <path
          d="M348.58,65.41l0.09,0.53l0.06,0.07v2.07h-0.8v-2.67H348.58z M349.79,65.34c0.5,0,0.76,0.25,0.76,0.74v2
      h-0.8v-1.75c0-0.12-0.02-0.2-0.06-0.25s-0.12-0.07-0.22-0.07c-0.12,0-0.23,0.02-0.36,0.07c-0.12,0.04-0.27,0.11-0.45,0.21
      l-0.04-0.45c0.19-0.16,0.39-0.29,0.59-0.37C349.4,65.38,349.6,65.34,349.79,65.34z"
          fill="#FFFFFF"
        />
        <path
          d="M352.04,65.38c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S351.81,65.38,352.04,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M355.3,65.44v0.36h-1.77v-0.33l0.52-0.03H355.3z M354.46,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H354.46z"
          fill="#FFFFFF"
        />
        <path
          d="M357.68,66.45l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V67.1
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H357.68z M356.99,65.38c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C356.72,65.38,356.86,65.38,356.99,65.38z M356.41,64.35
      c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13
      H356.41z M357.36,64.35c0.09,0,0.13,0.04,0.13,0.13v0.3c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3
      c0-0.09,0.04-0.13,0.13-0.13H357.36z"
          fill="#FFFFFF"
        />
        <path
          d="M358.96,65.44l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H358.96z M360.26,65.38l-0.05,0.43h-0.15
      c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
      c0.2-0.09,0.39-0.13,0.57-0.13H360.26z"
          fill="#FFFFFF"
        />
        <path
          d="M361.12,64.29l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
      s0.01,0.2,0.01,0.29v0.9h-0.46v-3.78H361.12z M361.57,66.42v0.33h-0.61v-0.33H361.57z M362.81,65.44l-0.83,0.97
      c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
      l-1.04-1.51l0.96-1.12H362.81z"
          fill="#FFFFFF"
        />
        <path
          d="M364.3,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C363.79,65.42,364.02,65.38,364.3,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M366.48,64.29l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H366.48z"
          fill="#FFFFFF"
        />
        <path
          d="M368.39,65.38c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S368.15,65.38,368.39,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M371.23,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C370.72,65.42,370.95,65.38,371.23,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M373.2,67.44c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
      c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
      c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
      H373.2z"
          fill="#FFFFFF"
        />
        <path
          d="M375.62,64.29l0.01,1.04c0,0.12,0,0.23-0.01,0.33s-0.02,0.2-0.04,0.28l0.05,0.08v2.05h-0.46v-3.78H375.62z
       M376.76,65.38c0.47,0,0.71,0.24,0.71,0.72v1.97H377v-1.84c0-0.18-0.03-0.3-0.08-0.37c-0.06-0.07-0.15-0.1-0.29-0.1
      c-0.16,0-0.32,0.04-0.49,0.11c-0.16,0.08-0.35,0.19-0.58,0.33l-0.02-0.33c0.21-0.15,0.41-0.28,0.61-0.37
      C376.36,65.42,376.56,65.38,376.76,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M378.63,65.44v1.85c0,0.17,0.03,0.28,0.09,0.35c0.06,0.07,0.17,0.1,0.32,0.1c0.15,0,0.29-0.04,0.44-0.11
      s0.32-0.17,0.53-0.3l0.06,0.32c-0.21,0.16-0.4,0.28-0.6,0.37c-0.19,0.08-0.39,0.12-0.59,0.12c-0.48,0-0.72-0.24-0.72-0.72v-1.98
      H378.63z M380.42,65.44v2.63h-0.37l-0.04-0.5l-0.05-0.08v-2.05H380.42z"
          fill="#FFFFFF"
        />
        <path
          d="M381.53,65.44l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H381.53z M382.63,65.38c0.22,0,0.39,0.06,0.51,0.18
      c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
      c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
      S382.45,65.38,382.63,65.38z M384.36,65.38c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
      c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
      c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C383.99,65.42,384.17,65.38,384.36,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M386.24,64.29l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H386.24z"
          fill="#FFFFFF"
        />
        <path
          d="M388.35,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C387.84,65.42,388.07,65.38,388.35,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M390.33,67.44c0.06,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29c0,0.06-0.01,0.11-0.04,0.13
      c-0.03,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.11-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13v-0.29
      c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H390.33z"
          fill="#FFFFFF"
        />
        <path
          d="M267.16,70.64c0.18,0,0.36,0,0.54,0s0.36,0,0.54,0.01c0.18,0,0.35,0.01,0.52,0.02l-0.02,0.37h-1.5
      c-0.11,0-0.19,0.03-0.25,0.08c-0.05,0.06-0.08,0.15-0.08,0.27v2.21c0,0.13,0.03,0.22,0.08,0.28c0.05,0.06,0.13,0.09,0.25,0.09h1.5
      l0.02,0.37c-0.17,0.01-0.34,0.02-0.52,0.02c-0.18,0-0.36,0.01-0.54,0.01s-0.36,0-0.54,0c-0.22,0-0.39-0.06-0.52-0.18
      c-0.13-0.12-0.19-0.27-0.2-0.47v-2.44c0-0.2,0.07-0.36,0.2-0.47C266.76,70.7,266.94,70.64,267.16,70.64z M266.53,72.21h1.99v0.38
      h-1.99V72.21z"
          fill="#FFFFFF"
        />
        <path
          d="M269.72,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H269.72z M270.93,71.68c0.47,0,0.71,0.24,0.71,0.72
      v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C270.54,71.72,270.74,71.68,270.93,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M273.45,71.68c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C272.94,71.72,273.17,71.68,273.45,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M275.51,71.74l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H275.51z M276.8,71.68l-0.05,0.43h-0.15
      c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
      c0.2-0.09,0.39-0.13,0.57-0.13H276.8z"
          fill="#FFFFFF"
        />
        <path
          d="M277.49,73.18l0.26,0.04c-0.06,0.05-0.1,0.12-0.12,0.2c-0.02,0.08-0.02,0.15,0.02,0.21
      s0.11,0.11,0.22,0.12l0.98,0.16c0.25,0.04,0.42,0.11,0.52,0.23c0.1,0.12,0.15,0.29,0.15,0.51c0,0.21-0.04,0.37-0.12,0.49
      c-0.08,0.12-0.21,0.2-0.39,0.25c-0.18,0.05-0.43,0.08-0.75,0.08c-0.24,0-0.44-0.01-0.6-0.04c-0.16-0.03-0.29-0.07-0.39-0.13
      c-0.1-0.06-0.17-0.14-0.21-0.25c-0.04-0.1-0.06-0.23-0.06-0.38c0-0.12,0.02-0.21,0.05-0.29s0.09-0.15,0.16-0.21
      s0.17-0.12,0.29-0.18l0.32-0.18l0.21,0.08l-0.26,0.2c-0.07,0.05-0.13,0.11-0.18,0.16c-0.05,0.05-0.08,0.11-0.11,0.17
      c-0.02,0.06-0.04,0.13-0.04,0.21c0,0.12,0.02,0.22,0.07,0.28c0.05,0.07,0.13,0.11,0.25,0.13c0.12,0.02,0.28,0.04,0.5,0.04
      c0.21,0,0.38-0.01,0.49-0.04s0.2-0.07,0.25-0.13c0.05-0.07,0.07-0.16,0.07-0.28c0-0.09-0.01-0.16-0.04-0.21s-0.07-0.08-0.13-0.11
      c-0.06-0.03-0.16-0.05-0.29-0.06l-0.94-0.13c-0.11-0.01-0.2-0.05-0.27-0.11s-0.11-0.13-0.14-0.21c-0.03-0.08-0.03-0.16-0.02-0.24
      c0.01-0.08,0.04-0.16,0.08-0.23S277.42,73.21,277.49,73.18z M278.23,71.68c0.28,0,0.5,0.03,0.67,0.09s0.28,0.15,0.35,0.27
      c0.07,0.12,0.11,0.28,0.11,0.48c0,0.19-0.04,0.35-0.11,0.47c-0.07,0.12-0.19,0.22-0.35,0.28c-0.17,0.06-0.39,0.09-0.67,0.09
      c-0.28,0-0.5-0.03-0.67-0.09s-0.28-0.15-0.35-0.27c-0.07-0.12-0.11-0.28-0.11-0.47c0-0.2,0.04-0.36,0.11-0.48
      c0.07-0.12,0.19-0.21,0.35-0.27C277.73,71.71,277.95,71.68,278.23,71.68z M278.23,72.03c-0.26,0-0.45,0.04-0.55,0.11
      c-0.1,0.07-0.15,0.2-0.15,0.38c0,0.17,0.05,0.3,0.15,0.37c0.1,0.08,0.29,0.11,0.55,0.11c0.27,0,0.45-0.04,0.55-0.11
      c0.1-0.08,0.15-0.2,0.15-0.37c0-0.18-0.05-0.3-0.15-0.38C278.68,72.07,278.5,72.03,278.23,72.03z M279.67,71.74L279.64,72
      l-0.67,0.05l-0.19-0.31H279.67z"
          fill="#FFFFFF"
        />
        <path
          d="M280.45,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H280.45z M280.57,71.74v2.63h-0.45v-2.63H280.57z"
          fill="#FFFFFF"
        />
        <path
          d="M283.26,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H283.26z M282.57,71.68c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C282.3,71.68,282.44,71.68,282.57,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M285.06,71.68c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S284.82,71.67,285.06,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M287.17,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H287.17z M287.29,71.74v2.63h-0.45v-2.63H287.29z"
          fill="#FFFFFF"
        />
        <path
          d="M288.92,71.68c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
      c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
      c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
      c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
      c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
      c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
      c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
      c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
      c0.07-0.1,0.18-0.17,0.33-0.21S288.69,71.67,288.92,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M292.61,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H292.61z M291.93,71.68c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C291.66,71.68,291.8,71.68,291.93,71.68z M291.34,70.65
      c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13
      H291.34z M292.29,70.65c0.09,0,0.13,0.04,0.13,0.13v0.3c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3
      c0-0.09,0.04-0.13,0.13-0.13H292.29z"
          fill="#FFFFFF"
        />
        <path
          d="M294.01,70.59L294,73.65c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H294.01z"
          fill="#FFFFFF"
        />
        <path
          d="M296.49,71.74v0.36h-1.77v-0.33l0.52-0.03H296.49z M295.66,70.96v2.68c0,0.13,0.03,0.22,0.08,0.27
      c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
      c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H295.66z"
          fill="#FFFFFF"
        />
        <path
          d="M298.06,71.68c0.3,0,0.54,0.04,0.73,0.13c0.18,0.09,0.31,0.24,0.39,0.44s0.12,0.47,0.12,0.81
      c0,0.33-0.04,0.6-0.12,0.81s-0.21,0.35-0.39,0.44s-0.42,0.13-0.73,0.13c-0.3,0-0.54-0.04-0.72-0.13s-0.31-0.24-0.39-0.44
      c-0.08-0.2-0.12-0.47-0.12-0.81c0-0.33,0.04-0.6,0.12-0.81c0.08-0.2,0.21-0.35,0.39-0.44C297.52,71.72,297.76,71.68,298.06,71.68z
       M298.06,72.07c-0.19,0-0.34,0.03-0.45,0.09c-0.11,0.06-0.19,0.16-0.24,0.31c-0.05,0.14-0.07,0.34-0.07,0.6
      c0,0.25,0.02,0.46,0.07,0.6c0.05,0.14,0.12,0.25,0.24,0.31c0.11,0.06,0.26,0.09,0.45,0.09c0.19,0,0.34-0.03,0.45-0.09
      c0.11-0.06,0.19-0.16,0.24-0.31c0.05-0.14,0.07-0.34,0.07-0.6c0-0.26-0.02-0.46-0.07-0.6c-0.05-0.14-0.13-0.25-0.24-0.31
      C298.4,72.1,298.25,72.07,298.06,72.07z M297.67,70.67c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16
      c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13H297.67z M298.62,70.67c0.09,0,0.13,0.04,0.13,0.13v0.3
      c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3c0-0.09,0.04-0.13,0.13-0.13H298.62z"
          fill="#FFFFFF"
        />
        <path d="M302.03,70.65l-2.12,4.08h-0.38l2.12-4.08H302.03z" fill="#FFFFFF" />
        <path
          d="M303.23,70.64c0.18,0,0.36,0,0.54,0s0.36,0,0.54,0.01c0.18,0,0.35,0.01,0.52,0.02l-0.02,0.37h-1.5
      c-0.11,0-0.19,0.03-0.25,0.08c-0.05,0.06-0.08,0.15-0.08,0.27v2.21c0,0.13,0.03,0.22,0.08,0.28c0.05,0.06,0.13,0.09,0.25,0.09h1.5
      l0.02,0.37c-0.17,0.01-0.34,0.02-0.52,0.02c-0.18,0-0.36,0.01-0.54,0.01s-0.36,0-0.54,0c-0.22,0-0.39-0.06-0.52-0.18
      c-0.13-0.12-0.19-0.27-0.2-0.47v-2.44c0-0.2,0.07-0.36,0.2-0.47C302.83,70.7,303.01,70.64,303.23,70.64z M302.6,72.21h1.99v0.38
      h-1.99V72.21z"
          fill="#FFFFFF"
        />
        <path
          d="M305.8,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H305.8z M307.01,71.68c0.47,0,0.71,0.24,0.71,0.72v1.97
      h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C306.61,71.72,306.81,71.68,307.01,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M309.52,71.68c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C309.01,71.72,309.24,71.68,309.52,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M311.58,71.74l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H311.58z M312.88,71.68l-0.05,0.43h-0.15
      c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
      c0.2-0.09,0.39-0.13,0.57-0.13H312.88z"
          fill="#FFFFFF"
        />
        <path
          d="M313.56,73.18l0.26,0.04c-0.06,0.05-0.1,0.12-0.12,0.2c-0.02,0.08-0.02,0.15,0.02,0.21
      s0.11,0.11,0.22,0.12l0.98,0.16c0.25,0.04,0.42,0.11,0.52,0.23c0.1,0.12,0.15,0.29,0.15,0.51c0,0.21-0.04,0.37-0.12,0.49
      c-0.08,0.12-0.21,0.2-0.39,0.25c-0.18,0.05-0.43,0.08-0.75,0.08c-0.24,0-0.44-0.01-0.6-0.04c-0.16-0.03-0.29-0.07-0.39-0.13
      c-0.1-0.06-0.17-0.14-0.21-0.25c-0.04-0.1-0.06-0.23-0.06-0.38c0-0.12,0.02-0.21,0.05-0.29s0.09-0.15,0.16-0.21
      s0.17-0.12,0.29-0.18l0.32-0.18l0.21,0.08l-0.26,0.2c-0.07,0.05-0.13,0.11-0.18,0.16c-0.05,0.05-0.08,0.11-0.11,0.17
      c-0.02,0.06-0.04,0.13-0.04,0.21c0,0.12,0.02,0.22,0.07,0.28c0.05,0.07,0.13,0.11,0.25,0.13c0.12,0.02,0.28,0.04,0.5,0.04
      c0.21,0,0.38-0.01,0.49-0.04s0.2-0.07,0.25-0.13c0.05-0.07,0.07-0.16,0.07-0.28c0-0.09-0.01-0.16-0.04-0.21s-0.07-0.08-0.13-0.11
      c-0.06-0.03-0.16-0.05-0.29-0.06l-0.94-0.13c-0.11-0.01-0.2-0.05-0.27-0.11s-0.11-0.13-0.14-0.21c-0.03-0.08-0.03-0.16-0.02-0.24
      c0.01-0.08,0.04-0.16,0.08-0.23S313.49,73.21,313.56,73.18z M314.3,71.68c0.28,0,0.5,0.03,0.67,0.09s0.28,0.15,0.35,0.27
      c0.07,0.12,0.11,0.28,0.11,0.48c0,0.19-0.04,0.35-0.11,0.47c-0.07,0.12-0.19,0.22-0.35,0.28c-0.17,0.06-0.39,0.09-0.67,0.09
      c-0.28,0-0.5-0.03-0.67-0.09s-0.28-0.15-0.35-0.27c-0.07-0.12-0.11-0.28-0.11-0.47c0-0.2,0.04-0.36,0.11-0.48
      c0.07-0.12,0.19-0.21,0.35-0.27C313.8,71.71,314.02,71.68,314.3,71.68z M314.3,72.03c-0.26,0-0.45,0.04-0.55,0.11
      c-0.1,0.07-0.15,0.2-0.15,0.38c0,0.17,0.05,0.3,0.15,0.37c0.1,0.08,0.29,0.11,0.55,0.11c0.27,0,0.45-0.04,0.55-0.11
      c0.1-0.08,0.15-0.2,0.15-0.37c0-0.18-0.05-0.3-0.15-0.38C314.75,72.07,314.57,72.03,314.3,72.03z M315.74,71.74L315.71,72
      l-0.67,0.05l-0.19-0.31H315.74z"
          fill="#FFFFFF"
        />
        <path
          d="M316.52,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H316.52z M316.64,71.74v2.63h-0.45v-2.63H316.64z"
          fill="#FFFFFF"
        />
        <path
          d="M317.76,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
      c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H317.76z M317.87,71.74v2.63h-0.45v-2.63H317.87z"
          fill="#FFFFFF"
        />
        <path
          d="M319.02,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H319.02z M320.23,71.68c0.47,0,0.71,0.24,0.71,0.72
      v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C319.83,71.72,320.03,71.68,320.23,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M322.05,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H322.05z M323.26,71.68c0.47,0,0.71,0.24,0.71,0.72
      v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
      l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C322.86,71.72,323.06,71.68,323.26,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M325.77,71.68c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
      c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
      c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
      c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
      c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
      c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
      c0.08-0.2,0.22-0.34,0.39-0.43C325.26,71.72,325.49,71.68,325.77,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M327.94,70.59l0.01,1.04c0,0.12,0,0.23-0.01,0.33s-0.02,0.2-0.04,0.28l0.05,0.08v2.05h-0.46v-3.78H327.94z
       M329.08,71.68c0.47,0,0.71,0.24,0.71,0.72v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.08-0.37c-0.06-0.07-0.15-0.1-0.29-0.1
      c-0.16,0-0.32,0.04-0.49,0.11c-0.16,0.08-0.35,0.19-0.58,0.33l-0.02-0.33c0.21-0.15,0.41-0.28,0.61-0.37
      C328.68,71.72,328.88,71.68,329.08,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M332.43,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H332.43z M331.75,71.68c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C331.48,71.68,331.62,71.68,331.75,71.68z M331.56,70.32
      c0.1,0,0.2,0.03,0.29,0.08c0.09,0.05,0.16,0.12,0.21,0.2c0.05,0.08,0.08,0.18,0.08,0.29c0,0.1-0.03,0.2-0.08,0.29
      c-0.05,0.09-0.12,0.16-0.21,0.21c-0.09,0.05-0.18,0.08-0.29,0.08c-0.1,0-0.2-0.03-0.29-0.08c-0.09-0.05-0.16-0.12-0.21-0.2
      c-0.05-0.08-0.08-0.18-0.08-0.29c0-0.11,0.03-0.2,0.08-0.29c0.05-0.08,0.12-0.15,0.21-0.2C331.36,70.34,331.46,70.32,331.56,70.32
      z M331.56,70.55c-0.09,0-0.17,0.03-0.23,0.09c-0.06,0.06-0.09,0.14-0.09,0.24c0,0.1,0.03,0.18,0.09,0.24
      c0.06,0.06,0.14,0.09,0.23,0.09c0.09,0,0.17-0.03,0.23-0.09c0.06-0.06,0.09-0.14,0.09-0.24c0-0.1-0.03-0.18-0.09-0.24
      C331.73,70.58,331.65,70.55,331.56,70.55z"
          fill="#FFFFFF"
        />
        <path
          d="M333.83,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H333.83z"
          fill="#FFFFFF"
        />
        <path
          d="M335.32,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H335.32z"
          fill="#FFFFFF"
        />
        <path
          d="M338.61,70.11l0.06,0.22c-0.36,0.18-0.62,0.46-0.79,0.85s-0.25,0.89-0.25,1.5c0,0.4,0.04,0.75,0.11,1.06
      s0.18,0.57,0.33,0.79s0.35,0.38,0.59,0.51l-0.06,0.22c-0.49-0.19-0.85-0.49-1.08-0.91c-0.23-0.42-0.34-0.97-0.34-1.67
      c0-0.7,0.11-1.26,0.34-1.67C337.76,70.6,338.12,70.29,338.61,70.11z"
          fill="#FFFFFF"
        />
        <path
          d="M340.27,70.65v3.73h-0.47v-2.95c0-0.05,0-0.11,0-0.16c0-0.05,0.01-0.11,0.02-0.16l-0.91,0.29l-0.06-0.35
      l0.95-0.38H340.27z"
          fill="#FFFFFF"
        />
        <path
          d="M342.39,70.58c0.32,0,0.57,0.07,0.76,0.2c0.19,0.13,0.32,0.34,0.4,0.63s0.12,0.65,0.12,1.11
      c0,0.45-0.04,0.82-0.12,1.11s-0.22,0.49-0.4,0.63c-0.19,0.13-0.44,0.2-0.76,0.2c-0.32,0-0.57-0.07-0.76-0.2
      c-0.19-0.13-0.32-0.34-0.4-0.63s-0.12-0.65-0.12-1.11c0-0.45,0.04-0.82,0.12-1.11s0.21-0.49,0.4-0.63
      C341.82,70.64,342.07,70.58,342.39,70.58z M342.39,70.99c-0.15,0-0.28,0.03-0.39,0.08s-0.19,0.14-0.25,0.26
      c-0.06,0.12-0.11,0.27-0.13,0.47c-0.03,0.19-0.04,0.43-0.04,0.71c0,0.27,0.01,0.51,0.04,0.7c0.03,0.19,0.07,0.35,0.13,0.47
      c0.06,0.12,0.15,0.21,0.25,0.26c0.1,0.06,0.23,0.08,0.39,0.08c0.16,0,0.29-0.03,0.39-0.08c0.11-0.06,0.19-0.14,0.25-0.26
      c0.06-0.12,0.11-0.28,0.13-0.47s0.04-0.43,0.04-0.7c0-0.28-0.01-0.51-0.04-0.71s-0.07-0.35-0.13-0.47
      c-0.06-0.12-0.15-0.21-0.25-0.26S342.55,70.99,342.39,70.99z"
          fill="#FFFFFF"
        />
        <path
          d="M345.54,70.58c0.32,0,0.57,0.07,0.76,0.2c0.19,0.13,0.32,0.34,0.4,0.63s0.12,0.65,0.12,1.11
      c0,0.45-0.04,0.82-0.12,1.11s-0.22,0.49-0.4,0.63c-0.19,0.13-0.44,0.2-0.76,0.2c-0.32,0-0.57-0.07-0.76-0.2
      c-0.19-0.13-0.32-0.34-0.4-0.63s-0.12-0.65-0.12-1.11c0-0.45,0.04-0.82,0.12-1.11s0.21-0.49,0.4-0.63
      C344.97,70.64,345.22,70.58,345.54,70.58z M345.54,70.99c-0.15,0-0.28,0.03-0.39,0.08s-0.19,0.14-0.25,0.26
      c-0.06,0.12-0.11,0.27-0.13,0.47c-0.03,0.19-0.04,0.43-0.04,0.71c0,0.27,0.01,0.51,0.04,0.7c0.03,0.19,0.07,0.35,0.13,0.47
      c0.06,0.12,0.15,0.21,0.25,0.26c0.1,0.06,0.23,0.08,0.39,0.08c0.16,0,0.29-0.03,0.39-0.08c0.11-0.06,0.19-0.14,0.25-0.26
      c0.06-0.12,0.11-0.28,0.13-0.47s0.04-0.43,0.04-0.7c0-0.28-0.01-0.51-0.04-0.71s-0.07-0.35-0.13-0.47
      c-0.06-0.12-0.15-0.21-0.25-0.26S345.69,70.99,345.54,70.99z"
          fill="#FFFFFF"
        />
        <path
          d="M348.96,71.74l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H348.96z M350.06,71.68c0.22,0,0.39,0.06,0.51,0.18
      c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
      c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
      S349.87,71.68,350.06,71.68z M351.78,71.68c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
      c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
      c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C351.42,71.72,351.6,71.68,351.78,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M353.66,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H353.66z"
          fill="#FFFFFF"
        />
        <path
          d="M354.37,70.11c0.49,0.19,0.85,0.49,1.08,0.91c0.23,0.42,0.34,0.98,0.34,1.67c0,0.69-0.11,1.25-0.34,1.67
      c-0.23,0.42-0.59,0.72-1.08,0.91l-0.05-0.22c0.24-0.13,0.44-0.3,0.59-0.51s0.26-0.48,0.33-0.79s0.11-0.66,0.11-1.06
      c0-0.61-0.08-1.11-0.24-1.5c-0.16-0.39-0.42-0.67-0.78-0.85L354.37,70.11z"
          fill="#FFFFFF"
        />
        <path
          d="M356.67,72c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.13v0.29c0,0.06-0.01,0.11-0.04,0.13
      c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04s-0.04-0.07-0.04-0.13v-0.29c0-0.06,0.01-0.11,0.04-0.13
      s0.07-0.04,0.13-0.04H356.67z M356.67,73.74c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29
      c0,0.06-0.01,0.11-0.04,0.13c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13
      v-0.29c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H356.67z"
          fill="#FFFFFF"
        />
        <path
          d="M359.78,70.65v3.73h-0.47v-2.95c0-0.05,0-0.11,0-0.16c0-0.05,0.01-0.11,0.02-0.16l-0.91,0.29l-0.06-0.35
      l0.95-0.38H359.78z"
          fill="#FFFFFF"
        />
        <path
          d="M362.08,70.58c0.08,0,0.18,0,0.32,0.01c0.14,0.01,0.28,0.03,0.43,0.07l-0.04,0.35
      c-0.08-0.01-0.15-0.01-0.22-0.02s-0.14-0.01-0.21-0.01c-0.08,0-0.17,0-0.28,0c-0.18,0-0.34,0.02-0.46,0.06
      c-0.13,0.04-0.23,0.12-0.3,0.23c-0.08,0.11-0.13,0.28-0.16,0.49c-0.03,0.22-0.05,0.5-0.05,0.85c0,0.27,0.01,0.5,0.02,0.68
      s0.05,0.33,0.1,0.43c0.05,0.11,0.13,0.18,0.24,0.23c0.11,0.05,0.25,0.07,0.43,0.07c0.21,0,0.37-0.03,0.47-0.1
      c0.1-0.07,0.17-0.16,0.2-0.29s0.05-0.29,0.05-0.47c0-0.21-0.02-0.37-0.06-0.49s-0.11-0.21-0.21-0.27s-0.24-0.08-0.43-0.08
      c-0.12,0-0.23,0.01-0.33,0.04c-0.1,0.03-0.2,0.08-0.31,0.15c-0.1,0.07-0.23,0.18-0.36,0.31l-0.02-0.24
      c0.12-0.17,0.23-0.3,0.34-0.4c0.11-0.1,0.23-0.16,0.35-0.2s0.28-0.06,0.45-0.06c0.26,0,0.47,0.04,0.62,0.12s0.25,0.21,0.31,0.39
      c0.06,0.18,0.09,0.42,0.09,0.72c0,0.19-0.01,0.36-0.04,0.52c-0.03,0.16-0.08,0.29-0.17,0.4c-0.08,0.11-0.2,0.2-0.36,0.26
      c-0.16,0.06-0.36,0.09-0.61,0.09c-0.26,0-0.48-0.03-0.64-0.09c-0.17-0.06-0.29-0.16-0.38-0.3c-0.09-0.14-0.15-0.33-0.18-0.56
      c-0.03-0.24-0.05-0.53-0.05-0.88c0-0.44,0.03-0.79,0.09-1.06s0.15-0.47,0.27-0.61c0.12-0.14,0.27-0.23,0.45-0.28
      C361.62,70.61,361.83,70.58,362.08,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M364.71,70.58c0.24,0,0.44,0.02,0.61,0.06c0.17,0.04,0.3,0.12,0.39,0.23s0.14,0.29,0.14,0.51
      c0,0.15-0.02,0.3-0.06,0.44c-0.04,0.14-0.11,0.28-0.21,0.42c-0.1,0.15-0.23,0.31-0.39,0.49l-1.07,1.19
      c0.09-0.01,0.19-0.01,0.3-0.02c0.11,0,0.21-0.01,0.3-0.01H366v0.46h-2.45v-0.3c0-0.04,0.01-0.08,0.02-0.11s0.03-0.07,0.05-0.09
      l1.08-1.22c0.24-0.25,0.41-0.48,0.52-0.68c0.11-0.2,0.16-0.39,0.16-0.56c0-0.12-0.03-0.21-0.09-0.27s-0.13-0.09-0.24-0.11
      c-0.1-0.02-0.23-0.03-0.37-0.03c-0.12,0-0.22,0-0.33,0.01c-0.1,0-0.21,0.01-0.33,0.02c-0.12,0.01-0.25,0.03-0.41,0.06l-0.03-0.37
      c0.17-0.04,0.32-0.07,0.45-0.09c0.13-0.02,0.25-0.03,0.36-0.04C364.49,70.58,364.6,70.58,364.71,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M368.15,70.59l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
      s0.01,0.2,0.01,0.29v0.9h-0.46v-3.78H368.15z M368.6,72.72v0.33h-0.61v-0.33H368.6z M369.84,71.74l-0.83,0.97
      c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
      l-1.04-1.51l0.96-1.12H369.84z"
          fill="#FFFFFF"
        />
        <path
          d="M371.3,70.65v2.94c0,0.27-0.06,0.48-0.19,0.62c-0.12,0.14-0.3,0.21-0.54,0.21c-0.05,0-0.1,0-0.17-0.01
      c-0.06,0-0.12-0.01-0.18-0.02s-0.11-0.02-0.15-0.04l0.05-0.33h0.27c0.28,0,0.42-0.14,0.42-0.41v-2.96H371.3z"
          fill="#FFFFFF"
        />
        <path d="M375.38,70.65l-2.12,4.08h-0.38l2.12-4.08H375.38z" fill="#FFFFFF" />
        <path
          d="M377.91,70.58c0.27,0,0.49,0.03,0.66,0.08c0.17,0.05,0.29,0.14,0.37,0.27s0.12,0.3,0.12,0.52
      c0,0.16-0.02,0.3-0.05,0.43s-0.09,0.24-0.16,0.33c-0.08,0.09-0.19,0.15-0.33,0.18v0.02c0.24,0.04,0.41,0.14,0.5,0.3
      c0.09,0.16,0.14,0.37,0.14,0.63c0,0.25-0.03,0.46-0.11,0.62c-0.08,0.16-0.2,0.29-0.37,0.36s-0.41,0.12-0.7,0.12
      c-0.2,0-0.4-0.01-0.6-0.03c-0.2-0.02-0.39-0.04-0.59-0.07l0.03-0.38c0.12,0.01,0.24,0.02,0.35,0.03s0.23,0.02,0.34,0.02
      c0.11,0,0.23,0,0.34,0c0.2,0,0.36-0.02,0.48-0.07c0.12-0.04,0.21-0.12,0.26-0.23c0.06-0.11,0.08-0.25,0.08-0.43
      c0-0.14-0.02-0.26-0.06-0.36s-0.11-0.18-0.22-0.24s-0.26-0.09-0.47-0.09h-0.69v-0.37h0.69c0.15,0,0.26-0.02,0.35-0.06
      c0.09-0.04,0.15-0.09,0.2-0.15c0.04-0.06,0.07-0.14,0.08-0.22c0.01-0.08,0.02-0.17,0.02-0.25c0-0.16-0.03-0.28-0.08-0.35
      c-0.05-0.08-0.12-0.13-0.23-0.16c-0.11-0.03-0.26-0.04-0.45-0.04c-0.18,0-0.35,0-0.51,0.01c-0.16,0.01-0.31,0.02-0.43,0.03
      l-0.04-0.37c0.18-0.03,0.36-0.06,0.53-0.07C377.57,70.58,377.74,70.58,377.91,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M380.9,70.58c0.27,0,0.49,0.03,0.65,0.09c0.16,0.06,0.29,0.17,0.37,0.31c0.08,0.14,0.14,0.34,0.17,0.58
      c0.03,0.24,0.04,0.54,0.04,0.9c0,0.42-0.03,0.76-0.08,1.02c-0.05,0.26-0.14,0.46-0.25,0.6s-0.26,0.23-0.45,0.28
      s-0.41,0.08-0.66,0.08c-0.08,0-0.2,0-0.34-0.01c-0.14-0.01-0.3-0.03-0.47-0.07l0.04-0.35c0.09,0.01,0.16,0.01,0.23,0.02
      c0.07,0,0.14,0,0.22,0s0.18,0,0.31,0c0.19,0,0.34-0.02,0.47-0.06s0.23-0.12,0.3-0.24c0.08-0.11,0.13-0.27,0.16-0.48
      s0.05-0.47,0.05-0.79c0-0.28-0.01-0.52-0.02-0.71c-0.01-0.19-0.04-0.34-0.09-0.45c-0.05-0.11-0.12-0.19-0.23-0.24
      s-0.25-0.07-0.44-0.07c-0.21,0-0.36,0.04-0.47,0.1c-0.11,0.07-0.18,0.17-0.21,0.3c-0.04,0.13-0.05,0.29-0.05,0.47
      c0,0.18,0.02,0.33,0.06,0.46c0.04,0.12,0.11,0.22,0.21,0.28c0.1,0.06,0.24,0.1,0.42,0.1c0.12,0,0.23-0.02,0.33-0.05
      c0.1-0.03,0.2-0.08,0.3-0.16c0.1-0.07,0.22-0.18,0.36-0.31l0.02,0.23c-0.12,0.17-0.23,0.3-0.34,0.4c-0.11,0.1-0.23,0.16-0.35,0.2
      s-0.28,0.06-0.45,0.06c-0.26,0-0.46-0.05-0.6-0.16s-0.25-0.25-0.31-0.43s-0.09-0.39-0.09-0.63c0-0.28,0.04-0.52,0.11-0.71
      c0.07-0.19,0.2-0.33,0.37-0.43C380.37,70.63,380.6,70.58,380.9,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M384.37,70.59l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
      s0.01,0.2,0.01,0.29v0.9h-0.46v-3.78H384.37z M384.82,72.72v0.33h-0.61v-0.33H384.82z M386.06,71.74l-0.83,0.97
      c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
      l-1.04-1.51l0.96-1.12H386.06z"
          fill="#FFFFFF"
        />
        <path
          d="M387.59,71.68c0.08,0,0.17,0,0.26,0.01c0.1,0.01,0.2,0.02,0.3,0.04c0.1,0.02,0.2,0.04,0.28,0.08l-0.06,0.3
      c-0.12-0.01-0.24-0.02-0.38-0.02c-0.13,0-0.24-0.01-0.34-0.01c-0.21,0-0.38,0.03-0.5,0.09s-0.21,0.16-0.26,0.3
      c-0.05,0.14-0.08,0.34-0.08,0.6s0.03,0.46,0.08,0.6c0.05,0.15,0.14,0.25,0.26,0.31s0.29,0.09,0.5,0.09c0.05,0,0.12,0,0.21,0
      c0.09,0,0.18-0.01,0.28-0.02c0.1-0.01,0.19-0.02,0.28-0.03l0.05,0.31c-0.13,0.05-0.28,0.08-0.43,0.1s-0.31,0.03-0.47,0.03
      c-0.3,0-0.55-0.04-0.73-0.13s-0.32-0.24-0.4-0.44c-0.08-0.2-0.12-0.47-0.12-0.81s0.04-0.61,0.12-0.81c0.08-0.2,0.22-0.35,0.4-0.43
      C387.05,71.72,387.29,71.68,387.59,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M390.93,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
      c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
      c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
      c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
      c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H390.93z M390.24,71.68c0.19,0,0.36,0.03,0.49,0.08
      c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
      c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
      c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C389.97,71.68,390.11,71.68,390.24,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M392.32,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
      c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
      c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H392.32z"
          fill="#FFFFFF"
        />
      </g>
      <g>
        <path
          d="M324.01,41.06c0.45,0,0.81,0.08,1.08,0.24c0.27,0.16,0.46,0.42,0.58,0.78c0.12,0.36,0.17,0.84,0.17,1.44
      c0,0.6-0.06,1.08-0.17,1.44c-0.12,0.36-0.31,0.62-0.58,0.78c-0.27,0.16-0.63,0.24-1.08,0.24s-0.81-0.08-1.08-0.24
      c-0.27-0.16-0.46-0.42-0.57-0.78s-0.17-0.84-0.17-1.44c0-0.6,0.06-1.08,0.17-1.44c0.11-0.36,0.31-0.62,0.57-0.78
      C323.19,41.14,323.55,41.06,324.01,41.06z M324,41.88c-0.15,0-0.28,0.03-0.38,0.08c-0.1,0.05-0.18,0.13-0.24,0.25
      s-0.1,0.28-0.13,0.5c-0.02,0.21-0.04,0.48-0.04,0.81c0,0.32,0.01,0.59,0.04,0.81c0.03,0.21,0.07,0.38,0.13,0.5
      c0.06,0.12,0.14,0.2,0.24,0.25c0.1,0.05,0.23,0.08,0.38,0.08c0.16,0,0.28-0.03,0.39-0.08c0.1-0.05,0.19-0.13,0.25-0.25
      c0.06-0.12,0.11-0.29,0.13-0.5c0.03-0.21,0.04-0.48,0.04-0.81c0-0.33-0.01-0.6-0.04-0.81c-0.02-0.21-0.07-0.38-0.13-0.5
      s-0.14-0.2-0.25-0.25C324.28,41.91,324.15,41.88,324,41.88z"
          fill="#FFFFFF"
        />
        <path
          d="M327.11,44.84c0.13,0,0.22,0.03,0.27,0.08c0.05,0.05,0.08,0.14,0.08,0.28v0.44c0,0.18-0.01,0.34-0.04,0.49
      c-0.03,0.15-0.07,0.28-0.12,0.4c-0.05,0.12-0.11,0.24-0.18,0.36l-0.41-0.12c0.04-0.15,0.06-0.3,0.08-0.43
      c0.01-0.14,0.02-0.29,0.03-0.45c-0.19,0-0.29-0.11-0.29-0.32l0.01-0.37c0-0.13,0.03-0.22,0.09-0.28c0.05-0.05,0.14-0.08,0.27-0.08
      H327.11z"
          fill="#FFFFFF"
        />
        <path
          d="M329.69,41.06c0.38,0,0.67,0.03,0.9,0.1s0.39,0.18,0.49,0.34c0.1,0.16,0.15,0.38,0.15,0.67
      c0,0.22-0.02,0.41-0.06,0.58s-0.11,0.3-0.22,0.41c-0.1,0.1-0.26,0.17-0.45,0.21v0.03c0.32,0.05,0.55,0.17,0.67,0.37
      s0.19,0.47,0.19,0.82c0,0.33-0.05,0.6-0.15,0.81s-0.28,0.36-0.52,0.46c-0.24,0.1-0.56,0.15-0.96,0.15c-0.27,0-0.55-0.01-0.83-0.04
      c-0.28-0.03-0.55-0.06-0.81-0.09l0.04-0.77c0.18,0.01,0.35,0.02,0.49,0.03c0.14,0.01,0.28,0.01,0.41,0.02
      c0.13,0,0.27,0.01,0.4,0.01c0.22,0,0.4-0.02,0.53-0.06c0.13-0.04,0.22-0.11,0.27-0.21c0.05-0.1,0.08-0.25,0.08-0.44
      c0-0.15-0.02-0.27-0.06-0.37c-0.04-0.1-0.11-0.17-0.22-0.23s-0.26-0.08-0.46-0.07l-0.9,0.01v-0.69h0.9c0.15,0,0.27-0.02,0.36-0.05
      s0.15-0.08,0.2-0.14c0.04-0.06,0.07-0.12,0.09-0.2c0.01-0.08,0.02-0.16,0.02-0.25c0-0.15-0.03-0.27-0.07-0.34
      c-0.04-0.08-0.12-0.13-0.23-0.16s-0.26-0.04-0.45-0.04c-0.24,0-0.47,0-0.69,0.01s-0.42,0.01-0.59,0.02l-0.05-0.73
      c0.26-0.04,0.51-0.08,0.76-0.1C329.2,41.07,329.45,41.06,329.69,41.06z"
          fill="#FFFFFF"
        />
        <path
          d="M333.58,41.06c0.38,0,0.67,0.03,0.9,0.1s0.39,0.18,0.49,0.34c0.1,0.16,0.15,0.38,0.15,0.67
      c0,0.22-0.02,0.41-0.06,0.58s-0.11,0.3-0.22,0.41c-0.1,0.1-0.26,0.17-0.45,0.21v0.03c0.32,0.05,0.55,0.17,0.67,0.37
      s0.19,0.47,0.19,0.82c0,0.33-0.05,0.6-0.15,0.81s-0.28,0.36-0.52,0.46c-0.24,0.1-0.56,0.15-0.96,0.15c-0.27,0-0.55-0.01-0.83-0.04
      c-0.28-0.03-0.55-0.06-0.81-0.09l0.04-0.77c0.18,0.01,0.35,0.02,0.49,0.03c0.14,0.01,0.28,0.01,0.41,0.02
      c0.13,0,0.27,0.01,0.4,0.01c0.22,0,0.4-0.02,0.53-0.06c0.13-0.04,0.22-0.11,0.27-0.21c0.05-0.1,0.08-0.25,0.08-0.44
      c0-0.15-0.02-0.27-0.06-0.37c-0.04-0.1-0.11-0.17-0.22-0.23s-0.26-0.08-0.46-0.07l-0.9,0.01v-0.69h0.9c0.15,0,0.27-0.02,0.36-0.05
      s0.15-0.08,0.2-0.14c0.04-0.06,0.07-0.12,0.09-0.2c0.01-0.08,0.02-0.16,0.02-0.25c0-0.15-0.03-0.27-0.07-0.34
      c-0.04-0.08-0.12-0.13-0.23-0.16s-0.26-0.04-0.45-0.04c-0.24,0-0.47,0-0.69,0.01s-0.42,0.01-0.59,0.02l-0.05-0.73
      c0.26-0.04,0.51-0.08,0.76-0.1C333.09,41.07,333.33,41.06,333.58,41.06z"
          fill="#FFFFFF"
        />
        <path
          d="M337.11,41.16v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
      c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
      v-3.76H337.11z"
          fill="#FFFFFF"
        />
        <path
          d="M267.8,49.29c0.38,0,0.69,0.04,0.95,0.12s0.47,0.21,0.63,0.4c0.16,0.18,0.27,0.44,0.35,0.75
      c0.07,0.32,0.11,0.71,0.11,1.19c0,0.47-0.04,0.87-0.11,1.19c-0.07,0.32-0.19,0.57-0.35,0.75c-0.16,0.19-0.37,0.32-0.63,0.4
      c-0.26,0.08-0.58,0.12-0.95,0.12c-0.37,0-0.69-0.04-0.95-0.12c-0.26-0.08-0.47-0.21-0.63-0.4c-0.16-0.18-0.28-0.44-0.35-0.75
      c-0.07-0.32-0.11-0.71-0.11-1.19c0-0.48,0.04-0.87,0.11-1.19s0.19-0.57,0.35-0.75c0.16-0.19,0.37-0.32,0.63-0.4
      C267.11,49.33,267.43,49.29,267.8,49.29z M267.8,50.12c-0.24,0-0.43,0.05-0.57,0.14c-0.13,0.1-0.23,0.26-0.29,0.5
      c-0.06,0.24-0.09,0.57-0.09,0.99c0,0.42,0.03,0.75,0.09,0.99c0.06,0.24,0.15,0.4,0.29,0.5c0.13,0.1,0.32,0.14,0.57,0.14
      c0.24,0,0.42-0.05,0.56-0.14c0.13-0.1,0.23-0.26,0.29-0.5c0.06-0.24,0.09-0.57,0.09-0.99c0-0.43-0.03-0.76-0.09-0.99
      c-0.06-0.23-0.15-0.4-0.29-0.5C268.23,50.16,268.04,50.12,267.8,50.12z"
          fill="#FFFFFF"
        />
        <path
          d="M271.67,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
      c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
      v-3.76H271.67z"
          fill="#FFFFFF"
        />
        <path
          d="M278,49.38v2.84c0,0.48-0.06,0.86-0.18,1.16c-0.12,0.29-0.31,0.5-0.59,0.63c-0.27,0.13-0.63,0.2-1.09,0.2
      c-0.48,0-0.86-0.06-1.15-0.2c-0.29-0.13-0.5-0.34-0.62-0.63c-0.13-0.29-0.19-0.68-0.19-1.16v-2.84h1.05v2.84
      c0,0.3,0.03,0.53,0.08,0.7c0.05,0.17,0.14,0.29,0.27,0.35s0.3,0.1,0.51,0.1s0.38-0.03,0.51-0.1s0.21-0.19,0.27-0.35
      c0.05-0.17,0.08-0.4,0.08-0.7v-2.84H278z"
          fill="#FFFFFF"
        />
        <path d="M282.46,49.38v0.82h-3.89v-0.82H282.46z M281.03,49.38v4.73h-1.05v-4.73H281.03z" fill="#FFFFFF" />
        <path
          d="M286.48,49.38c0.09,0,0.16,0.03,0.23,0.08c0.07,0.05,0.11,0.12,0.13,0.2l1.37,4.46h-1.07l-0.97-3.47
      c-0.02-0.08-0.04-0.16-0.06-0.24c-0.02-0.08-0.03-0.16-0.05-0.23h-0.18c-0.01,0.07-0.03,0.15-0.04,0.23
      c-0.02,0.08-0.04,0.16-0.06,0.24l-0.97,3.47h-1.08l1.38-4.46c0.02-0.08,0.07-0.15,0.13-0.2c0.07-0.05,0.14-0.08,0.23-0.08H286.48z
       M287.21,52.08v0.79h-2.45v-0.79H287.21z"
          fill="#FFFFFF"
        />
        <path
          d="M289.73,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
      c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
      v-3.76H289.73z"
          fill="#FFFFFF"
        />
        <path
          d="M293.42,49.38v1.34c0,0.16-0.01,0.31-0.03,0.46c-0.02,0.15-0.06,0.3-0.11,0.45
      c0.04,0.14,0.07,0.29,0.1,0.44c0.03,0.15,0.04,0.29,0.04,0.42v1.63h-1.04v-4.73H293.42z M293.95,51.29v0.73h-0.85v-0.73H293.95z
       M296.12,49.38l-1.23,1.91c-0.05,0.08-0.11,0.15-0.16,0.21c-0.06,0.06-0.12,0.11-0.18,0.15v0.02c0.06,0.04,0.13,0.09,0.21,0.17
      s0.13,0.15,0.18,0.23l1.28,2.04h-1.17l-1.46-2.47l1.42-2.25H296.12z"
          fill="#FFFFFF"
        />
        <path
          d="M297.21,53.07c0.13,0,0.22,0.03,0.28,0.08s0.08,0.14,0.08,0.28v0.33c0,0.13-0.03,0.23-0.08,0.28
      c-0.05,0.05-0.15,0.08-0.28,0.08h-0.26c-0.13,0-0.23-0.03-0.28-0.08c-0.05-0.05-0.08-0.14-0.08-0.28v-0.33
      c0-0.13,0.03-0.22,0.08-0.28c0.05-0.05,0.14-0.08,0.28-0.08H297.21z"
          fill="#FFFFFF"
        />
        <path
          d="M301.83,49.38l-1.14,2.72c-0.03,0.07-0.03,0.13,0,0.17c0.03,0.04,0.08,0.06,0.14,0.06h2.71v0.62l-0.58,0.1
      h-2.89c-0.13,0-0.24-0.04-0.33-0.11c-0.09-0.07-0.15-0.16-0.18-0.27c-0.03-0.11-0.02-0.23,0.03-0.34l1.24-2.95H301.83z
       M302.98,50.3v3.81h-0.97v-3.06l0.17-0.75H302.98z"
          fill="#FFFFFF"
        />
        <path
          d="M304.7,53.07c0.13,0,0.22,0.03,0.27,0.08c0.05,0.05,0.08,0.14,0.08,0.28v0.44c0,0.18-0.01,0.34-0.04,0.49
      c-0.03,0.15-0.07,0.28-0.12,0.4c-0.05,0.12-0.11,0.24-0.18,0.36L304.29,55c0.04-0.15,0.06-0.3,0.08-0.43
      c0.01-0.14,0.02-0.29,0.03-0.45c-0.19,0-0.29-0.11-0.29-0.32l0.01-0.37c0-0.13,0.03-0.22,0.09-0.28c0.05-0.05,0.14-0.08,0.27-0.08
      H304.7z"
          fill="#FFFFFF"
        />
        <path
          d="M308.24,49.38c0.11,0,0.21,0.03,0.28,0.08c0.08,0.05,0.13,0.12,0.15,0.2s0.02,0.18-0.01,0.28l-1.45,4.17
      h-1.03l1.21-3.43c0.03-0.08,0.06-0.15,0.09-0.23s0.07-0.15,0.12-0.22h-2.17l-0.05-0.85H308.24z"
          fill="#FFFFFF"
        />
        <path d="M314.16,49.38v0.82h-3.89v-0.82H314.16z M312.73,49.38v4.73h-1.05v-4.73H312.73z" fill="#FFFFFF" />
        <path d="M315.8,49.38v4.73h-1.04v-4.73H315.8z" fill="#FFFFFF" />
        <path
          d="M317.78,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
      c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
      v-3.76H317.78z"
          fill="#FFFFFF"
        />
        <path
          d="M320.92,53.07c0.13,0,0.22,0.03,0.28,0.08s0.08,0.14,0.08,0.28v0.33c0,0.13-0.03,0.23-0.08,0.28
      c-0.05,0.05-0.15,0.08-0.28,0.08h-0.26c-0.13,0-0.23-0.03-0.28-0.08c-0.05-0.05-0.08-0.14-0.08-0.28v-0.33
      c0-0.13,0.03-0.22,0.08-0.28c0.05-0.05,0.14-0.08,0.28-0.08H320.92z"
          fill="#FFFFFF"
        />
        <path d="M324.06,52.04v0.64h-2.04v-0.64H324.06z" fill="#FFFFFF" />
        <path
          d="M325.86,49.29c0.29,0,0.53,0.04,0.7,0.13c0.17,0.09,0.3,0.24,0.37,0.44c0.07,0.21,0.11,0.48,0.11,0.84
      c0,0.35-0.04,0.62-0.11,0.83c-0.07,0.21-0.2,0.35-0.37,0.45c-0.17,0.09-0.41,0.14-0.7,0.14c-0.29,0-0.52-0.05-0.69-0.14
      c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.48-0.11-0.83c0-0.35,0.04-0.63,0.11-0.84c0.08-0.21,0.2-0.35,0.37-0.44
      C325.35,49.33,325.58,49.29,325.86,49.29z M325.86,49.85c-0.11,0-0.19,0.02-0.25,0.07s-0.1,0.13-0.13,0.25s-0.04,0.3-0.04,0.53
      c0,0.23,0.01,0.41,0.04,0.53s0.07,0.2,0.13,0.25s0.14,0.07,0.25,0.07c0.11,0,0.2-0.02,0.26-0.07c0.06-0.05,0.1-0.13,0.13-0.25
      c0.03-0.12,0.04-0.3,0.04-0.53c0-0.23-0.01-0.41-0.04-0.53c-0.02-0.12-0.07-0.2-0.13-0.25C326.06,49.87,325.98,49.85,325.86,49.85
      z M329.3,49.38l-2.83,4.73h-0.64l2.83-4.73H329.3z M329.27,51.38c0.29,0,0.52,0.04,0.7,0.13c0.17,0.09,0.29,0.24,0.37,0.44
      s0.11,0.48,0.11,0.83c0,0.35-0.04,0.62-0.11,0.83s-0.2,0.36-0.37,0.45c-0.17,0.09-0.4,0.13-0.7,0.13c-0.29,0-0.52-0.04-0.69-0.13
      c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.49-0.11-0.83c0-0.35,0.04-0.63,0.11-0.83c0.08-0.2,0.2-0.35,0.37-0.44
      C328.75,51.42,328.98,51.38,329.27,51.38z M329.26,51.94c-0.11,0-0.2,0.02-0.26,0.07c-0.06,0.04-0.1,0.13-0.13,0.25
      c-0.02,0.12-0.03,0.3-0.03,0.53c0,0.23,0.01,0.41,0.03,0.54c0.02,0.12,0.07,0.21,0.13,0.25c0.06,0.04,0.15,0.07,0.26,0.07
      c0.11,0,0.2-0.02,0.26-0.07c0.06-0.04,0.1-0.13,0.13-0.25s0.04-0.3,0.04-0.54c0-0.23-0.01-0.41-0.04-0.53
      c-0.03-0.12-0.07-0.2-0.13-0.25C329.46,51.96,329.38,51.94,329.26,51.94z"
          fill="#FFFFFF"
        />
        <path d="M333.42,48.76v6.51h-0.72v-6.51H333.42z" fill="#FFFFFF" />
        <path
          d="M337.79,49.29c0.38,0,0.69,0.04,0.95,0.12s0.47,0.21,0.63,0.4c0.16,0.18,0.27,0.44,0.35,0.75
      c0.07,0.32,0.11,0.71,0.11,1.19c0,0.47-0.04,0.87-0.11,1.19c-0.07,0.32-0.19,0.57-0.35,0.75c-0.16,0.19-0.37,0.32-0.63,0.4
      c-0.26,0.08-0.58,0.12-0.95,0.12c-0.37,0-0.69-0.04-0.95-0.12c-0.26-0.08-0.47-0.21-0.63-0.4c-0.16-0.18-0.28-0.44-0.35-0.75
      c-0.07-0.32-0.11-0.71-0.11-1.19c0-0.48,0.04-0.87,0.11-1.19s0.19-0.57,0.35-0.75c0.16-0.19,0.37-0.32,0.63-0.4
      C337.1,49.33,337.42,49.29,337.79,49.29z M337.32,48.03c0.14,0,0.21,0.07,0.21,0.22v0.4c0,0.15-0.07,0.22-0.21,0.22h-0.34
      c-0.14,0-0.21-0.07-0.21-0.22v-0.4c0-0.15,0.07-0.22,0.21-0.22H337.32z M337.79,50.12c-0.24,0-0.43,0.05-0.57,0.14
      c-0.13,0.1-0.23,0.26-0.29,0.5c-0.06,0.24-0.09,0.57-0.09,0.99c0,0.42,0.03,0.75,0.09,0.99c0.06,0.24,0.15,0.4,0.29,0.5
      c0.13,0.1,0.32,0.14,0.57,0.14c0.24,0,0.42-0.05,0.56-0.14c0.13-0.1,0.23-0.26,0.29-0.5c0.06-0.24,0.09-0.57,0.09-0.99
      c0-0.43-0.03-0.76-0.09-0.99c-0.06-0.23-0.15-0.4-0.29-0.5C338.21,50.16,338.03,50.12,337.79,50.12z M338.58,48.03
      c0.15,0,0.22,0.07,0.22,0.22v0.4c0,0.15-0.07,0.22-0.22,0.22h-0.33c-0.14,0-0.21-0.07-0.21-0.22v-0.4c0-0.15,0.07-0.22,0.21-0.22
      H338.58z"
          fill="#FFFFFF"
        />
        <path
          d="M341.65,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
      c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
      v-3.76H341.65z"
          fill="#FFFFFF"
        />
        <path d="M347.47,49.38l-2.69,5.23h-0.81l2.69-5.23H347.47z" fill="#FFFFFF" />
        <path
          d="M349.88,49.33c0.41,0,0.74,0.04,0.98,0.11c0.25,0.07,0.42,0.2,0.53,0.37c0.11,0.18,0.17,0.43,0.17,0.75
      c0,0.34-0.07,0.6-0.2,0.77c-0.13,0.18-0.34,0.28-0.64,0.3v0.04c0.37,0.03,0.62,0.15,0.77,0.37c0.15,0.21,0.22,0.51,0.22,0.89
      c0,0.32-0.06,0.58-0.17,0.75c-0.11,0.18-0.29,0.3-0.52,0.37c-0.24,0.07-0.54,0.11-0.92,0.11c-0.31,0-0.57,0-0.8,0
      c-0.23,0-0.44-0.01-0.63-0.02c-0.19-0.01-0.39-0.02-0.59-0.03l0.1-0.73c0.11,0,0.32,0.01,0.63,0.01c0.31,0,0.68,0.01,1.09,0.01
      c0.19,0,0.34-0.02,0.45-0.05c0.11-0.04,0.19-0.1,0.23-0.2c0.04-0.1,0.06-0.24,0.06-0.42c0-0.2-0.02-0.35-0.07-0.45
      c-0.05-0.1-0.12-0.17-0.24-0.22c-0.11-0.04-0.27-0.06-0.46-0.07h-1.71v-0.67h1.7c0.17,0,0.3-0.02,0.4-0.05
      c0.1-0.03,0.17-0.09,0.21-0.18c0.04-0.09,0.06-0.22,0.06-0.38c0-0.17-0.02-0.3-0.07-0.39c-0.04-0.09-0.12-0.15-0.22-0.18
      c-0.11-0.03-0.25-0.05-0.44-0.05c-0.26,0-0.48,0-0.68,0s-0.37,0-0.53,0c-0.16,0-0.3,0.01-0.43,0.02l-0.1-0.73
      c0.18-0.02,0.36-0.03,0.53-0.04c0.17-0.01,0.36-0.01,0.56-0.01C349.37,49.33,349.61,49.33,349.88,49.33z M349.11,49.38v4.73h-1.03
      v-4.73H349.11z"
          fill="#FFFFFF"
        />
        <path
          d="M353.44,49.36c0.26,0,0.51,0,0.77,0c0.26,0,0.52,0.01,0.77,0.02c0.25,0.01,0.49,0.03,0.71,0.05l-0.05,0.76
      h-1.82c-0.11,0-0.2,0.03-0.26,0.09c-0.06,0.06-0.09,0.15-0.09,0.26v2.41c0,0.11,0.03,0.2,0.09,0.26c0.06,0.06,0.14,0.09,0.26,0.09
      h1.82l0.05,0.76c-0.22,0.02-0.46,0.03-0.71,0.04c-0.25,0.01-0.51,0.02-0.77,0.02c-0.26,0-0.52,0-0.77,0
      c-0.3,0-0.54-0.09-0.73-0.26c-0.18-0.18-0.28-0.41-0.28-0.69v-2.86c0-0.29,0.09-0.52,0.28-0.69
      C352.9,49.45,353.14,49.36,353.44,49.36z M352.56,51.28h2.83v0.74h-2.83V51.28z"
          fill="#FFFFFF"
        />
        <path
          d="M357.41,49.36c0.26,0,0.51,0,0.77,0c0.26,0,0.52,0.01,0.77,0.02c0.25,0.01,0.49,0.03,0.71,0.05l-0.05,0.76
      h-1.82c-0.11,0-0.2,0.03-0.26,0.09c-0.06,0.06-0.09,0.15-0.09,0.26v2.41c0,0.11,0.03,0.2,0.09,0.26c0.06,0.06,0.14,0.09,0.26,0.09
      h1.82l0.05,0.76c-0.22,0.02-0.46,0.03-0.71,0.04c-0.25,0.01-0.51,0.02-0.77,0.02c-0.26,0-0.52,0-0.77,0
      c-0.3,0-0.54-0.09-0.73-0.26c-0.18-0.18-0.28-0.41-0.28-0.69v-2.86c0-0.29,0.09-0.52,0.28-0.69
      C356.86,49.45,357.1,49.36,357.41,49.36z M356.52,51.28h2.83v0.74h-2.83V51.28z"
          fill="#FFFFFF"
        />
        <path
          d="M362.22,49.31c0.42,0,0.75,0.04,1.01,0.13c0.26,0.08,0.45,0.23,0.57,0.43s0.18,0.49,0.18,0.85
      c0,0.22-0.03,0.41-0.09,0.58c-0.06,0.17-0.15,0.3-0.28,0.4c-0.13,0.1-0.31,0.17-0.54,0.19v0.03c0.08,0.01,0.17,0.05,0.26,0.1
      c0.09,0.05,0.17,0.12,0.25,0.22c0.08,0.1,0.13,0.23,0.17,0.39l0.41,1.47h-1.1l-0.36-1.44c-0.04-0.16-0.1-0.26-0.18-0.32
      c-0.08-0.06-0.19-0.09-0.33-0.09c-0.31,0-0.57,0-0.79,0c-0.21,0-0.39,0-0.53-0.01s-0.27-0.01-0.39-0.01l0.02-0.76h1.71
      c0.18,0,0.33-0.02,0.44-0.06c0.11-0.04,0.18-0.11,0.23-0.21c0.04-0.1,0.07-0.24,0.07-0.42c0-0.18-0.02-0.32-0.07-0.42
      c-0.04-0.1-0.12-0.17-0.23-0.21s-0.25-0.06-0.44-0.06c-0.43,0-0.79,0-1.09,0.01c-0.3,0-0.5,0.01-0.61,0.02l-0.1-0.75
      c0.19-0.02,0.37-0.04,0.54-0.05c0.17-0.01,0.35-0.01,0.55-0.02C361.69,49.31,361.93,49.31,362.22,49.31z M361.43,49.38v4.73h-1.04
      v-4.73H361.43z"
          fill="#FFFFFF"
        />
        <path
          d="M368.28,49.38l-1.14,2.72c-0.03,0.07-0.03,0.13,0,0.17c0.03,0.04,0.08,0.06,0.14,0.06H370v0.62l-0.58,0.1
      h-2.89c-0.13,0-0.24-0.04-0.33-0.11c-0.09-0.07-0.15-0.16-0.18-0.27c-0.03-0.11-0.02-0.23,0.03-0.34l1.24-2.95H368.28z
       M369.42,50.3v3.81h-0.97v-3.06l0.17-0.75H369.42z"
          fill="#FFFFFF"
        />
        <path
          d="M371.14,53.07c0.13,0,0.22,0.03,0.27,0.08c0.05,0.05,0.08,0.14,0.08,0.28v0.44c0,0.18-0.01,0.34-0.04,0.49
      c-0.03,0.15-0.07,0.28-0.12,0.4c-0.05,0.12-0.11,0.24-0.18,0.36L370.74,55c0.04-0.15,0.06-0.3,0.08-0.43
      c0.01-0.14,0.02-0.29,0.03-0.45c-0.19,0-0.29-0.11-0.29-0.32l0.01-0.37c0-0.13,0.03-0.22,0.09-0.28c0.05-0.05,0.14-0.08,0.27-0.08
      H371.14z"
          fill="#FFFFFF"
        />
        <path
          d="M374.68,49.38c0.11,0,0.21,0.03,0.28,0.08c0.08,0.05,0.13,0.12,0.15,0.2s0.02,0.18-0.01,0.28l-1.45,4.17
      h-1.03l1.21-3.43c0.03-0.08,0.06-0.15,0.09-0.23s0.07-0.15,0.12-0.22h-2.17l-0.05-0.85H374.68z"
          fill="#FFFFFF"
        />
        <path
          d="M380.91,49.38l-1.29,4.44c-0.02,0.08-0.07,0.15-0.13,0.21s-0.14,0.08-0.24,0.08h-0.96
      c-0.1,0-0.18-0.03-0.24-0.08c-0.07-0.05-0.11-0.12-0.13-0.21l-1.3-4.44h1.08l0.86,3.41c0.02,0.09,0.04,0.17,0.06,0.26
      c0.02,0.09,0.04,0.18,0.05,0.26h0.18c0.01-0.09,0.03-0.18,0.05-0.27c0.02-0.09,0.04-0.18,0.06-0.27l0.86-3.41H380.91z"
          fill="#FFFFFF"
        />
        <path
          d="M383.27,49.29c0.38,0,0.69,0.04,0.95,0.12s0.47,0.21,0.63,0.4c0.16,0.18,0.27,0.44,0.35,0.75
      c0.07,0.32,0.11,0.71,0.11,1.19c0,0.47-0.04,0.87-0.11,1.19c-0.07,0.32-0.19,0.57-0.35,0.75c-0.16,0.19-0.37,0.32-0.63,0.4
      c-0.26,0.08-0.58,0.12-0.95,0.12c-0.37,0-0.69-0.04-0.95-0.12c-0.26-0.08-0.47-0.21-0.63-0.4c-0.16-0.18-0.28-0.44-0.35-0.75
      c-0.07-0.32-0.11-0.71-0.11-1.19c0-0.48,0.04-0.87,0.11-1.19s0.19-0.57,0.35-0.75c0.16-0.19,0.37-0.32,0.63-0.4
      C382.58,49.33,382.9,49.29,383.27,49.29z M383.27,50.12c-0.24,0-0.43,0.05-0.57,0.14c-0.13,0.1-0.23,0.26-0.29,0.5
      c-0.06,0.24-0.09,0.57-0.09,0.99c0,0.42,0.03,0.75,0.09,0.99c0.06,0.24,0.15,0.4,0.29,0.5c0.13,0.1,0.32,0.14,0.57,0.14
      c0.24,0,0.42-0.05,0.56-0.14c0.13-0.1,0.23-0.26,0.29-0.5c0.06-0.24,0.09-0.57,0.09-0.99c0-0.43-0.03-0.76-0.09-0.99
      c-0.06-0.23-0.15-0.4-0.29-0.5C383.7,50.16,383.51,50.12,383.27,50.12z"
          fill="#FFFFFF"
        />
        <path
          d="M387.14,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
      c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
      v-3.76H387.14z"
          fill="#FFFFFF"
        />
        <path
          d="M390.71,49.29c0.29,0,0.53,0.04,0.7,0.13c0.17,0.09,0.3,0.24,0.37,0.44c0.07,0.21,0.11,0.48,0.11,0.84
      c0,0.35-0.04,0.62-0.11,0.83c-0.07,0.21-0.2,0.35-0.37,0.45c-0.17,0.09-0.41,0.14-0.7,0.14c-0.29,0-0.52-0.05-0.69-0.14
      c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.48-0.11-0.83c0-0.35,0.04-0.63,0.11-0.84c0.08-0.21,0.2-0.35,0.37-0.44
      C390.19,49.33,390.42,49.29,390.71,49.29z M390.71,49.85c-0.11,0-0.19,0.02-0.25,0.07s-0.1,0.13-0.13,0.25s-0.04,0.3-0.04,0.53
      c0,0.23,0.01,0.41,0.04,0.53s0.07,0.2,0.13,0.25s0.14,0.07,0.25,0.07c0.11,0,0.2-0.02,0.26-0.07c0.06-0.05,0.1-0.13,0.13-0.25
      c0.03-0.12,0.04-0.3,0.04-0.53c0-0.23-0.01-0.41-0.04-0.53c-0.02-0.12-0.07-0.2-0.13-0.25C390.91,49.87,390.82,49.85,390.71,49.85
      z M394.14,49.38l-2.83,4.73h-0.64l2.83-4.73H394.14z M394.11,51.38c0.29,0,0.52,0.04,0.7,0.13c0.17,0.09,0.29,0.24,0.37,0.44
      s0.11,0.48,0.11,0.83c0,0.35-0.04,0.62-0.11,0.83s-0.2,0.36-0.37,0.45c-0.17,0.09-0.4,0.13-0.7,0.13c-0.29,0-0.52-0.04-0.69-0.13
      c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.49-0.11-0.83c0-0.35,0.04-0.63,0.11-0.83c0.08-0.2,0.2-0.35,0.37-0.44
      C393.6,51.42,393.83,51.38,394.11,51.38z M394.11,51.94c-0.11,0-0.2,0.02-0.26,0.07c-0.06,0.04-0.1,0.13-0.13,0.25
      c-0.02,0.12-0.03,0.3-0.03,0.53c0,0.23,0.01,0.41,0.03,0.54c0.02,0.12,0.07,0.21,0.13,0.25c0.06,0.04,0.15,0.07,0.26,0.07
      c0.11,0,0.2-0.02,0.26-0.07c0.06-0.04,0.1-0.13,0.13-0.25s0.04-0.3,0.04-0.54c0-0.23-0.01-0.41-0.04-0.53
      c-0.03-0.12-0.07-0.2-0.13-0.25C394.31,51.96,394.22,51.94,394.11,51.94z"
          fill="#FFFFFF"
        />
      </g>
      <g>
        <path
          d="M268.91,119.84l-0.94,3.24c-0.02,0.06-0.05,0.11-0.1,0.15c-0.05,0.04-0.11,0.06-0.17,0.06H267
      c-0.07,0-0.13-0.02-0.18-0.06c-0.05-0.04-0.08-0.09-0.09-0.15l-0.95-3.24h0.79l0.63,2.49c0.01,0.06,0.03,0.13,0.04,0.19
      c0.02,0.07,0.03,0.13,0.04,0.19h0.13c0.01-0.06,0.02-0.13,0.04-0.19c0.02-0.07,0.03-0.13,0.05-0.2l0.62-2.49H268.91z"
          fill="#FFFFFF"
        />
        <path
          d="M270.97,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
      c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
      c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
      c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
      s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H270.97z M270.31,120.75
      c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
      v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
      l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C270.05,120.76,270.19,120.75,270.31,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M272.5,119.79l0,2.71c0,0.08,0.03,0.15,0.08,0.19s0.12,0.06,0.22,0.06h0.2l0.08,0.5
      c-0.04,0.02-0.1,0.04-0.17,0.05c-0.07,0.01-0.15,0.03-0.23,0.04c-0.08,0.01-0.14,0.01-0.19,0.01c-0.22,0-0.4-0.06-0.53-0.17
      c-0.13-0.12-0.2-0.28-0.2-0.49v-2.89H272.5z"
          fill="#FFFFFF"
        />
        <path
          d="M273.89,120.82l0.08,0.49l0.08,0.07v1.92h-0.74v-2.47H273.89z M274.89,120.75c0.22,0,0.39,0.06,0.5,0.17
      s0.17,0.29,0.17,0.51v1.85h-0.71v-1.62c0-0.11-0.02-0.18-0.07-0.22c-0.04-0.04-0.11-0.07-0.21-0.07c-0.06,0-0.11,0.01-0.17,0.02
      c-0.05,0.02-0.12,0.04-0.19,0.08c-0.07,0.04-0.16,0.09-0.26,0.15l-0.04-0.41c0.16-0.16,0.32-0.27,0.47-0.35
      C274.54,120.79,274.71,120.75,274.89,120.75z M276.43,120.75c0.22,0,0.39,0.06,0.51,0.17c0.12,0.12,0.17,0.29,0.17,0.51v1.85
      h-0.74v-1.62c0-0.1-0.02-0.18-0.06-0.22s-0.11-0.07-0.21-0.07c-0.06,0-0.12,0.01-0.17,0.03c-0.05,0.02-0.12,0.04-0.19,0.08
      c-0.07,0.04-0.16,0.09-0.25,0.15l-0.04-0.41c0.16-0.16,0.32-0.27,0.47-0.35C276.08,120.79,276.25,120.75,276.43,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M278.15,119.73c0.15,0,0.22,0.07,0.22,0.22v0.26c0,0.15-0.07,0.23-0.22,0.23h-0.32
      c-0.15,0-0.22-0.08-0.22-0.23v-0.26c0-0.15,0.07-0.22,0.22-0.22H278.15z M278.36,120.82v2.47h-0.73v-2.47H278.36z"
          fill="#FFFFFF"
        />
        <path
          d="M279.82,120.75c0.12,0,0.25,0,0.39,0.01s0.28,0.02,0.41,0.03c0.13,0.01,0.25,0.03,0.35,0.05l-0.05,0.46
      c-0.16,0-0.32,0-0.48-0.01c-0.16,0-0.32,0-0.47,0c-0.13,0-0.23,0-0.3,0c-0.07,0.01-0.12,0.02-0.15,0.05s-0.04,0.07-0.04,0.13
      c0,0.07,0.02,0.12,0.07,0.15c0.05,0.02,0.13,0.05,0.25,0.08l0.65,0.16c0.21,0.05,0.36,0.13,0.46,0.24
      c0.1,0.11,0.15,0.26,0.15,0.47c0,0.21-0.04,0.37-0.12,0.47c-0.08,0.11-0.2,0.19-0.37,0.23c-0.17,0.04-0.38,0.06-0.64,0.06
      c-0.1,0-0.26,0-0.46-0.01c-0.2-0.01-0.43-0.04-0.67-0.08l0.05-0.46c0.06,0,0.14,0.01,0.23,0.01c0.09,0,0.2,0,0.3,0
      c0.11,0,0.22,0,0.33,0c0.17,0,0.3,0,0.39-0.01c0.09-0.01,0.15-0.03,0.18-0.06c0.03-0.03,0.05-0.08,0.05-0.14
      c0-0.07-0.03-0.12-0.08-0.15s-0.15-0.05-0.27-0.08l-0.62-0.16c-0.16-0.04-0.28-0.1-0.37-0.17s-0.15-0.15-0.18-0.25
      s-0.05-0.21-0.05-0.34c0-0.17,0.03-0.31,0.1-0.41s0.18-0.18,0.33-0.22C279.36,120.78,279.56,120.75,279.82,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M283.09,120.82v0.48h-1.87v-0.46l0.48-0.02H283.09z M282.4,120.13v2.37c0,0.09,0.02,0.15,0.06,0.19
      c0.04,0.04,0.11,0.06,0.21,0.06h0.32l0.08,0.5c-0.06,0.02-0.13,0.04-0.22,0.05c-0.09,0.01-0.17,0.03-0.26,0.04
      c-0.09,0.01-0.16,0.01-0.22,0.01c-0.22,0-0.4-0.06-0.52-0.18s-0.19-0.29-0.19-0.5l-0.01-2.53H282.4z"
          fill="#FFFFFF"
        />
        <path
          d="M285.35,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
      c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
      c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
      c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
      s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H285.35z M284.69,120.75
      c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
      v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
      l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C284.43,120.76,284.57,120.75,284.69,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M286.9,120.82v2.35c0,0.15-0.03,0.3-0.08,0.44s-0.12,0.27-0.22,0.38s-0.21,0.21-0.34,0.28l-0.41-0.32
      c0.11-0.13,0.19-0.25,0.24-0.37s0.07-0.25,0.07-0.41v-2.35H286.9z M286.69,119.7c0.14,0,0.22,0.07,0.22,0.22v0.27
      c0,0.15-0.07,0.22-0.22,0.22h-0.32c-0.14,0-0.22-0.08-0.22-0.22v-0.27c0-0.15,0.07-0.22,0.22-0.22H286.69z"
          fill="#FFFFFF"
        />
        <path
          d="M289.39,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
      c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
      c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
      c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
      s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H289.39z M288.73,120.75
      c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
      v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
      l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C288.46,120.76,288.6,120.75,288.73,120.75z"
          fill="#FFFFFF"
        />
        <path d="M292.53,119.84l-1.96,3.82h-0.59l1.96-3.82H292.53z" fill="#FFFFFF" />
        <path d="M295.46,119.84v0.6h-2.84v-0.6H295.46z M294.42,119.84v3.45h-0.76v-3.45H294.42z" fill="#FFFFFF" />
        <path
          d="M296.3,119.73c0.15,0,0.22,0.07,0.22,0.22v0.26c0,0.15-0.07,0.23-0.22,0.23h-0.32
      c-0.15,0-0.22-0.08-0.22-0.23v-0.26c0-0.15,0.07-0.22,0.22-0.22H296.3z M296.5,120.82v2.47h-0.73v-2.47H296.5z"
          fill="#FFFFFF"
        />
        <path
          d="M297.77,119.79l0,2.71c0,0.08,0.03,0.15,0.08,0.19s0.12,0.06,0.22,0.06h0.2l0.08,0.5
      c-0.04,0.02-0.1,0.04-0.17,0.05c-0.07,0.01-0.15,0.03-0.23,0.04c-0.08,0.01-0.14,0.01-0.19,0.01c-0.22,0-0.4-0.06-0.53-0.17
      c-0.13-0.12-0.2-0.28-0.2-0.49v-2.89H297.77z"
          fill="#FFFFFF"
        />
        <path
          d="M299.31,119.79l0,2.71c0,0.08,0.03,0.15,0.08,0.19s0.12,0.06,0.22,0.06h0.2l0.08,0.5
      c-0.04,0.02-0.1,0.04-0.17,0.05c-0.07,0.01-0.15,0.03-0.23,0.04c-0.08,0.01-0.14,0.01-0.19,0.01c-0.22,0-0.4-0.06-0.53-0.17
      c-0.13-0.12-0.2-0.28-0.2-0.49v-2.89H299.31z"
          fill="#FFFFFF"
        />
        <path
          d="M302.47,120.82l-0.82,2.29c-0.02,0.05-0.05,0.1-0.09,0.13c-0.04,0.03-0.09,0.05-0.15,0.05h-0.65
      c-0.06,0-0.11-0.02-0.15-0.05c-0.04-0.03-0.07-0.07-0.09-0.13l-0.81-2.29h0.79l0.46,1.54c0.02,0.07,0.04,0.15,0.05,0.22
      c0.02,0.08,0.03,0.15,0.05,0.22h0.07c0.02-0.07,0.04-0.15,0.06-0.22c0.02-0.08,0.04-0.15,0.06-0.22l0.45-1.54H302.47z"
          fill="#FFFFFF"
        />
        <path
          d="M303.84,120.75c0.42,0,0.72,0.07,0.9,0.21s0.27,0.35,0.27,0.63c0,0.23-0.05,0.4-0.17,0.51
      c-0.12,0.11-0.32,0.17-0.6,0.17h-1.38v-0.42h1.17c0.11,0,0.19-0.02,0.22-0.07c0.03-0.05,0.05-0.12,0.05-0.2
      c0-0.12-0.04-0.2-0.1-0.24c-0.06-0.04-0.17-0.07-0.33-0.07c-0.14,0-0.24,0.02-0.32,0.05c-0.08,0.04-0.13,0.11-0.16,0.21
      c-0.03,0.1-0.04,0.25-0.04,0.45c0,0.21,0.02,0.37,0.06,0.48c0.04,0.11,0.1,0.19,0.19,0.23c0.09,0.04,0.21,0.06,0.37,0.06
      c0.11,0,0.25,0,0.42-0.01c0.17-0.01,0.34-0.02,0.51-0.04l0.07,0.46c-0.1,0.05-0.21,0.08-0.33,0.11c-0.12,0.03-0.25,0.05-0.38,0.06
      c-0.13,0.01-0.25,0.02-0.38,0.02c-0.31,0-0.57-0.05-0.76-0.14c-0.19-0.09-0.33-0.23-0.42-0.42s-0.13-0.43-0.13-0.73
      c0-0.32,0.04-0.58,0.13-0.77s0.22-0.33,0.41-0.41C303.29,120.79,303.53,120.75,303.84,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M305.96,120.82l0.09,0.49l0.07,0.07v1.92h-0.74v-2.47H305.96z M307.17,120.75l-0.07,0.69h-0.21
      c-0.12,0-0.24,0.02-0.36,0.05c-0.13,0.03-0.29,0.08-0.48,0.15l-0.05-0.42c0.17-0.15,0.35-0.27,0.53-0.35
      c0.18-0.08,0.35-0.12,0.51-0.12H307.17z"
          fill="#FFFFFF"
        />
        <path
          d="M308.16,119.79l0,1.45c0,0.12-0.01,0.23-0.02,0.34c-0.01,0.11-0.03,0.22-0.06,0.33
      c0.02,0.1,0.04,0.21,0.05,0.32c0.01,0.11,0.02,0.21,0.02,0.3v0.77h-0.74v-3.5H308.16z M308.51,121.69v0.45h-0.58v-0.45H308.51z
       M309.81,120.82l-0.65,0.86c-0.03,0.05-0.07,0.09-0.12,0.14c-0.05,0.04-0.08,0.07-0.11,0.09v0.02c0.03,0.02,0.07,0.05,0.11,0.11
      s0.08,0.11,0.12,0.16l0.73,1.09h-0.83l-0.84-1.39l0.76-1.08H309.81z"
          fill="#FFFFFF"
        />
        <path
          d="M312.08,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
      c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
      c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
      c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
      s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H312.08z M311.42,120.75
      c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
      v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
      l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C311.16,120.76,311.3,120.75,311.42,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M313.45,120.82l0.09,0.49l0.07,0.07v1.92h-0.74v-2.47H313.45z M314.65,120.75l-0.07,0.69h-0.21
      c-0.12,0-0.24,0.02-0.36,0.05c-0.13,0.03-0.29,0.08-0.48,0.15l-0.05-0.42c0.17-0.15,0.35-0.27,0.53-0.35
      c0.18-0.08,0.35-0.12,0.51-0.12H314.65z"
          fill="#FFFFFF"
        />
        <path
          d="M316.03,120.75c0.42,0,0.72,0.07,0.9,0.21s0.27,0.35,0.27,0.63c0,0.23-0.05,0.4-0.17,0.51
      c-0.12,0.11-0.32,0.17-0.6,0.17h-1.38v-0.42h1.17c0.11,0,0.19-0.02,0.22-0.07c0.03-0.05,0.05-0.12,0.05-0.2
      c0-0.12-0.04-0.2-0.1-0.24c-0.06-0.04-0.17-0.07-0.33-0.07c-0.14,0-0.24,0.02-0.32,0.05c-0.08,0.04-0.13,0.11-0.16,0.21
      c-0.03,0.1-0.04,0.25-0.04,0.45c0,0.21,0.02,0.37,0.06,0.48c0.04,0.11,0.1,0.19,0.19,0.23c0.09,0.04,0.21,0.06,0.37,0.06
      c0.11,0,0.25,0,0.42-0.01c0.17-0.01,0.34-0.02,0.51-0.04l0.07,0.46c-0.1,0.05-0.21,0.08-0.33,0.11c-0.12,0.03-0.25,0.05-0.38,0.06
      c-0.13,0.01-0.25,0.02-0.38,0.02c-0.31,0-0.57-0.05-0.76-0.14c-0.19-0.09-0.33-0.23-0.42-0.42s-0.13-0.43-0.13-0.73
      c0-0.32,0.04-0.58,0.13-0.77s0.22-0.33,0.41-0.41C315.48,120.79,315.73,120.75,316.03,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M317.99,121.03c0.1,0,0.16,0.02,0.2,0.06c0.04,0.04,0.06,0.11,0.06,0.2v0.24c0,0.1-0.02,0.16-0.06,0.2
      c-0.04,0.04-0.11,0.06-0.2,0.06h-0.19c-0.1,0-0.16-0.02-0.2-0.06c-0.04-0.04-0.06-0.11-0.06-0.2v-0.24c0-0.1,0.02-0.16,0.06-0.2
      s0.11-0.06,0.2-0.06H317.99z M317.99,122.52c0.1,0,0.16,0.02,0.2,0.06c0.04,0.04,0.06,0.11,0.06,0.2v0.24
      c0,0.1-0.02,0.16-0.06,0.2c-0.04,0.04-0.11,0.06-0.2,0.06h-0.19c-0.1,0-0.16-0.02-0.2-0.06s-0.06-0.11-0.06-0.2v-0.24
      c0-0.1,0.02-0.16,0.06-0.2c0.04-0.04,0.11-0.06,0.2-0.06H317.99z"
          fill="#FFFFFF"
        />
        <path
          d="M267.6,125.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
      c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
      c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
      c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C267.06,125.52,267.31,125.49,267.6,125.49z
       M267.6,126.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
      c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
      c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
      C267.94,126.18,267.79,126.14,267.6,126.14z"
          fill="#FFFFFF"
        />
        <path
          d="M270.48,125.51l-0.01,2.93c0,0.09,0.03,0.16,0.08,0.2s0.13,0.07,0.24,0.07h0.22l0.09,0.54
      c-0.04,0.02-0.1,0.04-0.18,0.06c-0.08,0.02-0.16,0.03-0.24,0.04c-0.08,0.01-0.15,0.01-0.21,0.01c-0.24,0-0.43-0.06-0.57-0.19
      s-0.21-0.3-0.21-0.53v-3.13H270.48z"
          fill="#FFFFFF"
        />
        <path
          d="M273.89,126.62l-0.88,2.48c-0.02,0.06-0.05,0.1-0.1,0.14c-0.05,0.04-0.1,0.05-0.16,0.05h-0.7
      c-0.06,0-0.11-0.02-0.16-0.05c-0.04-0.03-0.08-0.08-0.1-0.14l-0.88-2.48h0.85l0.49,1.67c0.02,0.08,0.04,0.16,0.06,0.24
      c0.02,0.08,0.04,0.16,0.05,0.24h0.07c0.02-0.08,0.04-0.16,0.06-0.24c0.02-0.08,0.04-0.16,0.06-0.24l0.48-1.67H273.89z"
          fill="#FFFFFF"
        />
        <path
          d="M274.75,125.44c0.16,0,0.24,0.08,0.24,0.24v0.29c0,0.16-0.08,0.24-0.24,0.24h-0.35
      c-0.16,0-0.24-0.08-0.24-0.24v-0.29c0-0.16,0.08-0.24,0.24-0.24H274.75z M274.97,126.62v2.67h-0.79v-2.67H274.97z"
          fill="#FFFFFF"
        />
        <path
          d="M278.13,125.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
      c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
      c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
      c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C277.58,125.52,277.83,125.49,278.13,125.49z
       M278.13,126.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
      c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
      c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
      C278.46,126.18,278.31,126.14,278.13,126.14z"
          fill="#FFFFFF"
        />
        <path
          d="M280.77,126.62l0.51,1.73c0.01,0.07,0.03,0.14,0.04,0.21s0.03,0.14,0.04,0.21h0.15l-0.25,0.53h-0.19
      c-0.06,0-0.12-0.02-0.16-0.05s-0.08-0.08-0.1-0.14l-0.88-2.47H280.77z M282.88,126.62l-0.96,2.7c-0.04,0.13-0.1,0.27-0.18,0.4
      c-0.08,0.13-0.17,0.26-0.29,0.36c-0.12,0.11-0.26,0.19-0.42,0.25s-0.36,0.07-0.58,0.04l-0.06-0.45c0.22-0.06,0.39-0.13,0.51-0.23
      c0.12-0.1,0.22-0.22,0.29-0.38l0.17-0.37c0.03-0.08,0.06-0.18,0.09-0.29s0.06-0.21,0.08-0.29l0.5-1.73H282.88z"
          fill="#FFFFFF"
        />
        <path
          d="M283.97,126.62v2.54c0,0.16-0.03,0.32-0.08,0.47c-0.06,0.15-0.13,0.29-0.24,0.41
      c-0.1,0.12-0.23,0.22-0.37,0.3l-0.44-0.35c0.12-0.14,0.21-0.27,0.26-0.4c0.05-0.13,0.08-0.28,0.08-0.44v-2.54H283.97z
       M283.74,125.42c0.15,0,0.23,0.08,0.23,0.24v0.29c0,0.16-0.08,0.24-0.23,0.24h-0.35c-0.16,0-0.23-0.08-0.23-0.24v-0.29
      c0-0.16,0.08-0.24,0.23-0.24H283.74z"
          fill="#FFFFFF"
        />
        <path
          d="M284.95,128.47c0.1,0,0.18,0.02,0.22,0.06c0.04,0.04,0.06,0.11,0.06,0.22v0.35c0,0.14-0.01,0.27-0.04,0.38
      c-0.02,0.11-0.05,0.22-0.09,0.32c-0.04,0.1-0.09,0.19-0.15,0.29l-0.32-0.1c0.03-0.12,0.05-0.23,0.06-0.34
      c0.01-0.11,0.02-0.23,0.02-0.35c-0.15,0-0.23-0.08-0.23-0.25l0.01-0.29c0-0.1,0.03-0.18,0.07-0.22c0.04-0.04,0.11-0.06,0.21-0.06
      H284.95z"
          fill="#FFFFFF"
        />
        <path
          d="M288.33,125.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
      c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
      c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
      c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C287.78,125.52,288.03,125.49,288.33,125.49z
       M288.33,126.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
      c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
      c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
      C288.66,126.18,288.51,126.14,288.33,126.14z"
          fill="#FFFFFF"
        />
        <path
          d="M291.2,125.51l-0.01,2.93c0,0.09,0.03,0.16,0.08,0.2s0.13,0.07,0.24,0.07h0.22l0.09,0.54
      c-0.04,0.02-0.1,0.04-0.18,0.06c-0.08,0.02-0.16,0.03-0.24,0.04c-0.08,0.01-0.15,0.01-0.21,0.01c-0.24,0-0.43-0.06-0.57-0.19
      s-0.21-0.3-0.21-0.53v-3.13H291.2z"
          fill="#FFFFFF"
        />
        <path
          d="M294.62,126.62l-0.88,2.48c-0.02,0.06-0.05,0.1-0.1,0.14c-0.05,0.04-0.1,0.05-0.16,0.05h-0.7
      c-0.06,0-0.11-0.02-0.16-0.05c-0.04-0.03-0.08-0.08-0.1-0.14l-0.88-2.48h0.85l0.49,1.67c0.02,0.08,0.04,0.16,0.06,0.24
      c0.02,0.08,0.04,0.16,0.05,0.24h0.07c0.02-0.08,0.04-0.16,0.06-0.24c0.02-0.08,0.04-0.16,0.06-0.24l0.48-1.67H294.62z"
          fill="#FFFFFF"
        />
        <path
          d="M295.47,125.44c0.16,0,0.24,0.08,0.24,0.24v0.29c0,0.16-0.08,0.24-0.24,0.24h-0.35
      c-0.16,0-0.24-0.08-0.24-0.24v-0.29c0-0.16,0.08-0.24,0.24-0.24H295.47z M295.69,126.62v2.67h-0.79v-2.67H295.69z"
          fill="#FFFFFF"
        />
        <path
          d="M298.04,126.62v0.52h-2.01v-0.5l0.52-0.03H298.04z M297.3,125.88v2.55c0,0.1,0.02,0.17,0.07,0.21
      c0.04,0.04,0.12,0.06,0.22,0.06h0.35l0.09,0.54c-0.07,0.02-0.14,0.04-0.24,0.06c-0.09,0.02-0.19,0.03-0.28,0.04
      c-0.09,0.01-0.17,0.01-0.24,0.01c-0.24,0-0.43-0.06-0.56-0.19c-0.13-0.13-0.2-0.31-0.2-0.54l-0.01-2.73H297.3z"
          fill="#FFFFFF"
        />
        <path
          d="M298.96,125.44c0.16,0,0.24,0.08,0.24,0.24v0.29c0,0.16-0.08,0.24-0.24,0.24h-0.35
      c-0.16,0-0.24-0.08-0.24-0.24v-0.29c0-0.16,0.08-0.24,0.24-0.24H298.96z M299.18,126.62v2.67h-0.79v-2.67H299.18z"
          fill="#FFFFFF"
        />
        <path
          d="M301.01,126.55c0.45,0,0.77,0.08,0.97,0.23s0.29,0.38,0.29,0.68c0,0.25-0.06,0.43-0.18,0.55
      c-0.13,0.12-0.34,0.18-0.65,0.18h-1.49v-0.45h1.27c0.12,0,0.2-0.03,0.23-0.08c0.03-0.05,0.05-0.12,0.05-0.22
      c0-0.13-0.04-0.21-0.11-0.26c-0.07-0.05-0.19-0.07-0.35-0.07c-0.15,0-0.26,0.02-0.35,0.06c-0.08,0.04-0.14,0.12-0.17,0.23
      c-0.03,0.11-0.05,0.27-0.05,0.49c0,0.22,0.02,0.39,0.06,0.52c0.04,0.12,0.11,0.2,0.2,0.25c0.09,0.05,0.23,0.07,0.4,0.07
      c0.12,0,0.27,0,0.46-0.01c0.18-0.01,0.36-0.02,0.55-0.05l0.08,0.49c-0.1,0.05-0.22,0.09-0.36,0.12c-0.13,0.03-0.27,0.05-0.41,0.06
      s-0.28,0.02-0.41,0.02c-0.34,0-0.61-0.05-0.82-0.15c-0.21-0.1-0.36-0.25-0.45-0.46s-0.14-0.47-0.14-0.79
      c0-0.35,0.05-0.63,0.14-0.83c0.09-0.21,0.24-0.35,0.44-0.44C300.42,126.59,300.68,126.55,301.01,126.55z"
          fill="#FFFFFF"
        />
        <path d="M304.61,125.56v3.73h-0.82v-3.73H304.61z" fill="#FFFFFF" />
        <path d="M306.81,127.66v0.51h-1.61v-0.51H306.81z" fill="#FFFFFF" />
        <path d="M308.21,125.56v3.73h-0.82v-3.73H308.21z" fill="#FFFFFF" />
        <path
          d="M311.89,125.56l-1.01,3.5c-0.02,0.06-0.05,0.12-0.11,0.16c-0.05,0.04-0.11,0.06-0.19,0.06h-0.76
      c-0.08,0-0.14-0.02-0.19-0.06c-0.05-0.04-0.09-0.1-0.1-0.16l-1.02-3.5h0.85l0.68,2.69c0.01,0.07,0.03,0.14,0.05,0.21
      c0.02,0.07,0.03,0.14,0.04,0.21h0.14c0.01-0.07,0.02-0.14,0.04-0.21c0.02-0.07,0.03-0.14,0.05-0.21l0.67-2.68H311.89z"
          fill="#FFFFFF"
        />
        <path
          d="M312.35,128.47c0.1,0,0.18,0.02,0.22,0.06c0.04,0.04,0.06,0.11,0.06,0.22v0.35c0,0.14-0.01,0.27-0.04,0.38
      c-0.02,0.11-0.05,0.22-0.09,0.32c-0.04,0.1-0.09,0.19-0.15,0.29l-0.32-0.1c0.03-0.12,0.05-0.23,0.06-0.34
      c0.01-0.11,0.02-0.23,0.02-0.35c-0.15,0-0.23-0.08-0.23-0.25l0.01-0.29c0-0.1,0.03-0.18,0.07-0.22c0.04-0.04,0.11-0.06,0.21-0.06
      H312.35z"
          fill="#FFFFFF"
        />
        <path
          d="M268.14,131.56c0.09,0,0.17,0.02,0.22,0.06s0.1,0.09,0.12,0.16c0.02,0.07,0.02,0.14-0.01,0.22l-1.14,3.29
      h-0.81l0.95-2.71c0.02-0.06,0.04-0.12,0.07-0.18s0.06-0.12,0.09-0.17h-1.71l-0.04-0.67H268.14z"
          fill="#FFFFFF"
        />
        <path
          d="M270.41,131.56l-0.9,2.14c-0.02,0.06-0.02,0.1,0,0.13c0.03,0.03,0.06,0.05,0.11,0.05h2.14v0.49l-0.46,0.08
      h-2.27c-0.1,0-0.19-0.03-0.26-0.08s-0.12-0.13-0.14-0.22c-0.02-0.09-0.02-0.18,0.02-0.27l0.98-2.33H270.41z M271.31,132.29v3
      h-0.76v-2.41l0.13-0.59H271.31z"
          fill="#FFFFFF"
        />
        <path
          d="M273.66,131.56v3.73h-0.82v-2.64c0-0.07,0-0.14,0.01-0.2s0.01-0.13,0.03-0.2l-0.89,0.23l-0.1-0.53
      l0.95-0.37H273.66z"
          fill="#FFFFFF"
        />
        <path
          d="M275.75,131.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C275.11,131.55,275.39,131.49,275.75,131.49z M275.74,132.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S275.86,132.13,275.74,132.13z"
          fill="#FFFFFF"
        />
        <path
          d="M279.05,131.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C278.4,131.55,278.69,131.49,279.05,131.49z M279.04,132.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S279.16,132.13,279.04,132.13z"
          fill="#FFFFFF"
        />
        <path d="M282.9,131.56v3.73h-0.82v-3.73H282.9z" fill="#FFFFFF" />
        <path d="M284.37,131.56v3.73h-0.82v-3.73H284.37z" fill="#FFFFFF" />
        <path
          d="M286.24,131.49c0.18,0,0.38,0.01,0.6,0.03c0.22,0.02,0.43,0.06,0.63,0.12l-0.05,0.54
      c-0.16,0-0.34-0.01-0.56-0.01c-0.21,0-0.42,0-0.63,0c-0.1,0-0.19,0-0.26,0.01c-0.07,0.01-0.13,0.02-0.17,0.04
      s-0.07,0.06-0.09,0.11c-0.02,0.05-0.03,0.11-0.03,0.19c0,0.13,0.03,0.22,0.09,0.28c0.06,0.06,0.16,0.11,0.31,0.16l0.72,0.23
      c0.3,0.1,0.51,0.24,0.62,0.41c0.12,0.17,0.17,0.4,0.17,0.68c0,0.21-0.03,0.39-0.08,0.53c-0.05,0.14-0.13,0.25-0.24,0.33
      c-0.11,0.08-0.25,0.14-0.43,0.17c-0.18,0.03-0.39,0.05-0.65,0.05c-0.12,0-0.3-0.01-0.52-0.02c-0.23-0.02-0.49-0.06-0.78-0.12
      l0.05-0.58c0.21,0,0.4,0.01,0.55,0.01c0.16,0.01,0.29,0.01,0.4,0.01c0.11,0,0.21,0,0.29,0c0.14,0,0.26-0.01,0.34-0.03
      c0.08-0.02,0.14-0.05,0.17-0.11c0.03-0.05,0.05-0.13,0.05-0.23c0-0.09-0.01-0.16-0.04-0.21c-0.02-0.05-0.06-0.09-0.11-0.12
      c-0.05-0.03-0.12-0.06-0.21-0.08l-0.76-0.26c-0.28-0.1-0.49-0.24-0.6-0.41c-0.12-0.17-0.18-0.39-0.18-0.67
      c0-0.22,0.03-0.39,0.08-0.53c0.05-0.13,0.13-0.24,0.24-0.32c0.11-0.08,0.25-0.13,0.43-0.16
      C285.79,131.51,286,131.49,286.24,131.49z"
          fill="#FFFFFF"
        />
        <path
          d="M289.85,131.56c0.07,0,0.13,0.02,0.18,0.06c0.05,0.04,0.09,0.09,0.11,0.16l1.08,3.51h-0.84l-0.76-2.74
      c-0.01-0.06-0.03-0.12-0.04-0.19c-0.01-0.06-0.03-0.12-0.04-0.18h-0.15c-0.01,0.06-0.02,0.12-0.04,0.18
      c-0.01,0.06-0.03,0.12-0.05,0.19l-0.76,2.74h-0.85l1.08-3.51c0.02-0.06,0.05-0.12,0.11-0.16s0.11-0.06,0.18-0.06H289.85z
       M290.42,133.68v0.62h-1.93v-0.62H290.42z"
          fill="#FFFFFF"
        />
        <path
          d="M292.3,131.56v2.79c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.12,0.07,0.22,0.07h1.3l0.04,0.62
      c-0.26,0.03-0.52,0.05-0.8,0.05c-0.28,0.01-0.56,0.01-0.83,0.01c-0.27,0-0.47-0.07-0.61-0.22c-0.14-0.15-0.21-0.33-0.21-0.56
      v-2.96H292.3z"
          fill="#FFFFFF"
        />
        <path
          d="M298.09,131.56c0.19,0,0.3,0.1,0.3,0.3l0.14,3.42h-0.76l-0.12-3.09h-0.1l-0.6,2.32
      c-0.03,0.17-0.14,0.26-0.32,0.26h-0.52c-0.18,0-0.29-0.09-0.33-0.26l-0.6-2.32h-0.09l-0.11,3.09h-0.77l0.14-3.42
      c0.01-0.2,0.11-0.3,0.31-0.3h0.77c0.18,0,0.28,0.09,0.32,0.26l0.48,1.87c0.02,0.09,0.04,0.17,0.05,0.26
      c0.01,0.09,0.03,0.18,0.05,0.27h0.07c0.02-0.09,0.04-0.18,0.05-0.27c0.02-0.09,0.03-0.18,0.05-0.27l0.47-1.87
      c0.04-0.18,0.14-0.26,0.32-0.26H298.09z"
          fill="#FFFFFF"
        />
        <path d="M299.93,131.56v3.73h-0.82v-3.73H299.93z" fill="#FFFFFF" />
        <path
          d="M267.35,137.49c0.18,0,0.38,0.01,0.6,0.03c0.22,0.02,0.43,0.06,0.63,0.12l-0.05,0.54
      c-0.16,0-0.34-0.01-0.56-0.01c-0.21,0-0.42,0-0.63,0c-0.1,0-0.19,0-0.26,0.01c-0.07,0.01-0.13,0.02-0.17,0.04
      s-0.07,0.06-0.09,0.11c-0.02,0.05-0.03,0.11-0.03,0.19c0,0.13,0.03,0.22,0.09,0.28c0.06,0.06,0.16,0.11,0.31,0.16l0.72,0.23
      c0.3,0.1,0.51,0.24,0.62,0.41c0.12,0.17,0.17,0.4,0.17,0.68c0,0.21-0.03,0.39-0.08,0.53c-0.05,0.14-0.13,0.25-0.24,0.33
      c-0.11,0.08-0.25,0.14-0.43,0.17c-0.18,0.03-0.39,0.05-0.65,0.05c-0.12,0-0.3-0.01-0.52-0.02c-0.23-0.02-0.49-0.06-0.78-0.12
      l0.05-0.58c0.21,0,0.4,0.01,0.55,0.01c0.16,0.01,0.29,0.01,0.4,0.01c0.11,0,0.21,0,0.29,0c0.14,0,0.26-0.01,0.34-0.03
      c0.08-0.02,0.14-0.05,0.17-0.11c0.03-0.05,0.05-0.13,0.05-0.23c0-0.09-0.01-0.16-0.04-0.21c-0.02-0.05-0.06-0.09-0.11-0.12
      c-0.05-0.03-0.12-0.06-0.21-0.08l-0.76-0.26c-0.28-0.1-0.49-0.24-0.6-0.41c-0.12-0.17-0.18-0.39-0.18-0.67
      c0-0.22,0.03-0.39,0.08-0.53c0.05-0.13,0.13-0.24,0.24-0.32c0.11-0.08,0.25-0.13,0.43-0.16
      C266.9,137.51,267.1,137.49,267.35,137.49z"
          fill="#FFFFFF"
        />
        <path
          d="M272.15,137.56v2.24c0,0.38-0.05,0.68-0.14,0.91c-0.09,0.23-0.25,0.39-0.46,0.5s-0.5,0.15-0.86,0.15
      c-0.38,0-0.68-0.05-0.91-0.15s-0.39-0.27-0.49-0.5c-0.1-0.23-0.15-0.53-0.15-0.91v-2.24h0.83v2.24c0,0.23,0.02,0.42,0.06,0.55
      c0.04,0.13,0.11,0.22,0.21,0.28c0.1,0.05,0.24,0.08,0.4,0.08c0.17,0,0.3-0.03,0.4-0.08c0.1-0.05,0.17-0.15,0.21-0.28
      c0.04-0.13,0.06-0.31,0.06-0.55v-2.24H272.15z"
          fill="#FFFFFF"
        />
        <path
          d="M274.26,137.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
      c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
      c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
      c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C273.71,137.52,273.96,137.49,274.26,137.49z
       M274.26,138.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
      c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
      c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
      C274.59,138.18,274.44,138.14,274.26,138.14z"
          fill="#FFFFFF"
        />
        <path
          d="M280.19,137.56c0.19,0,0.3,0.1,0.3,0.3l0.14,3.42h-0.76l-0.12-3.09h-0.1l-0.6,2.32
      c-0.03,0.17-0.14,0.26-0.32,0.26h-0.52c-0.18,0-0.29-0.09-0.33-0.26l-0.6-2.32h-0.09l-0.11,3.09h-0.77l0.14-3.42
      c0.01-0.2,0.11-0.3,0.31-0.3h0.77c0.18,0,0.28,0.09,0.32,0.26l0.48,1.87c0.02,0.09,0.04,0.17,0.05,0.26
      c0.01,0.09,0.03,0.18,0.05,0.27h0.07c0.02-0.09,0.04-0.18,0.05-0.27c0.02-0.09,0.03-0.18,0.05-0.27l0.47-1.87
      c0.04-0.18,0.14-0.26,0.32-0.26H280.19z"
          fill="#FFFFFF"
        />
        <path d="M282.04,137.56v3.73h-0.82v-3.73H282.04z" fill="#FFFFFF" />
        <path d="M285.18,137.56l-2.12,4.12h-0.64l2.12-4.12H285.18z" fill="#FFFFFF" />
        <path
          d="M286.35,137.55c0.19,0,0.39,0,0.59,0c0.2,0,0.4,0.01,0.59,0.02s0.37,0.02,0.54,0.04l-0.04,0.59h-1.35
      c-0.1,0-0.17,0.02-0.22,0.07c-0.05,0.05-0.08,0.12-0.08,0.21v2.81h-0.83v-2.98c0-0.23,0.07-0.42,0.22-0.55
      C285.91,137.62,286.11,137.55,286.35,137.55z M285.64,139.12h2.18v0.58h-2.18V139.12z"
          fill="#FFFFFF"
        />
        <path d="M289.28,137.56v3.73h-0.82v-3.73H289.28z" fill="#FFFFFF" />
        <path
          d="M293.08,137.56v3.43c0,0.2-0.1,0.3-0.3,0.3h-0.45c-0.09,0-0.16-0.02-0.21-0.06
      c-0.05-0.04-0.09-0.1-0.13-0.19l-1.03-1.96c-0.04-0.08-0.09-0.17-0.13-0.28c-0.04-0.1-0.08-0.2-0.11-0.29h-0.06
      c0.01,0.1,0.02,0.2,0.03,0.29s0.01,0.2,0.01,0.29v2.18h-0.78v-3.43c0-0.2,0.1-0.3,0.3-0.3h0.45c0.09,0,0.15,0.02,0.2,0.06
      c0.04,0.04,0.09,0.1,0.13,0.19l1,1.9c0.04,0.08,0.09,0.17,0.13,0.28s0.09,0.21,0.14,0.31h0.06c-0.01-0.11-0.02-0.21-0.02-0.32
      s-0.01-0.2-0.01-0.3l-0.01-2.11H293.08z"
          fill="#FFFFFF"
        />
        <path
          d="M294.53,137.56v2.79c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.12,0.07,0.22,0.07h1.3l0.04,0.62
      c-0.26,0.03-0.52,0.05-0.8,0.05c-0.28,0.01-0.56,0.01-0.83,0.01c-0.27,0-0.47-0.07-0.61-0.22c-0.14-0.15-0.21-0.33-0.21-0.56
      v-2.96H294.53z"
          fill="#FFFFFF"
        />
        <path
          d="M298.28,137.56c0.07,0,0.13,0.02,0.18,0.06c0.05,0.04,0.09,0.09,0.11,0.16l1.08,3.51h-0.84l-0.76-2.74
      c-0.01-0.06-0.03-0.12-0.04-0.19c-0.01-0.06-0.03-0.12-0.04-0.18h-0.15c-0.01,0.06-0.02,0.12-0.04,0.18
      c-0.01,0.06-0.03,0.12-0.05,0.19l-0.76,2.74h-0.85l1.08-3.51c0.02-0.06,0.05-0.12,0.11-0.16s0.11-0.06,0.18-0.06H298.28z
       M298.85,139.68v0.62h-1.93v-0.62H298.85z"
          fill="#FFFFFF"
        />
        <path
          d="M303.07,137.56v3.43c0,0.2-0.1,0.3-0.3,0.3h-0.45c-0.09,0-0.16-0.02-0.21-0.06
      c-0.05-0.04-0.09-0.1-0.13-0.19l-1.03-1.96c-0.04-0.08-0.09-0.17-0.13-0.28c-0.04-0.1-0.08-0.2-0.11-0.29h-0.06
      c0.01,0.1,0.02,0.2,0.03,0.29s0.01,0.2,0.01,0.29v2.18h-0.78v-3.43c0-0.2,0.1-0.3,0.3-0.3h0.45c0.09,0,0.15,0.02,0.2,0.06
      c0.04,0.04,0.09,0.1,0.13,0.19l1,1.9c0.04,0.08,0.09,0.17,0.13,0.28s0.09,0.21,0.14,0.31h0.06c-0.01-0.11-0.02-0.21-0.02-0.32
      s-0.01-0.2-0.01-0.3l-0.01-2.11H303.07z"
          fill="#FFFFFF"
        />
        <path
          d="M305.17,137.51c0.29,0,0.54,0.03,0.75,0.09c0.2,0.06,0.37,0.17,0.49,0.31c0.12,0.14,0.21,0.34,0.27,0.59
      c0.06,0.25,0.09,0.56,0.09,0.93c0,0.37-0.03,0.68-0.09,0.93c-0.06,0.25-0.15,0.44-0.27,0.59c-0.12,0.14-0.29,0.25-0.49,0.31
      c-0.2,0.06-0.45,0.09-0.75,0.09c-0.28,0-0.55,0-0.78-0.02s-0.46-0.02-0.68-0.04l0.35-0.61c0.14,0.01,0.3,0.01,0.49,0.02
      c0.19,0,0.4,0.01,0.63,0.01c0.18,0,0.33-0.04,0.43-0.11c0.11-0.07,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78
      c0-0.33-0.02-0.59-0.07-0.78c-0.04-0.19-0.12-0.32-0.23-0.39c-0.11-0.08-0.25-0.11-0.43-0.11c-0.22,0-0.42,0-0.59,0
      c-0.17,0-0.34,0-0.51,0l-0.37-0.58c0.22-0.01,0.44-0.03,0.68-0.04C304.62,137.51,304.88,137.51,305.17,137.51z M304.53,137.56
      v3.73h-0.83v-3.73H304.53z"
          fill="#FFFFFF"
        />
        <path
          d="M267.55,143.51c0.35,0,0.62,0.04,0.82,0.11c0.2,0.07,0.34,0.2,0.42,0.37s0.13,0.42,0.13,0.74
      c0,0.32-0.04,0.56-0.12,0.74c-0.08,0.18-0.21,0.3-0.4,0.37c-0.18,0.07-0.43,0.1-0.75,0.1c-0.17,0-0.33-0.01-0.49-0.02
      c-0.16-0.01-0.31-0.03-0.44-0.05s-0.25-0.04-0.35-0.07c-0.1-0.02-0.16-0.05-0.2-0.07l0.02-0.41c0.21,0,0.43,0,0.66,0
      s0.45,0,0.68,0c0.14,0,0.26-0.02,0.34-0.05s0.14-0.09,0.17-0.18c0.03-0.09,0.05-0.21,0.05-0.36c0-0.16-0.02-0.28-0.05-0.37
      c-0.04-0.09-0.09-0.15-0.17-0.18s-0.19-0.05-0.34-0.05c-0.33,0-0.62,0-0.85,0c-0.24,0-0.4,0.01-0.48,0.02l-0.08-0.59
      c0.15-0.02,0.29-0.03,0.43-0.04c0.13-0.01,0.28-0.01,0.43-0.01C267.14,143.51,267.33,143.51,267.55,143.51z M266.94,143.56v3.73
      h-0.83v-3.73H266.94z"
          fill="#FFFFFF"
        />
        <path
          d="M272.22,143.56v2.24c0,0.38-0.05,0.68-0.14,0.91c-0.09,0.23-0.25,0.39-0.46,0.5s-0.5,0.15-0.86,0.15
      c-0.38,0-0.68-0.05-0.91-0.15s-0.39-0.27-0.49-0.5c-0.1-0.23-0.15-0.53-0.15-0.91v-2.24h0.83v2.24c0,0.23,0.02,0.42,0.06,0.55
      c0.04,0.13,0.11,0.22,0.21,0.28c0.1,0.05,0.24,0.08,0.4,0.08c0.17,0,0.3-0.03,0.4-0.08c0.1-0.05,0.17-0.15,0.21-0.28
      c0.04-0.13,0.06-0.31,0.06-0.55v-2.24H272.22z"
          fill="#FFFFFF"
        />
        <path
          d="M273.56,143.56v3.73h-0.83v-3.73H273.56z M274.94,144.99v0.62h-1.42v-0.62H274.94z M275.71,143.56v3.73
      h-0.83v-3.73H275.71z"
          fill="#FFFFFF"
        />
        <path d="M278.74,143.56l-2.12,4.12h-0.64l2.12-4.12H278.74z" fill="#FFFFFF" />
        <path d="M281.8,143.56v0.65h-3.07v-0.65H281.8z M280.67,143.56v3.73h-0.83v-3.73H280.67z" fill="#FFFFFF" />
        <path
          d="M282.81,143.55c0.2,0,0.4,0,0.61,0s0.41,0.01,0.61,0.01c0.2,0.01,0.38,0.02,0.56,0.04l-0.04,0.6h-1.43
      c-0.09,0-0.16,0.02-0.2,0.07c-0.04,0.05-0.07,0.12-0.07,0.21v1.9c0,0.09,0.02,0.16,0.07,0.21s0.11,0.07,0.2,0.07h1.43l0.04,0.6
      c-0.17,0.01-0.36,0.03-0.56,0.04c-0.2,0.01-0.4,0.01-0.61,0.02s-0.41,0-0.61,0c-0.24,0-0.43-0.07-0.58-0.21
      c-0.15-0.14-0.22-0.32-0.22-0.54v-2.26c0-0.23,0.07-0.41,0.22-0.54C282.38,143.61,282.58,143.55,282.81,143.55z M282.12,145.06
      h2.23v0.58h-2.23V145.06z"
          fill="#FFFFFF"
        />
        <path
          d="M285.77,143.56v2.79c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.12,0.07,0.22,0.07h1.3l0.04,0.62
      c-0.26,0.03-0.52,0.05-0.8,0.05c-0.28,0.01-0.56,0.01-0.83,0.01c-0.27,0-0.47-0.07-0.61-0.22c-0.14-0.15-0.21-0.33-0.21-0.56
      v-2.96H285.77z"
          fill="#FFFFFF"
        />
        <path
          d="M288.02,144.85c0.1,0,0.18,0.02,0.22,0.06c0.04,0.04,0.06,0.11,0.06,0.22v0.26c0,0.1-0.02,0.18-0.06,0.22
      c-0.04,0.04-0.11,0.06-0.22,0.06h-0.21c-0.1,0-0.18-0.02-0.22-0.06c-0.04-0.04-0.06-0.11-0.06-0.22v-0.26
      c0-0.1,0.02-0.18,0.06-0.22c0.04-0.04,0.11-0.06,0.22-0.06H288.02z M288.02,146.46c0.1,0,0.18,0.02,0.22,0.06
      c0.04,0.04,0.06,0.11,0.06,0.22V147c0,0.1-0.02,0.18-0.06,0.22s-0.11,0.06-0.22,0.06h-0.21c-0.1,0-0.18-0.02-0.22-0.06
      s-0.06-0.11-0.06-0.22v-0.26c0-0.1,0.02-0.18,0.06-0.22c0.04-0.04,0.11-0.06,0.22-0.06H288.02z"
          fill="#FFFFFF"
        />
        <path d="M268.42,151.37v0.59h-2.46v-0.59H268.42z M267.49,150.46v2.4h-0.59l0-2.4H267.49z" fill="#FFFFFF" />
        <path
          d="M269.9,149.49c0.29,0,0.53,0.03,0.71,0.08c0.18,0.05,0.31,0.14,0.39,0.27s0.12,0.3,0.12,0.52
      c0,0.17-0.02,0.33-0.05,0.46c-0.03,0.13-0.09,0.24-0.17,0.32c-0.08,0.08-0.2,0.14-0.36,0.16v0.02c0.25,0.04,0.43,0.14,0.53,0.29
      c0.1,0.15,0.15,0.37,0.15,0.64c0,0.26-0.04,0.47-0.12,0.63s-0.22,0.28-0.41,0.36c-0.19,0.08-0.44,0.12-0.76,0.12
      c-0.22,0-0.43-0.01-0.65-0.03c-0.22-0.02-0.43-0.04-0.64-0.07l0.03-0.6c0.14,0.01,0.27,0.02,0.39,0.02s0.22,0.01,0.33,0.01
      s0.21,0,0.32,0c0.18,0,0.32-0.01,0.42-0.05c0.1-0.03,0.17-0.09,0.22-0.17s0.07-0.2,0.07-0.34c0-0.12-0.02-0.21-0.05-0.29
      c-0.03-0.08-0.09-0.14-0.17-0.18c-0.08-0.04-0.2-0.06-0.36-0.06l-0.71,0v-0.54h0.71c0.12,0,0.21-0.01,0.28-0.04
      c0.07-0.03,0.12-0.06,0.15-0.11s0.06-0.1,0.07-0.16c0.01-0.06,0.02-0.13,0.02-0.2c0-0.12-0.02-0.21-0.06-0.27
      c-0.03-0.06-0.09-0.1-0.18-0.12c-0.08-0.02-0.2-0.03-0.35-0.03c-0.19,0-0.37,0-0.54,0.01c-0.17,0-0.33,0.01-0.46,0.01l-0.04-0.58
      c0.2-0.04,0.4-0.06,0.6-0.08C269.51,149.49,269.7,149.49,269.9,149.49z"
          fill="#FFFFFF"
        />
        <path
          d="M273.92,149.56l-0.03,0.62h-1.55l-0.04,0.7l0.55,0.06c0.23,0.02,0.42,0.06,0.57,0.13
      c0.15,0.07,0.27,0.15,0.36,0.25s0.16,0.22,0.19,0.36c0.04,0.14,0.06,0.29,0.06,0.46c0,0.16-0.02,0.32-0.06,0.46
      c-0.04,0.15-0.11,0.27-0.2,0.39c-0.1,0.11-0.23,0.2-0.4,0.26c-0.17,0.06-0.39,0.09-0.65,0.09c-0.17,0-0.37-0.01-0.59-0.03
      s-0.44-0.05-0.66-0.08l0.08-0.63c0.18,0.02,0.35,0.03,0.52,0.05c0.17,0.01,0.32,0.02,0.45,0.02c0.19,0,0.33-0.02,0.43-0.06
      c0.1-0.04,0.17-0.1,0.2-0.18c0.04-0.08,0.06-0.18,0.07-0.31c0-0.12-0.01-0.21-0.04-0.28s-0.08-0.12-0.15-0.17
      c-0.07-0.04-0.16-0.07-0.28-0.08c-0.11-0.02-0.25-0.03-0.42-0.04l-0.46-0.05c-0.08-0.01-0.14-0.04-0.18-0.09
      c-0.05-0.05-0.07-0.12-0.06-0.19l0.09-1.65H273.92z"
          fill="#FFFFFF"
        />
        <path
          d="M275.72,149.5c0.27,0,0.49,0.02,0.66,0.06c0.17,0.04,0.3,0.1,0.4,0.18c0.09,0.08,0.16,0.18,0.2,0.31
      c0.04,0.13,0.05,0.28,0.05,0.45c0,0.14-0.02,0.28-0.06,0.4s-0.1,0.23-0.18,0.31c-0.08,0.08-0.19,0.13-0.32,0.14v0.02
      c0.17,0.01,0.31,0.07,0.4,0.16c0.09,0.09,0.16,0.21,0.19,0.35c0.04,0.14,0.05,0.3,0.05,0.48c0,0.24-0.04,0.43-0.11,0.58
      c-0.08,0.15-0.21,0.25-0.42,0.32c-0.2,0.06-0.49,0.1-0.87,0.1c-0.37,0-0.66-0.03-0.86-0.1c-0.2-0.07-0.34-0.17-0.42-0.32
      s-0.12-0.34-0.12-0.58c0-0.18,0.02-0.34,0.05-0.48s0.1-0.26,0.19-0.35s0.23-0.14,0.4-0.16v-0.02c-0.13-0.01-0.24-0.06-0.32-0.14
      s-0.14-0.18-0.18-0.31c-0.04-0.12-0.06-0.26-0.06-0.4c0-0.17,0.02-0.32,0.06-0.45c0.04-0.13,0.1-0.23,0.2-0.31s0.23-0.14,0.4-0.18
      C275.24,149.52,275.46,149.5,275.72,149.5z M275.73,151.63c-0.13,0-0.24,0.01-0.32,0.02c-0.08,0.02-0.15,0.04-0.19,0.09
      s-0.08,0.1-0.1,0.17c-0.02,0.07-0.03,0.17-0.03,0.29c0,0.14,0.02,0.24,0.05,0.32c0.03,0.08,0.1,0.13,0.19,0.17
      s0.22,0.05,0.39,0.05c0.17,0,0.29-0.02,0.38-0.05c0.09-0.03,0.15-0.08,0.18-0.17c0.03-0.08,0.05-0.19,0.05-0.32
      c0-0.12-0.01-0.21-0.03-0.29c-0.02-0.07-0.05-0.13-0.09-0.17c-0.04-0.04-0.1-0.07-0.18-0.09
      C275.96,151.64,275.86,151.63,275.73,151.63z M275.73,150.07c-0.16,0-0.27,0.01-0.35,0.04c-0.08,0.03-0.14,0.08-0.17,0.16
      c-0.03,0.08-0.05,0.18-0.05,0.32c0,0.13,0.02,0.24,0.05,0.31c0.03,0.07,0.09,0.12,0.18,0.14c0.08,0.03,0.2,0.04,0.36,0.04
      c0.15,0,0.27-0.01,0.35-0.04c0.08-0.03,0.13-0.08,0.16-0.15s0.04-0.17,0.04-0.3c0-0.14-0.01-0.25-0.04-0.32
      c-0.03-0.08-0.08-0.13-0.16-0.16C276,150.08,275.88,150.07,275.73,150.07z"
          fill="#FFFFFF"
        />
        <path
          d="M279.92,149.02l0.09,0.35c-0.32,0.14-0.55,0.41-0.68,0.79s-0.2,0.86-0.2,1.44c0,0.38,0.03,0.72,0.09,1.02
      s0.15,0.55,0.28,0.75c0.13,0.2,0.3,0.36,0.51,0.46l-0.09,0.35c-0.54-0.17-0.94-0.47-1.19-0.88c-0.25-0.42-0.38-0.98-0.38-1.69
      c0-0.72,0.13-1.28,0.38-1.7C278.98,149.48,279.38,149.19,279.92,149.02z"
          fill="#FFFFFF"
        />
        <path
          d="M281.6,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C280.96,149.55,281.24,149.49,281.6,149.49z M281.59,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S281.71,150.13,281.59,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M283.23,149.02c0.54,0.17,0.94,0.46,1.19,0.88c0.25,0.42,0.38,0.99,0.38,1.7c0,0.71-0.13,1.28-0.38,1.69
      c-0.25,0.42-0.65,0.71-1.19,0.88l-0.09-0.35c0.21-0.1,0.38-0.26,0.51-0.46c0.13-0.2,0.22-0.45,0.28-0.75
      c0.06-0.3,0.09-0.64,0.09-1.02c0-0.58-0.07-1.06-0.2-1.44c-0.13-0.38-0.36-0.64-0.68-0.79L283.23,149.02z"
          fill="#FFFFFF"
        />
        <path
          d="M287.28,149.5c0.26,0,0.48,0.02,0.67,0.07c0.19,0.04,0.33,0.13,0.44,0.26c0.1,0.13,0.16,0.31,0.16,0.54
      c0,0.14-0.02,0.27-0.05,0.39c-0.03,0.12-0.09,0.25-0.18,0.38c-0.09,0.13-0.21,0.28-0.38,0.46l-0.98,1.01
      c0.09-0.01,0.18-0.02,0.27-0.03c0.09,0,0.18-0.01,0.28-0.01h1.15v0.72h-2.67v-0.44c0-0.07,0.01-0.12,0.03-0.18
      c0.02-0.06,0.05-0.11,0.09-0.15l0.93-0.99c0.22-0.23,0.39-0.43,0.5-0.61c0.12-0.18,0.17-0.33,0.17-0.46
      c0-0.08-0.02-0.14-0.06-0.17c-0.04-0.04-0.09-0.06-0.17-0.07c-0.08-0.01-0.17-0.02-0.28-0.02c-0.13,0-0.24,0-0.35,0
      s-0.23,0.01-0.35,0.01c-0.12,0.01-0.27,0.02-0.43,0.03l-0.03-0.62c0.18-0.04,0.34-0.07,0.48-0.09c0.14-0.02,0.28-0.03,0.4-0.04
      C287.02,149.5,287.15,149.5,287.28,149.5z"
          fill="#FFFFFF"
        />
        <path
          d="M290.21,149.5c0.29,0,0.52,0.03,0.7,0.09c0.18,0.06,0.32,0.16,0.41,0.3c0.1,0.14,0.16,0.33,0.2,0.58
      c0.04,0.24,0.05,0.55,0.05,0.93c0,0.42-0.03,0.76-0.09,1.02c-0.06,0.26-0.15,0.46-0.27,0.59c-0.12,0.14-0.28,0.23-0.48,0.28
      c-0.2,0.05-0.44,0.07-0.73,0.07c-0.1,0-0.23,0-0.4-0.01c-0.17-0.01-0.36-0.04-0.55-0.09l0.04-0.52c0.1,0,0.2,0.01,0.28,0.01
      c0.08,0,0.17,0,0.27,0c0.1,0,0.22,0,0.37,0c0.16,0,0.29-0.02,0.39-0.06c0.1-0.04,0.18-0.11,0.23-0.21s0.09-0.25,0.11-0.43
      c0.02-0.18,0.03-0.41,0.03-0.69c0-0.25,0-0.46-0.01-0.62s-0.03-0.29-0.07-0.38s-0.09-0.15-0.17-0.19
      c-0.08-0.03-0.18-0.05-0.32-0.05c-0.16,0-0.28,0.02-0.35,0.08c-0.08,0.05-0.13,0.13-0.15,0.23c-0.02,0.11-0.04,0.24-0.04,0.4
      c0,0.14,0.01,0.26,0.04,0.35c0.03,0.09,0.08,0.16,0.16,0.2c0.08,0.04,0.18,0.06,0.32,0.06c0.1,0,0.19-0.01,0.27-0.04
      c0.08-0.03,0.17-0.07,0.26-0.13c0.09-0.06,0.21-0.14,0.35-0.25l0.02,0.31c-0.12,0.18-0.23,0.32-0.34,0.42
      c-0.11,0.1-0.23,0.17-0.35,0.21c-0.13,0.04-0.28,0.06-0.46,0.06c-0.27,0-0.48-0.05-0.63-0.15c-0.15-0.1-0.26-0.24-0.32-0.41
      c-0.06-0.18-0.09-0.38-0.09-0.61c0-0.31,0.04-0.56,0.12-0.76c0.08-0.2,0.21-0.34,0.4-0.44C289.6,149.54,289.87,149.5,290.21,149.5
      z"
          fill="#FFFFFF"
        />
        <path
          d="M293.34,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C292.7,149.55,292.98,149.49,293.34,149.49z M293.33,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S293.45,150.13,293.33,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M297.48,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C296.84,149.55,297.12,149.49,297.48,149.49z M297.47,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S297.59,150.13,297.47,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M300.67,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C300.03,149.55,300.31,149.49,300.67,149.49z M300.66,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S300.78,150.13,300.66,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M303.99,149.56v3.73h-0.82v-2.64c0-0.07,0-0.14,0.01-0.2s0.01-0.13,0.03-0.2l-0.89,0.23l-0.1-0.53
      l0.95-0.37H303.99z"
          fill="#FFFFFF"
        />
        <path
          d="M305.98,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C305.34,149.55,305.62,149.49,305.98,149.49z M305.97,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S306.09,150.13,305.97,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M310.11,149.56l-0.03,0.62h-1.55l-0.04,0.7l0.55,0.06c0.23,0.02,0.42,0.06,0.57,0.13
      c0.15,0.07,0.27,0.15,0.36,0.25s0.16,0.22,0.19,0.36c0.04,0.14,0.06,0.29,0.06,0.46c0,0.16-0.02,0.32-0.06,0.46
      c-0.04,0.15-0.11,0.27-0.2,0.39c-0.1,0.11-0.23,0.2-0.4,0.26c-0.17,0.06-0.39,0.09-0.65,0.09c-0.17,0-0.37-0.01-0.59-0.03
      s-0.44-0.05-0.66-0.08l0.08-0.63c0.18,0.02,0.35,0.03,0.52,0.05c0.17,0.01,0.32,0.02,0.45,0.02c0.19,0,0.33-0.02,0.43-0.06
      c0.1-0.04,0.17-0.1,0.2-0.18c0.04-0.08,0.06-0.18,0.07-0.31c0-0.12-0.01-0.21-0.04-0.28s-0.08-0.12-0.15-0.17
      c-0.07-0.04-0.16-0.07-0.28-0.08c-0.11-0.02-0.25-0.03-0.42-0.04l-0.46-0.05c-0.08-0.01-0.14-0.04-0.18-0.09
      c-0.05-0.05-0.07-0.12-0.06-0.19l0.09-1.65H310.11z"
          fill="#FFFFFF"
        />
        <path
          d="M311.93,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
      c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
      c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
      C311.29,149.55,311.57,149.49,311.93,149.49z M311.92,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
      c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
      c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
      c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
      c-0.05-0.09-0.11-0.16-0.19-0.2S312.05,150.13,311.92,150.13z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M95.28,206.29l1-4.86h0.8l1,4.86h-0.73l-0.2-1.17h-0.92l-0.22,1.17H95.28z M96.32,204.6h0.74l-0.37-2.12
      L96.32,204.6z"
          fill="#FFFFFF"
        />
        <path d="M98.56,206.29v-4.86h0.78v4.31h1.21v0.55H98.56z" fill="#FFFFFF" />
        <path
          d="M101,206.29v-4.86h0.78v2.21l1.04-2.21h0.74l-0.94,2.15l1.07,2.71h-0.77l-0.87-2.24l-0.26,0.47v1.78H101z"
          fill="#FFFFFF"
        />
        <path d="M103.98,206.29v-0.77h0.71v0.77H103.98z" fill="#FFFFFF" />
        <path
          d="M107.97,206.29v-1.25h-1.43v-0.69l1.27-2.92h0.86v3h0.52v0.61h-0.52v1.25H107.97z M107.19,204.44h0.78
      v-2.16L107.19,204.44z"
          fill="#FFFFFF"
        />
        <path
          d="M109.64,207.09l-0.09-0.28c0.1-0.04,0.19-0.1,0.26-0.18c0.07-0.09,0.11-0.2,0.11-0.33h-0.38v-0.75h0.73
      v0.71c0,0.24-0.05,0.42-0.15,0.56C110.01,206.94,109.85,207.04,109.64,207.09z"
          fill="#FFFFFF"
        />
        <path d="M111,206.29l0.92-4.29h-1.31v-0.57h2.04v0.41l-0.94,4.45H111z" fill="#FFFFFF" />
        <path
          d="M115.53,204.06c-0.36,0-0.61-0.1-0.77-0.31c-0.15-0.21-0.23-0.49-0.23-0.86v-0.34
      c0-0.38,0.08-0.67,0.22-0.86c0.15-0.19,0.41-0.29,0.77-0.29c0.36,0,0.61,0.09,0.76,0.28s0.22,0.47,0.22,0.85v0.38
      c0,0.37-0.08,0.65-0.23,0.85C116.13,203.96,115.88,204.06,115.53,204.06z M115.53,203.54c0.1,0,0.18-0.03,0.23-0.09
      c0.05-0.06,0.08-0.14,0.1-0.23c0.02-0.1,0.02-0.21,0.02-0.33v-0.35c0-0.18-0.02-0.33-0.06-0.44c-0.04-0.11-0.14-0.17-0.29-0.17
      c-0.16,0-0.26,0.06-0.29,0.17c-0.04,0.12-0.05,0.26-0.05,0.44v0.35c0,0.12,0.01,0.23,0.02,0.32c0.02,0.1,0.05,0.17,0.1,0.23
      C115.35,203.51,115.42,203.54,115.53,203.54z M116.1,206.29l1.38-4.87h0.55l-1.37,4.87H116.1z M118.6,206.32
      c-0.36,0-0.61-0.1-0.76-0.31s-0.23-0.49-0.23-0.86v-0.34c0-0.38,0.07-0.66,0.22-0.85s0.4-0.29,0.77-0.29
      c0.36,0,0.62,0.09,0.77,0.28c0.15,0.19,0.22,0.47,0.22,0.84v0.37c0,0.38-0.08,0.66-0.23,0.86
      C119.21,206.22,118.96,206.32,118.6,206.32z M118.6,205.8c0.1,0,0.18-0.03,0.23-0.08s0.08-0.13,0.1-0.23
      c0.01-0.1,0.02-0.21,0.02-0.32v-0.37c0-0.18-0.02-0.33-0.06-0.44c-0.04-0.11-0.13-0.17-0.29-0.17c-0.16,0-0.26,0.06-0.29,0.17
      c-0.04,0.11-0.05,0.26-0.05,0.44v0.36c0,0.12,0.01,0.22,0.02,0.32c0.02,0.1,0.05,0.17,0.1,0.23S118.5,205.8,118.6,205.8z"
          fill="#FFFFFF"
        />
        <path d="M122.12,206.29v-4.28h-0.8v-0.58h2.36v0.58h-0.78v4.28H122.12z" fill="#FFFFFF" />
        <path d="M124.17,206.29v-4.86h0.77v4.86H124.17z" fill="#FFFFFF" />
        <path d="M125.71,206.29v-4.86h0.78v4.31h1.21v0.55H125.71z" fill="#FFFFFF" />
        <path d="M128.07,206.29v-0.77h0.71v0.77H128.07z" fill="#FFFFFF" />
        <path d="M130.61,206.29l1.35-4.86h0.55l-1.36,4.86H130.61z" fill="#FFFFFF" />
        <path d="M135.29,206.29l-1.03-4.86h0.71l0.7,3.51l0.67-3.51h0.71l-1.03,4.86H135.29z" fill="#FFFFFF" />
        <path
          d="M138.79,206.35c-0.34,0-0.61-0.06-0.81-0.19s-0.34-0.31-0.43-0.55c-0.09-0.24-0.13-0.52-0.13-0.84v-1.84
      c0-0.32,0.04-0.6,0.13-0.83s0.23-0.41,0.43-0.53s0.47-0.18,0.81-0.18c0.34,0,0.6,0.06,0.8,0.19c0.2,0.12,0.34,0.3,0.43,0.53
      s0.13,0.51,0.13,0.83v1.84c0,0.32-0.04,0.6-0.13,0.84c-0.09,0.24-0.23,0.42-0.43,0.55C139.39,206.29,139.12,206.35,138.79,206.35z
       M138.79,205.76c0.16,0,0.28-0.03,0.36-0.1c0.08-0.07,0.14-0.17,0.17-0.29c0.03-0.12,0.04-0.26,0.04-0.42v-2.18
      c0-0.16-0.01-0.3-0.04-0.42c-0.03-0.12-0.08-0.21-0.17-0.28c-0.08-0.07-0.2-0.1-0.36-0.1c-0.16,0-0.28,0.03-0.37,0.1
      c-0.08,0.07-0.14,0.16-0.17,0.28c-0.03,0.12-0.04,0.26-0.04,0.42v2.18c0,0.16,0.01,0.3,0.04,0.42c0.03,0.12,0.08,0.22,0.17,0.29
      C138.5,205.73,138.62,205.76,138.79,205.76z"
          fill="#FFFFFF"
        />
        <path d="M140.81,206.29v-4.86h0.78v4.31h1.21v0.55H140.81z" fill="#FFFFFF" />
      </g>
      <g>
        <path
          d="M67.69,207.04c-0.28,0-0.51-0.06-0.69-0.19c-0.18-0.12-0.32-0.29-0.41-0.51s-0.14-0.46-0.14-0.73v-2.12
      c0-0.28,0.04-0.53,0.13-0.75c0.09-0.21,0.22-0.38,0.4-0.5c0.18-0.12,0.42-0.18,0.7-0.18c0.29,0,0.52,0.06,0.7,0.18
      s0.31,0.29,0.4,0.5c0.09,0.21,0.13,0.46,0.13,0.75v2.12c0,0.28-0.04,0.53-0.14,0.74c-0.09,0.21-0.22,0.38-0.4,0.5
      C68.2,206.98,67.97,207.04,67.69,207.04z M67.69,206.41c0.14,0,0.24-0.04,0.31-0.12c0.07-0.08,0.11-0.19,0.14-0.31
      c0.02-0.12,0.04-0.25,0.04-0.37v-2.12c0-0.13-0.01-0.26-0.03-0.39c-0.02-0.13-0.07-0.23-0.13-0.31c-0.07-0.08-0.17-0.12-0.32-0.12
      c-0.14,0-0.25,0.04-0.31,0.12c-0.07,0.08-0.12,0.18-0.14,0.31c-0.02,0.13-0.04,0.26-0.04,0.39v2.12c0,0.12,0.01,0.25,0.04,0.37
      c0.03,0.12,0.08,0.23,0.15,0.31C67.46,206.37,67.56,206.41,67.69,206.41z"
          fill="#FFFFFF"
        />
        <path
          d="M69.63,207.77l-0.09-0.28c0.1-0.04,0.19-0.1,0.26-0.18c0.07-0.09,0.11-0.2,0.11-0.33h-0.38v-0.75h0.73
      v0.71c0,0.24-0.05,0.42-0.15,0.56C69.99,207.62,69.84,207.71,69.63,207.77z"
          fill="#FFFFFF"
        />
        <path
          d="M71.94,207.04c-0.28,0-0.52-0.05-0.7-0.16c-0.18-0.11-0.31-0.26-0.4-0.46c-0.09-0.2-0.13-0.43-0.13-0.7
      v-0.17h0.73c0,0.01,0,0.03,0,0.05s0,0.05,0,0.07c0,0.15,0.02,0.28,0.05,0.39c0.03,0.11,0.08,0.2,0.15,0.26
      c0.07,0.06,0.17,0.09,0.31,0.09c0.14,0,0.25-0.03,0.32-0.1c0.07-0.07,0.12-0.16,0.14-0.29s0.04-0.27,0.04-0.44
      c0-0.25-0.04-0.46-0.13-0.61c-0.09-0.15-0.25-0.24-0.47-0.25c-0.01,0-0.02-0.01-0.05-0.01c-0.02,0-0.05,0-0.07,0v-0.65
      c0.02,0,0.03,0,0.05,0c0.02,0,0.04,0,0.05,0c0.22,0,0.38-0.06,0.48-0.18c0.09-0.11,0.14-0.3,0.14-0.55c0-0.21-0.03-0.38-0.1-0.5
      c-0.07-0.12-0.2-0.18-0.4-0.18c-0.2,0-0.33,0.07-0.39,0.2c-0.06,0.14-0.1,0.31-0.11,0.53c0,0.02,0,0.04,0,0.07s0,0.05,0,0.07
      h-0.73v-0.19c0-0.27,0.05-0.5,0.14-0.69c0.09-0.19,0.23-0.33,0.41-0.43c0.18-0.1,0.41-0.15,0.67-0.15c0.27,0,0.5,0.05,0.68,0.15
      c0.18,0.1,0.32,0.24,0.41,0.43c0.09,0.19,0.14,0.42,0.14,0.68c0,0.29-0.06,0.52-0.19,0.7s-0.29,0.3-0.49,0.36
      c0.14,0.04,0.26,0.12,0.36,0.22s0.17,0.23,0.23,0.4c0.06,0.16,0.08,0.35,0.08,0.58c0,0.29-0.04,0.54-0.12,0.76
      s-0.21,0.39-0.4,0.51C72.47,206.98,72.23,207.04,71.94,207.04z"
          fill="#FFFFFF"
        />
        <path
          d="M74.86,207.04c-0.28,0-0.52-0.05-0.7-0.16c-0.18-0.11-0.31-0.26-0.4-0.46c-0.09-0.2-0.13-0.43-0.13-0.7
      v-0.17h0.73c0,0.01,0,0.03,0,0.05s0,0.05,0,0.07c0,0.15,0.02,0.28,0.05,0.39c0.03,0.11,0.08,0.2,0.15,0.26
      c0.07,0.06,0.17,0.09,0.31,0.09c0.14,0,0.25-0.03,0.32-0.1c0.07-0.07,0.12-0.16,0.14-0.29s0.04-0.27,0.04-0.44
      c0-0.25-0.04-0.46-0.13-0.61c-0.09-0.15-0.25-0.24-0.47-0.25c-0.01,0-0.02-0.01-0.05-0.01c-0.02,0-0.05,0-0.07,0v-0.65
      c0.02,0,0.03,0,0.05,0c0.02,0,0.04,0,0.05,0c0.22,0,0.38-0.06,0.48-0.18c0.09-0.11,0.14-0.3,0.14-0.55c0-0.21-0.03-0.38-0.1-0.5
      c-0.07-0.12-0.2-0.18-0.4-0.18c-0.2,0-0.33,0.07-0.39,0.2c-0.06,0.14-0.1,0.31-0.11,0.53c0,0.02,0,0.04,0,0.07s0,0.05,0,0.07
      h-0.73v-0.19c0-0.27,0.05-0.5,0.14-0.69c0.09-0.19,0.23-0.33,0.41-0.43c0.18-0.1,0.41-0.15,0.67-0.15c0.27,0,0.5,0.05,0.68,0.15
      c0.18,0.1,0.32,0.24,0.41,0.43c0.09,0.19,0.14,0.42,0.14,0.68c0,0.29-0.06,0.52-0.19,0.7s-0.29,0.3-0.49,0.36
      c0.14,0.04,0.26,0.12,0.36,0.22s0.17,0.23,0.23,0.4c0.06,0.16,0.08,0.35,0.08,0.58c0,0.29-0.04,0.54-0.12,0.76
      s-0.21,0.39-0.4,0.51C75.39,206.98,75.15,207.04,74.86,207.04z"
          fill="#FFFFFF"
        />
        <path d="M76.69,206.97v-4.86h0.78v4.31h1.21v0.55H76.69z" fill="#FFFFFF" />
      </g>
      <g>
        <path
          d="M5.97,206.09c-0.34,0-0.61-0.06-0.81-0.19c-0.2-0.13-0.34-0.31-0.43-0.55c-0.09-0.24-0.13-0.52-0.13-0.84
      v-1.84c0-0.32,0.04-0.6,0.13-0.83s0.23-0.41,0.43-0.53c0.2-0.12,0.47-0.18,0.81-0.18c0.34,0,0.6,0.06,0.8,0.19
      c0.2,0.12,0.34,0.3,0.43,0.53c0.09,0.23,0.13,0.51,0.13,0.83v1.84c0,0.32-0.04,0.6-0.13,0.84c-0.09,0.24-0.23,0.42-0.43,0.55
      C6.57,206.02,6.31,206.09,5.97,206.09z M5.97,205.49c0.16,0,0.28-0.03,0.36-0.1c0.08-0.07,0.14-0.17,0.17-0.29
      c0.03-0.12,0.04-0.26,0.04-0.42v-2.18c0-0.16-0.01-0.3-0.04-0.42c-0.03-0.12-0.08-0.21-0.17-0.28c-0.08-0.07-0.2-0.1-0.36-0.1
      s-0.28,0.03-0.37,0.1c-0.08,0.07-0.14,0.16-0.17,0.28c-0.03,0.12-0.04,0.26-0.04,0.42v2.18c0,0.16,0.01,0.3,0.04,0.42
      c0.03,0.12,0.08,0.22,0.17,0.29C5.68,205.46,5.81,205.49,5.97,205.49z"
          fill="#FFFFFF"
        />
        <path d="M7.99,206.03v-4.86h0.78v4.31h1.21v0.55H7.99z" fill="#FFFFFF" />
        <path
          d="M11.72,206.09c-0.36,0-0.64-0.07-0.83-0.2c-0.19-0.14-0.33-0.33-0.4-0.58c-0.07-0.25-0.11-0.54-0.11-0.88
      v-3.26h0.76v3.32c0,0.18,0.01,0.35,0.04,0.5c0.03,0.15,0.08,0.28,0.17,0.37c0.08,0.09,0.21,0.13,0.38,0.13
      c0.17,0,0.3-0.04,0.38-0.13c0.08-0.09,0.13-0.21,0.16-0.37c0.02-0.15,0.04-0.32,0.04-0.5v-3.32h0.75v3.26
      c0,0.34-0.04,0.63-0.11,0.88c-0.07,0.25-0.2,0.44-0.4,0.58C12.35,206.02,12.07,206.09,11.72,206.09z"
          fill="#FFFFFF"
        />
        <path d="M14.26,206.03v-4.28h-0.8v-0.58h2.36v0.58h-0.78v4.28H14.26z" fill="#FFFFFF" />
        <path d="M16.1,206.03l1.35-4.86h0.55l-1.36,4.86H16.1z" fill="#FFFFFF" />
        <path
          d="M19.86,206.09c-0.34,0-0.61-0.06-0.81-0.19c-0.2-0.13-0.34-0.31-0.43-0.55c-0.09-0.24-0.13-0.52-0.13-0.84
      v-1.84c0-0.32,0.04-0.6,0.13-0.83s0.23-0.41,0.43-0.53c0.2-0.12,0.47-0.18,0.81-0.18c0.34,0,0.6,0.06,0.8,0.19
      c0.2,0.12,0.34,0.3,0.43,0.53c0.09,0.23,0.13,0.51,0.13,0.83v1.84c0,0.32-0.04,0.6-0.13,0.84c-0.09,0.24-0.23,0.42-0.43,0.55
      C20.46,206.02,20.19,206.09,19.86,206.09z M18.97,200.5v-0.73h0.68v0.73H18.97z M19.86,205.49c0.16,0,0.28-0.03,0.36-0.1
      c0.08-0.07,0.14-0.17,0.17-0.29c0.03-0.12,0.04-0.26,0.04-0.42v-2.18c0-0.16-0.01-0.3-0.04-0.42c-0.03-0.12-0.08-0.21-0.17-0.28
      c-0.08-0.07-0.2-0.1-0.36-0.1s-0.28,0.03-0.37,0.1c-0.08,0.07-0.14,0.16-0.17,0.28c-0.03,0.12-0.04,0.26-0.04,0.42v2.18
      c0,0.16,0.01,0.3,0.04,0.42c0.03,0.12,0.08,0.22,0.17,0.29C19.57,205.46,19.69,205.49,19.86,205.49z M20.07,200.5v-0.73h0.68v0.73
      H20.07z"
          fill="#FFFFFF"
        />
        <path d="M21.88,206.03v-4.86h0.78v4.31h1.21v0.55H21.88z" fill="#FFFFFF" />
        <path d="M24.15,206.03l1.35-4.86h0.55l-1.36,4.86H24.15z" fill="#FFFFFF" />
        <path
          d="M26.62,206.03v-4.86h1.16c0.22,0,0.41,0.02,0.58,0.07s0.31,0.11,0.43,0.21s0.21,0.23,0.27,0.38
      c0.06,0.16,0.09,0.35,0.09,0.57c0,0.2-0.03,0.37-0.08,0.52c-0.05,0.14-0.13,0.26-0.23,0.34c-0.1,0.08-0.23,0.13-0.39,0.16
      c0.19,0.04,0.35,0.11,0.47,0.22s0.21,0.24,0.27,0.4c0.06,0.17,0.09,0.37,0.09,0.6c0,0.22-0.03,0.42-0.08,0.59
      c-0.05,0.17-0.13,0.32-0.24,0.44c-0.11,0.12-0.24,0.21-0.41,0.27c-0.16,0.06-0.36,0.1-0.58,0.1H26.62z M27.4,203.17h0.35
      c0.18,0,0.33-0.03,0.43-0.08c0.1-0.05,0.17-0.13,0.21-0.25c0.04-0.11,0.06-0.26,0.06-0.44c0-0.2-0.03-0.35-0.09-0.44
      c-0.06-0.1-0.16-0.16-0.29-0.19c-0.13-0.03-0.3-0.05-0.51-0.05H27.4V203.17z M27.4,205.48h0.4c0.29,0,0.48-0.07,0.58-0.21
      c0.1-0.14,0.15-0.35,0.15-0.65c0-0.2-0.03-0.37-0.08-0.5s-0.13-0.23-0.24-0.29c-0.11-0.06-0.26-0.1-0.45-0.1H27.4V205.48z"
          fill="#FFFFFF"
        />
        <path d="M29.87,206.03v-4.86h1.96v0.56h-1.18v1.51h0.95v0.55h-0.95v1.7h1.19v0.55H29.87z" fill="#FFFFFF" />
        <path d="M32.37,206.03v-4.86h1.96v0.56h-1.18v1.51h0.95v0.55h-0.95v1.7h1.19v0.55H32.37z" fill="#FFFFFF" />
        <path
          d="M34.87,206.03v-4.86h1.07c0.32,0,0.6,0.04,0.82,0.12c0.22,0.08,0.38,0.22,0.49,0.41
      c0.11,0.19,0.17,0.44,0.17,0.76c0,0.19-0.02,0.36-0.06,0.52c-0.04,0.15-0.1,0.28-0.19,0.39c-0.09,0.11-0.21,0.19-0.35,0.25
      l0.68,2.41h-0.77l-0.61-2.26h-0.47v2.26H34.87z M35.65,203.25h0.27c0.18,0,0.33-0.02,0.44-0.08c0.11-0.05,0.19-0.13,0.24-0.24
      c0.05-0.11,0.08-0.26,0.08-0.46c0-0.26-0.05-0.46-0.14-0.58s-0.29-0.19-0.57-0.19h-0.31V203.25z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g>
      <rect fill="#FFFFFF" height="109.47" width="76.23" x="323.33" y="100.15" />
      <g id="barcode_16_">
        <defs>
          <rect height="105.8" id="SVGID_1_" width="77.02" x="322.38" y="99.32" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" />
        </clipPath>
        <g clip-path="url(#SVGID_2_)" transform="matrix(1 3.469447e-18 0 1 0 0)">
          <image
            height="1295"
            id="_x36_419800023036_1_"
            transform="matrix(2.633640e-12 -0.0588 0.0595 2.675640e-12 322.3785 205.1233)"
            width="1800"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABwgAAAUPCAYAAACiCbQXAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAPQBJREFUeNrs2cENgCAQAEGx/57P
Gnwp7EwDGgS5ZK8LAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAC2sSwBL8zHe2x+sMcnfM7mg2cu7+8bHnJ+Z8Mzf9KMMPauO8j84O4xf2bmf3eo+cf7u7+snf+n
+9f5221+O21+tX98PzZxWwIAAAAAAADoEAgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAgIe9O9hx24YCKAoW+f9fdoEuCnQxje2IEsl7
DtBdm4z1SEryRVIAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAkOES8IHX
w2vsZY0DAOAZ/LZnYM/fAAB49r7v+dfzN7fyJwgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIOSXS7Ct1wf/7nC5rBVrZPm5mIHzlWdmaJ57z9DMzA+sX3Nyn+/M0Qydn9h7
7LV+rB1YnEB47ssPWDtmwvyZCL1n7Svz3G+OL/MyP7B+zch9PjNHM3R+Yu+x57s2sCiB0OEL2Nfm
MefX9dK0/54yz71m+TIr8wPr14zc55MzND/nJ/Ye+7xnAwsRCM88gN2gcVM3B56fh5ems/aUee4z
S7MyP7B+zcfsPHfj/DQje4/714y1AJsRCPc/gB28rPJSjRmYx8+/t7P6nD3lJXifWdp75gfWrxm5
z/eeu83P+WlG9h73rBfzhgMIhPsdwg5f8FDO3JmMCTP2wrT+PfNlpsvNc1zw65iT+YH16xn625/H
7Pado/k5P83I3uPZ92tgEzb2Hofw2PjnHwf9/ievM2fB8w/kZvD8TIb95jy7ce2Y53XX82VO5ufa
T7tep9znrN+zn6NnM7+952h+zk8zsvfY/zvpyrP3Sc/fbMLicQg7IK01Z8EaD+Nm8NxcBKUz9tY4
YB2Z5ffX0ZdM5mcGc67V7s/f1m/nWXo289t7hubn/LT37D3rxOx2ef71/Te3+sslWPZQsbFh/b3t
rxPtvsz+6a9j/Vw7w1n3zJl/RalZXjvHd/97czI/rF/rd+93ZT+r64LzE9eEeevEd9IQIxDOPYS/
+dMPDmE8IJ63tznvZfanX9O+vfcsu+Oe+claMc/v96P7pfmZH9avGfmZMTvnpxnae1xz3X0nDbxF
IFznBugQxkPXeQ9Y9F5mneX3nWV3X2uRcI/7kjmZH1i/ZuRndy3MzvlpTn5e62Pdd2tgIb9cgscP
YocweJjlrDmND3+el3vBxzMci8/WTJ+dpTmZH1i/ntlmf4b6+TPzC+iX2Tk/zdDe45b1YSaAQLjJ
TRpOf8l2XblrVneduyLh/jNk/jzHAnvQlxXmh/Vr/e7zfO1PGTWe24Z5Oj+dn/Ye0++vnqGBf/gr
Rh3EtF+ycV29JM19wbXunr2mT/4ML3vSc5D5gfXLJffdT/+/SOPL/85z27X7bFy8BszO+WlG9h7X
XFd7GfiXQOggBt7bo+M3/7DfTE+5pxTvoz6bz3vlvvdlhflh/Vq/a83o6mfsWc/rzp/7rvVOz/PO
T+envcfK+9c8gf8QCO89iIUEvJDu+ZA93vx3sQesi3kzHOZ61NnqPDE/88P67azfVe737t/z5njX
dx1X/T7uG85Pe8/eK91b71wrwGYEwnsPYpi9/qyzZ1+AXP81z+Bx8GfDPH1O57L5Yf1av6c8e48N
fx/3+WfWuv3l/DQje8+6MDfgAgKhg5izXqqBM/amSOh8O/m5yOfwuX0OzL2zft/5bL7k9oxmhs4d
56e9x5w1b17A/xIIHcR4AAZ7AHP0Yuy6OV/MD+vX+n1iJid8yX367E7922vcM5yf9p55nX7tvLMC
vyUQOojxgAheZtf8Obw4dZ8ffLYzzwHzMz+sX854RsP8nJ9ma++x+r41I+AtAuFZD1A016Cbvgd2
+wBwPjpnzM/8sH69vzy3Pkp/gmnY385P56e9Z+9Z38AZBMJ5B7EbJQBeBLzgWJ+YH1i/rM/7u+vk
/MTe45Q9a70AbxMIHcTsvQ6tNewDL3XA9fvPl2fmB9av5yHP3vvMzrpyXpx0ftp7/MmaNhPgIwKh
g5h916G1Bu5FYF1ifli/7DKn1d9fvF/9fD1cG+cn9h7r71frBfjYL5fAQQzgTAYmsx/ND6xfCs+r
w/7B/HHtueB+Ys0At/AnCK87iOHOtejGD60Xvfp9yX0Z6w7zw/ptPgP5OTE73P/tPWsYYBKB8LqD
2A0RDwUAuD+ty7Oa+WH9Wr+A89P5CaftU/sE+NrfArR3Z7ux3EYAQC8N/f8v0w824Cy+0sw0l1rO
AfISO5KGtbDYVCsuCA0syDcAYO0hHfGDzvk7k59fnLecq9E/UXvWE2jBBaEhgly5aONHPVgHnz/f
zzPEAfED+QugfwLqFIjEBeHzRuzCBkMBYN8AOS9+IH8RA1C7wLs8kwaucUFoYEK+AVQ/OIG8Ez+Q
v2fPLcP5BWdr9E/UnrUEYnNBaHAgRy7a+AGHGZ/VvCQe4of8lb+IF+ifoEYBlnBB6DVuDAWgLqyH
NRAHAIAOPMsBtZf9bGstgWVcEIIhCqDLQUr/BgAAAIBfLgj9pgbRc1GuQU1q+94envX70jPfED+Q
v2AGR/9Ue/LTWgJbeIMQDK0AWQ+Js/jns1chfiB/6TXbsK9XDGsCag+A/9b5gtBvauCACcCO2UH/
thbiB/IXQP8Enpwr1SawnTcIIeZQYNMHVh8mIlr1FuG07gAAOF+D2gPgdV0vCP2mBpnzEICzPfWd
r21WkGOIH8hfiFMjZjO5gdqrkJPWE9jCG4QQj00fHAb1vDgxdzkIAJgBxAVQewDldLwg9MCYyHlo
iALIuc8Pa5rGUBPih/yVv1Be93rTPxErdQnwI28Q2uAwEABU22Pn4a9jRgAAiHO+NpuB2nNeBnhB
twtCFzPY8AF69MD5cN93OQgAffkT43njJB6g9rLvKwDHfFkChwtC5IK4A/zVC1cfnOabPdbFIABA
3lkSUHu8f74VR2iq0wXhkweO4+D3mpqzHARgef8dL/57Dk4AAPnO1+YzUHtZ1uwdnkkDW3mDMEaz
f+Xrasp1iS3s7c1qLF9PnBdyweUgAOAsl2fGFwdQe7x3lv3064ozFPaHJXjcKJ/+fxy9+72otVnb
ZOF7akTcT+yl88WfST6q3yl+4of8lb8QqhbMZ/qn/qn2usbDM2ngsS4XhKtf5b7ZGDVlwyhABzsP
nPPNHt358GsPEz+Qv8g984G+YJ2QU5xfO8+kge38idG8G6I/m5ef+AH83Cd3/7kUfRoAIL75w39v
bgO11zEOt34WcYci/InRnI048s/EazGykYJeyWtGs+8LAJhPeH+e92YLqL3OcfAzAR/p8Abh01e5
55v//unm6bc2bOAA1e18k/DdvRwAcIYjbjy81QRqr8Ke4pk0cIQ/Mfq8eb7TCMemxqwh5yJWsHcw
1idr98554HsAAJgX7pmLvoZYgdqrHAvPpIHH/InRZ4eCcfF/v2MTZ388bJqAnv18/xUXAMAcUDMG
U0xB7fHjmdgzaWAJF4T3m6iG7GAJqE3u7Z3yBACIMIN0n9Nnwq8Nak/tnd6DPJMGlnJBeK8Ja8gO
k4D6sRYx18FhFgAwg+01D89cZjtQe5n3H8+kgS2qXxCuaEw7m7DDhpwD1Chx9075AgBmRNau881f
xBJn1J7ay/SZPZMGtvMG4f0mvKMhG3rjbf42WzC0kjMv7KkAgDn02SwV6a8zmO1Qe2ovw57jmTRw
hAvCugcPDdlhEtAfkTMAwLN93nkOgA48k4aGvixB2IasmdY6VAKx+uP85WGP/itnAMAcwYn5PVoM
zHWoPbXH7/PG3giNeIPQYYe962zwgfMHncg9MtKfeLHPiR8AcGbu5Ps1vvHn9Mx0qD21hzhDey4I
HUTYt+GJIRhaV38vfeWftbzZfx12AKD+HGjuOu/WhQWoPbWH/Q9aqnxB6OGddQAMrBH6pLfOzu5r
438OufZXAICc875f+gK1V/HM6uwOhOENwvibMjk3OrGDXD1yx+DqYvD8AWMc7sfiCwB9Zgruzfzi
AWqP8/EHGnBBqAmy/kApfyBnv15xoTd/uRi80Xd/ygWXhADAipmCu3P/uJAPoPbU3un1BjjGBWGP
jcGmC+iT7/XMVy/55pv//gj0ObN4ZW3Hi+s3Nv6MAIC5EjECtYeYOytDGl+WAJZubgYmuDe4zsP1
/slgbThev/4jUK7YAwDAWY4zs7/ZGtQeAA95gxAcKKHaYcXPVb/fPl1bbxICgNkC87/cALUH0JoL
wl4bL0CHfjkK/izd9oF5YE1cEqIWxQ/kL9ZX7JAjWFfEGdpyQQiv8/YgGGJf+b6/uxh0cfS81+6K
V+bP4ECI+CF/wVlOv8B6INcAeJsLQgciHCih8mFlFPxe9qr1ay12AOAsBwDcO+cDF7ggzMPBBeDz
/rnjAm/X19X/76zH6q/rEOSAiviB/I295mYsM6L61D9Re9w7gwMBfFkCcKiExoPtqTfWHHpyrNFY
/HNM+wQApJgJAQCgHReE8L358J9X+ZwO11Q1/HypetA4tOYudAGg7nzh/FJ7tjfHgdoD4EX+xCgA
cFPEg+Qo/vk6xxbxQ/4iBwC1AwD8ckEIAFEP2X67/e56WH8AqDd72d/NjIDaA+BvLggBgFui/4by
aPI5HfZrr4P4WQdxsw5mC+uIvmEdAID/44LQYAPAPt4etPcCAPZzxBvUHmILhFP5glDjAoC43rk8
vb2nj8OfFwBYt9d6NgBAtvMjwBFfloBEG5wNFsjE24P19kCXfEC3GdieRPa5Sw4DAJnmX7MLR/kT
owDAaVkv2gzqAJBnrrBvAwDAN1wQAsB63h6sa/jcalP8xA/5K3/Dr6t1Qw7on3JA3gHwAxeEa4cP
AMAB03xhphI/kL/ci7GH06B/gvoDeIELwj4ckgBiD/T6tD0VAHg2b9mjMd+B2kOMgRd9NWhAfvOC
bpvYbPI5Qa/JaRaK2WyYp1N9ip/4IX/lb9BZwnqB/qkfoPYA3uANQgBYxwEAAOD8rOUyAAAA3uSC
EADu8kBL7ACAn7kcBACAhTpcEH56WJjWAIDN+4b+TIeZYqpR8RM/5K/83bSG1gn0T32BSrUXqf5u
rgFwiDcIAcDBFQAg25xllmIVuQRqD6AlF4QAAA61ABCRy0EAANikywWhwwMAu3h7kG6Gz+lz+ZyI
q895ccayPqCv6J/ISYAFvEH42YHERgTAp/uEvgwA8NmMZY5i9Zwup0DtZV7HyMQYEuh0QVihKU0p
C5C6JxuQexuNP8tMVq9DLoof8lf+hluzkfSzTGd59E/9E7VXdG8GkvMGoQ0IAD058jo4iABAH9Uu
BzGfA2pPjIGwXBAa6gHYvzcYjnHo87l8Tp8LcZa/n81XFS4H9Sb0GZ8LVu2LAMt0uyAcPisAh4d0
/RgccMUP5C9iRKx8MqOrTdTeSZ5JAyF1fIPwkyYVYSAylAHk68UGY+tS/TOPYnU75KX4IX/lb6h1
8vYg6J/6J51rzzNpYCt/YtSGA8CeoVgv7nEgcVgCgD376Ej4WcwFOWYzczqoPfYQY0im6wVh1t/Y
ACDuYcdQTNd8GBtr6WTt+nNH4of8lb9x1mgU+izEioP9Qv9E7WU7G9pjgG28QVjvsGHDBbjXg/Xh
fevkUAQA9eerkWyW+umtQXMhABX3a3seFNH5grDib2xoxAD3hmF9WH50zomIv4XuMCt+yF/5m2v/
zPL/i/af/8Fcpn/qn2pPrHbW3ukaPPmZgAC+NOTwDdahAyB+/zUMn9mHp7U2VznMip/4IX/LzlfO
vuyc2+0X+idqT+2tjy+QnD8xGvtPnHk4DRB/ENZ/6+7DDsN5D5sOs+IH8rff2mefcaLH7t/+YyZT
w3qE2lN7Nc/CnolAEy4I4w4tGjFA/B6v/9Y/GDkM742ln9d6iB/y13xF7LjNF/75DJxX9gr9U+2p
Pfvpvu8hzmAIaN1gR4CfRwyJnsvWXxwqxk+s+vYuh+H4taSOxQ/5K3/rzMdZjeZxG0F+PnuF/qn2
1J6zsHoEFHK6hqwRU2mwsP7iUC1u4tS3fzkMx59nzFDiB/K3zmyc2RC3beug1vRP66/2xF6cAUNr
yYbsATWVhgrrLw4OwVTqYS4H49eXWhY/kL915uLsXBCuXxPPS/RPa6/2xH/fmoszGFq53JA1YioM
FNZeX64aL3HJUUt+gzJ3PIdaFj/xQ/6aiwsY4rdsjWbDtdc/e8dV7eGZNGBwbdaQbbpUGCasuf5c
NV7ikLO2hjxJG8shTuInfshfs3FyLgitu/6pf6o9TueBZ9KAJh5scx4bvq54EWmQsM56deWYWfM6
tTY2fx+5cv4A6iArfiB/zciRDbG03vqn/qn2uJgLnkkDirvY5ixO7M5XOZZ3MBe7Mwda1KJcyRlL
cRI/kL9mZ3GvGU97hP5pvdWefBBvQJEbimBBnsq1nIO52KkBsVVzYilO4gfy1z5tNu8UU3uE/mmt
1Z6cEG9AsZduyOLC6RyVc3mGcrFbFyN5rzblS704ipP4gfw1O5vN68VWfemf1lvt4Zk0oOhLN2ax
ANjbb/VZuSFn6sZRrMQP5C/knrvUlv6J2uNOTog5NKYB3GvO1h5g3/Crx8oXc1Ht+ImV+IH8hbwz
l5rSP1F73MsJsQcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvvUnd88g6Uucl5sAAAAASUVORK5CYII="
          />
        </g>
      </g>
    </g>
    <path
      d="M140.52,69.44c-0.08-0.98-0.19-1.96-0.32-2.93c-0.02-0.14-0.04-0.29-0.06-0.43l0.09,0.06
  c0.95-1.14,1.86-2.32,2.75-3.55c0,0,0,0-0.01,0c-0.18-1.01-0.39-2.02-0.62-3.03c-0.23-1.01-0.48-2.01-0.75-3
  c-0.27-0.99-0.57-1.98-0.88-2.97c-0.32-0.98-0.65-1.96-1.01-2.92l-0.14-0.36c-0.05-0.12-0.09-0.24-0.14-0.36l-0.28-0.72l-0.29-0.72
  c-0.1-0.24-0.2-0.48-0.3-0.71c-0.4-0.95-0.82-1.89-1.26-2.82c-0.44-0.93-0.9-1.85-1.38-2.77c-0.48-0.91-0.98-1.81-1.5-2.7
  c-0.52-0.89-1.06-1.77-1.62-2.64c-0.14-0.22-0.28-0.43-0.42-0.65l-0.43-0.64l-0.44-0.64c-0.07-0.11-0.15-0.21-0.22-0.32l-0.22-0.32
  c-1.19-1.68-2.45-3.31-3.78-4.89c-2.66-3.14-5.6-6.06-8.76-8.7c-3.16-2.64-6.56-5.01-10.13-7.07c-1.79-1.03-3.62-1.98-5.49-2.85
  c-0.93-0.44-1.88-0.85-2.83-1.24c-0.95-0.39-1.91-0.77-2.88-1.12c-1.94-0.7-3.91-1.32-5.9-1.85c-1.99-0.53-4.01-0.97-6.04-1.33
  c-2.03-0.36-4.08-0.62-6.13-0.79l-0.39-0.03l-0.19-0.02c-0.06-0.01-0.13-0.01-0.19-0.01L77.6,4.36l-0.77-0.04L76.64,4.3l-0.19-0.01
  l-0.39-0.02c-0.13-0.01-0.26-0.01-0.39-0.02l-0.39-0.01L74.9,4.24c-0.13,0-0.26-0.01-0.39-0.01l-0.11,0V4.21l-0.02,0l-0.05,0l-0.1,0
  l-0.19,0l-0.39,0c-0.03,0-0.05,0-0.08,0l-0.22,0l-0.19,0l-0.1,0l-0.05,0l-0.02,0v0.01l-0.11,0c-0.13,0-0.26,0-0.39,0.01l-0.39,0.01
  l-0.39,0.01c-0.13,0-0.26,0.01-0.39,0.02l-0.39,0.02L70.76,4.3l-0.19,0.01L69.8,4.36l-0.77,0.05c-0.06,0-0.13,0.01-0.19,0.01
  l-0.19,0.02l-0.39,0.03c-2.05,0.18-4.1,0.44-6.13,0.79c-2.03,0.35-4.05,0.79-6.04,1.33c-1.99,0.53-3.96,1.15-5.9,1.85
  c-0.97,0.35-1.93,0.72-2.88,1.12c-0.95,0.39-1.9,0.81-2.83,1.24c-1.87,0.87-3.7,1.82-5.49,2.85c-3.57,2.06-6.97,4.42-10.13,7.07
  c-3.17,2.64-6.1,5.56-8.76,8.7c-1.33,1.57-2.59,3.21-3.78,4.89l-0.22,0.32c-0.07,0.11-0.15,0.21-0.22,0.32l-0.44,0.64l-0.43,0.64
  c-0.14,0.22-0.28,0.43-0.42,0.65c-0.56,0.87-1.1,1.75-1.62,2.64c-0.52,0.89-1.02,1.79-1.5,2.7c-0.48,0.91-0.94,1.83-1.38,2.77
  C9.62,45.91,9.2,46.85,8.8,47.8c-0.1,0.24-0.2,0.48-0.3,0.71l-0.29,0.72l-0.28,0.72c-0.05,0.12-0.09,0.24-0.14,0.36l-0.14,0.36
  c-0.36,0.97-0.69,1.94-1.01,2.92c-0.32,0.98-0.61,1.97-0.88,2.97c-0.27,0.99-0.52,2-0.75,3c-0.23,1.01-0.44,2.02-0.62,3.03
  l0.07,0.01c0,0,0,0,0,0c0.89,1.23,1.81,2.41,2.75,3.55l0,0c-0.02,0.12-0.03,0.24-0.05,0.36c-0.13,0.97-0.23,1.95-0.32,2.93
  c-0.09,0.98-0.15,1.96-0.19,2.94c-0.04,0.98-0.06,1.96-0.06,2.95l0.82,0c0-0.97,0.02-1.94,0.06-2.91c0.04-0.97,0.1-1.94,0.19-2.91
  c0.08-0.97,0.19-1.93,0.31-2.89c0.02-0.14,0.04-0.28,0.06-0.42l0.07,0.08c1.21-0.83,2.44-1.61,3.68-2.34c0,0,0,0,0,0
  c0.16-0.91,0.35-1.81,0.55-2.71c0.2-0.9,0.43-1.79,0.67-2.68c0.24-0.89,0.51-1.77,0.79-2.65c0.28-0.88,0.58-1.75,0.9-2.61l0.12-0.32
  c0.04-0.11,0.08-0.22,0.12-0.32l0.25-0.64l0.26-0.64c0.09-0.21,0.18-0.43,0.26-0.64c0.36-0.85,0.73-1.69,1.13-2.52
  c0.39-0.83,0.81-1.66,1.24-2.47c0.43-0.82,0.88-1.62,1.34-2.42c0.47-0.8,0.95-1.58,1.45-2.36c0.13-0.19,0.25-0.39,0.38-0.58
  l0.38-0.58l0.39-0.57c0.06-0.1,0.13-0.19,0.2-0.28l0.2-0.28c1.07-1.51,2.2-2.96,3.39-4.37c2.38-2.81,5.01-5.43,7.84-7.79
  c2.83-2.37,5.87-4.48,9.06-6.32c1.6-0.92,3.24-1.77,4.91-2.55c0.84-0.39,1.68-0.76,2.53-1.11c0.85-0.35,1.71-0.68,2.58-1
  c1.73-0.63,3.49-1.18,5.27-1.65c1.78-0.48,3.58-0.87,5.4-1.19c1.82-0.32,3.65-0.55,5.48-0.71l0.34-0.03l0.17-0.01
  c0.06,0,0.11-0.01,0.17-0.01l0.69-0.05l0.69-0.04l0.17-0.01l0.17-0.01l0.35-0.02c0.12,0,0.23-0.01,0.35-0.01l0.35-0.01l0.35-0.01
  c0.12,0,0.23,0,0.35-0.01l0.35-0.01l0.17,0c0.01,0,0.02,0,0.02,0l0.08,0c0.06,0,0.12,0,0.17,0l0.17,0l0.35,0.01
  c0.12,0,0.23,0,0.35,0.01l0.35,0.01l0.35,0.01c0.12,0,0.23,0.01,0.35,0.01l0.35,0.02l0.17,0.01l0.17,0.01l0.69,0.04l0.69,0.05
  c0.06,0,0.11,0.01,0.17,0.01l0.17,0.01l0.34,0.03c1.84,0.16,3.67,0.39,5.48,0.71c1.82,0.32,3.62,0.71,5.4,1.19
  c1.78,0.47,3.54,1.02,5.27,1.65c0.87,0.31,1.73,0.65,2.58,1c0.85,0.35,1.7,0.72,2.53,1.11c1.67,0.78,3.31,1.63,4.91,2.55
  c3.19,1.84,6.23,3.96,9.06,6.32c2.83,2.36,5.46,4.97,7.84,7.79c1.19,1.41,2.32,2.87,3.39,4.37l0.2,0.28
  c0.07,0.09,0.13,0.19,0.2,0.28l0.39,0.57l0.38,0.58c0.13,0.19,0.25,0.39,0.38,0.58c0.5,0.78,0.98,1.56,1.45,2.36
  c0.47,0.8,0.91,1.6,1.34,2.42c0.43,0.82,0.84,1.64,1.24,2.47c0.39,0.83,0.77,1.67,1.13,2.52c0.09,0.21,0.18,0.43,0.26,0.64
  l0.26,0.64l0.25,0.64c0.04,0.11,0.08,0.22,0.12,0.32l0.12,0.32c0.32,0.86,0.62,1.74,0.9,2.61c0.28,0.88,0.55,1.76,0.79,2.65
  c0.24,0.89,0.47,1.78,0.67,2.68c0.2,0.9,0.39,1.8,0.55,2.71l0.07-0.01c0,0,0,0,0,0c1.24,0.73,2.47,1.51,3.68,2.34l0,0
  c0.02,0.12,0.03,0.24,0.05,0.35c0.12,0.96,0.23,1.93,0.31,2.89c0.08,0.97,0.15,1.94,0.19,2.9s0.06,1.94,0.06,2.91l0.82,0
  c0-0.98-0.02-1.96-0.06-2.95C140.67,71.4,140.61,70.42,140.52,69.44z M135.68,60.33c0,0.01-0.01,0.01-0.01,0.02
  c-0.16-0.66-0.31-1.31-0.49-1.96c1,0.48,2,0.99,2.99,1.54c0.16,0.68,0.31,1.37,0.45,2.06C137.64,61.41,136.66,60.85,135.68,60.33z
   M139.96,53.85c0.21,0.67,0.4,1.34,0.6,2.01c-0.59,1-1.2,1.98-1.83,2.93c-0.08-0.31-0.15-0.63-0.23-0.94
  c-0.1-0.37-0.21-0.73-0.32-1.1c0.6-0.98,1.18-1.99,1.74-3.02C139.93,53.77,139.95,53.81,139.96,53.85z M140.86,56.9
  c0.19,0.72,0.37,1.44,0.54,2.16c-0.64,0.98-1.3,1.92-1.97,2.84c-0.14-0.69-0.29-1.38-0.45-2.07
  C139.63,58.88,140.25,57.9,140.86,56.9z M141.82,62.84c-0.06,0.08-0.11,0.15-0.17,0.23c-0.22,0.3-0.45,0.6-0.68,0.89
  c-0.23,0.3-0.46,0.59-0.69,0.88c-0.09,0.11-0.18,0.23-0.27,0.34L140,65.05l-0.12-0.73l-0.03-0.18l-0.03-0.18l-0.06-0.36
  c-0.04-0.22-0.09-0.44-0.13-0.66c0.69-0.92,1.36-1.86,2.01-2.83c0.17,0.77,0.33,1.55,0.47,2.33l-0.13,0.18L141.82,62.84z
   M139.57,52.71c-0.54,1.03-1.11,2.03-1.69,3.01c-0.07-0.23-0.13-0.47-0.2-0.7c-0.07-0.23-0.15-0.47-0.22-0.7l-0.21-0.62
  c0.55-1,1.08-2.03,1.59-3.08l0.13,0.35C139.18,51.54,139.37,52.13,139.57,52.71z M138.42,49.53l0.02,0.06
  c-0.49,1.05-1.01,2.08-1.55,3.09c-0.01-0.04-0.03-0.07-0.04-0.11l-0.12-0.35c-0.18-0.51-0.39-1-0.58-1.51
  c0.51-1.03,0.98-2.09,1.44-3.16c0.08,0.19,0.17,0.37,0.25,0.56c0.1,0.24,0.2,0.47,0.29,0.71L138.42,49.53z M137.15,46.57
  c-0.44,1.07-0.91,2.12-1.39,3.14c-0.03-0.08-0.06-0.16-0.09-0.24c-0.24-0.57-0.49-1.13-0.74-1.7c0.46-1.05,0.88-2.13,1.29-3.22
  c0.12,0.26,0.26,0.5,0.38,0.76C136.79,45.73,136.96,46.15,137.15,46.57z M135.73,43.59c-0.39,1.09-0.8,2.15-1.24,3.2
  c0,0,0-0.01,0-0.01c-0.29-0.63-0.61-1.25-0.92-1.88c0.41-1.07,0.78-2.16,1.14-3.27c0.18,0.32,0.36,0.63,0.53,0.96
  C135.4,42.92,135.56,43.25,135.73,43.59z M134.16,40.68c-0.34,1.11-0.7,2.19-1.09,3.26c-0.33-0.62-0.67-1.24-1.01-1.85
  c0.35-1.09,0.68-2.2,0.98-3.32c0.23,0.38,0.48,0.76,0.7,1.14C133.89,40.17,134.02,40.42,134.16,40.68z M131.73,36.67
  c0.14,0.21,0.28,0.43,0.42,0.64c0.11,0.17,0.21,0.35,0.33,0.53c-0.28,1.13-0.6,2.23-0.94,3.32c-0.36-0.61-0.72-1.21-1.1-1.81
  c0.3-1.11,0.58-2.24,0.83-3.38l0.04,0.06L131.73,36.67z M130.64,35.08c-0.23,1.14-0.5,2.27-0.79,3.38
  c-0.39-0.59-0.77-1.18-1.18-1.76c0.25-1.11,0.47-2.24,0.66-3.39c0.37,0.49,0.74,0.97,1.09,1.47L130.64,35.08z M124,35.96
  c-0.41-0.53-0.84-1.05-1.27-1.57c1.12,0.03,2.25,0.09,3.39,0.18c0.43,0.55,0.83,1.12,1.24,1.69C126.23,36.13,125.11,36.02,124,35.96
  L124,35.96z M120.08,31.36c-0.46-0.49-0.93-0.98-1.41-1.46c1.11-0.07,2.24-0.12,3.37-0.14c0.48,0.51,0.94,1.04,1.4,1.56
  c-1.13-0.02-2.26-0.02-3.37,0.02L120.08,31.36z M121.96,28.5c-0.49-0.51-0.98-1.01-1.48-1.51c0.04-1.15,0.04-2.31,0.02-3.48
  c0.55,0.5,1.08,1.01,1.61,1.53C122.09,26.21,122.04,27.36,121.96,28.5z M127.31,30.71c0.47,0.57,0.93,1.13,1.38,1.71
  c-0.18,1.15-0.39,2.29-0.63,3.41c-0.41-0.58-0.83-1.15-1.26-1.71C127,33,127.16,31.86,127.31,30.71z M126.14,33.29
  c-0.37-0.47-0.73-0.94-1.12-1.39c-0.07-0.09-0.15-0.17-0.22-0.25c0.15-1.13,0.26-2.28,0.34-3.44c0.49,0.54,0.98,1.09,1.46,1.65
  C126.49,31.02,126.32,32.16,126.14,33.29z M124.41,27.4c-0.07,1.16-0.18,2.31-0.31,3.45c-0.46-0.53-0.93-1.06-1.41-1.58
  c0.09-1.14,0.15-2.3,0.18-3.46C123.4,26.33,123.91,26.86,124.41,27.4z M119.69,22.79c0.04,1.16,0.04,2.31,0.02,3.46
  c-0.51-0.49-1.03-0.96-1.55-1.43c-0.01-1.15-0.07-2.31-0.14-3.48c0,0,0.01,0.01,0.01,0.01C118.59,21.82,119.14,22.31,119.69,22.79z
   M115.74,22.75c-0.07-1.15-0.17-2.31-0.3-3.47c0.59,0.45,1.17,0.91,1.74,1.38c0.09,1.16,0.15,2.3,0.18,3.44
  c-0.21-0.18-0.41-0.37-0.63-0.55C116.4,23.28,116.07,23.02,115.74,22.75z M114.9,22.08c-0.55-0.43-1.11-0.86-1.68-1.27
  c-0.12-1.14-0.28-2.29-0.46-3.44c0.61,0.42,1.21,0.85,1.8,1.29C114.71,19.8,114.82,20.94,114.9,22.08z M112.36,20.16
  c-0.57-0.41-1.15-0.8-1.74-1.19c-0.17-1.13-0.39-2.27-0.62-3.41c0.63,0.39,1.25,0.79,1.86,1.2
  C112.06,17.9,112.22,19.03,112.36,20.16z M111.83,20.79c-1.11,0.24-2.21,0.5-3.28,0.8c0,0.01,0,0.02,0,0.02
  c-0.57-0.38-1.14-0.74-1.71-1.1c1.07-0.33,2.15-0.64,3.25-0.92C110.68,19.99,111.26,20.38,111.83,20.79z M112.66,21.41
  c0.57,0.42,1.13,0.84,1.68,1.27c-1.12,0.19-2.23,0.4-3.31,0.65c0,0.01,0,0.02,0,0.02c-0.55-0.4-1.1-0.8-1.66-1.18
  C110.46,21.89,111.55,21.63,112.66,21.41z M115.15,23.33c0.35,0.28,0.71,0.56,1.05,0.85c0.19,0.16,0.38,0.34,0.57,0.5
  c-1.12,0.14-2.24,0.29-3.34,0.49c0,0.01,0,0.02,0,0.02c-0.53-0.43-1.05-0.85-1.59-1.26C112.93,23.71,114.03,23.5,115.15,23.33z
   M109.06,14.99c0.25,1.13,0.47,2.25,0.66,3.37c-0.59-0.38-1.19-0.74-1.79-1.1c-0.23-1.12-0.49-2.25-0.78-3.38
  c0.29,0.16,0.58,0.31,0.87,0.47C108.37,14.56,108.71,14.78,109.06,14.99z M107.01,16.7c-0.61-0.35-1.22-0.69-1.84-1.02
  c-0.28-1.11-0.6-2.22-0.94-3.33c0.66,0.33,1.32,0.66,1.96,1.01C106.5,14.48,106.77,15.59,107.01,16.7z M104.71,16.36
  c0.62,0.33,1.24,0.67,1.85,1.03c-1.08,0.35-2.15,0.71-3.2,1.11l0,0.02c-0.6-0.32-1.2-0.63-1.81-0.93
  C102.58,17.15,103.63,16.74,104.71,16.36z M103.25,11.87c0.36,1.1,0.68,2.2,0.97,3.31c-0.62-0.32-1.25-0.64-1.89-0.94
  c-0.33-1.09-0.7-2.19-1.1-3.28c0.45,0.2,0.91,0.39,1.35,0.59C102.81,11.65,103.03,11.76,103.25,11.87z M100.23,10.51
  c0.41,1.09,0.78,2.18,1.13,3.26c-0.64-0.3-1.28-0.59-1.93-0.86c-0.38-1.07-0.8-2.15-1.25-3.22c0.54,0.21,1.08,0.4,1.61,0.62
  C99.94,10.38,100.08,10.45,100.23,10.51z M97.16,9.3c0.46,1.06,0.88,2.13,1.28,3.2l-0.59-0.24l-0.69-0.27l-0.09-0.03l-0.09-0.03
  l-0.17-0.06l-0.35-0.13c0,0,0,0,0,0c-0.43-1.05-0.9-2.1-1.4-3.15c0.62,0.21,1.25,0.41,1.87,0.63C97.01,9.24,97.08,9.27,97.16,9.3z
   M90.2,9.43l-0.13-0.25l-0.13-0.24l-0.27-0.49l-0.14-0.24l-0.07-0.12l-0.07-0.12c-0.18-0.32-0.37-0.65-0.56-0.97
  c-0.04-0.06-0.07-0.12-0.11-0.18c0.71,0.16,1.43,0.33,2.14,0.52c0.56,1.02,1.09,2.05,1.59,3.08c-0.53-0.16-1.05-0.33-1.59-0.47
  c-0.15-0.04-0.3-0.07-0.45-0.11L90.2,9.43z M93.46,10.71c-0.48-1.03-1-2.06-1.55-3.09c0.71,0.2,1.41,0.39,2.11,0.61
  c0.52,1.05,0.99,2.1,1.44,3.16c-0.59-0.21-1.19-0.42-1.79-0.62C93.61,10.75,93.53,10.73,93.46,10.71z M89.62,10.92l0.23-0.15
  l0.26-0.17c0.18,0.05,0.35,0.08,0.53,0.13c0.5,0.14,1,0.29,1.5,0.44c-0.97,0.59-1.92,1.19-2.84,1.81c0,0,0,0.01,0,0.01
  c-0.66-0.17-1.31-0.33-1.98-0.48c0.09-0.07,0.18-0.13,0.27-0.2c0.29-0.21,0.59-0.42,0.89-0.63l0.11-0.08l0.11-0.08l0.23-0.15
  l0.45-0.31L89.62,10.92z M93.14,11.47c0.1,0.03,0.19,0.06,0.29,0.09c0.57,0.18,1.13,0.39,1.7,0.58c-0.99,0.54-1.97,1.1-2.92,1.68
  c0,0,0,0,0,0.01c-0.64-0.2-1.29-0.38-1.94-0.56C91.21,12.64,92.16,12.04,93.14,11.47z M57.57,10.84l0.23,0.15l0.23,0.15l0.45,0.31
  l0.23,0.15l0.11,0.08l0.11,0.08c0.3,0.21,0.59,0.42,0.89,0.63c0.06,0.05,0.12,0.09,0.19,0.14c-0.66,0.15-1.31,0.31-1.97,0.48
  c-0.92-0.63-1.87-1.23-2.84-1.82c0.52-0.16,1.04-0.32,1.56-0.46c0.15-0.04,0.31-0.07,0.46-0.11L57.57,10.84z M53.72,10.77
  c-0.58,0.19-1.15,0.39-1.72,0.59c0.45-1.06,0.93-2.11,1.44-3.16c0,0,0,0,0,0c0.7-0.22,1.41-0.42,2.12-0.61
  c-0.55,1.03-1.07,2.06-1.55,3.09C53.91,10.72,53.81,10.74,53.72,10.77z M53.97,11.55c0.07-0.02,0.15-0.04,0.22-0.07
  c0.98,0.57,1.94,1.18,2.87,1.79c-0.65,0.18-1.3,0.36-1.94,0.56c-0.95-0.58-1.93-1.14-2.92-1.68C52.79,11.95,53.37,11.74,53.97,11.55
  z M57.96,8.16l-0.07,0.12l-0.14,0.24l-0.27,0.49l-0.13,0.24l-0.13,0.25l-0.16,0.3c-0.17,0.05-0.35,0.08-0.52,0.13
  c-0.51,0.14-1.01,0.3-1.52,0.45c0.5-1.03,1.02-2.06,1.59-3.08c0.71-0.19,1.42-0.36,2.14-0.52c-0.05,0.09-0.11,0.18-0.17,0.28
  c-0.19,0.33-0.38,0.65-0.56,0.97L57.96,8.16z M52.4,8.56c-0.5,1.05-0.97,2.1-1.4,3.15c-0.02,0.01-0.05,0.02-0.07,0.02l-0.35,0.13
  l-0.17,0.06l-0.09,0.03l-0.09,0.03l-0.69,0.27l-0.53,0.21c0.4-1.07,0.82-2.14,1.28-3.2c0.05-0.02,0.1-0.04,0.15-0.06
  C51.1,8.98,51.75,8.77,52.4,8.56z M47.61,10.32c0.55-0.23,1.12-0.43,1.68-0.65c-0.45,1.07-0.87,2.14-1.25,3.21
  c-0.65,0.28-1.3,0.57-1.94,0.86c0.35-1.09,0.72-2.18,1.13-3.27C47.36,10.43,47.48,10.37,47.61,10.32z M44.81,11.54
  c0.47-0.22,0.95-0.42,1.42-0.62c-0.4,1.09-0.76,2.18-1.1,3.28c-0.64,0.3-1.27,0.62-1.89,0.94c0.3-1.1,0.62-2.2,0.98-3.31
  C44.42,11.74,44.61,11.64,44.81,11.54z M42.3,15.64c-0.62,0.33-1.24,0.67-1.84,1.02c0.24-1.11,0.51-2.22,0.82-3.34
  c0.65-0.35,1.31-0.68,1.97-1.01C42.89,13.43,42.57,14.54,42.3,15.64z M42.63,16.39c1.07,0.38,2.12,0.79,3.15,1.22
  c-0.6,0.3-1.2,0.6-1.79,0.92c-1.05-0.4-2.13-0.76-3.22-1.11C41.39,17.06,42.01,16.73,42.63,16.39z M39.38,14.36
  c0.31-0.18,0.62-0.34,0.93-0.51c-0.29,1.13-0.56,2.25-0.78,3.37c-0.6,0.36-1.2,0.72-1.79,1.1c0.19-1.12,0.4-2.24,0.66-3.37
  C38.73,14.76,39.05,14.55,39.38,14.36z M31.62,27.17c-1.11-0.14-2.24-0.25-3.37-0.33c0.51-0.48,1.02-0.96,1.55-1.43
  c1.12,0.12,2.23,0.27,3.33,0.45C32.62,26.29,32.12,26.73,31.62,27.17z M27.29,31.4c-1.12-0.04-2.24-0.04-3.38-0.02
  c0.46-0.53,0.92-1.05,1.4-1.57c1.14,0.01,2.26,0.06,3.37,0.14C28.21,30.43,27.74,30.91,27.29,31.4z M23.36,36.01
  c-1.11,0.07-2.24,0.17-3.36,0.3c0.41-0.57,0.82-1.14,1.24-1.69c1.14-0.09,2.27-0.15,3.39-0.18C24.2,34.96,23.77,35.48,23.36,36.01z
   M20.64,34.07c-0.43,0.56-0.85,1.14-1.26,1.71c-0.23-1.12-0.45-2.26-0.62-3.41l0,0c0.45-0.58,0.92-1.15,1.39-1.72
  C20.28,31.8,20.44,32.94,20.64,34.07z M20.83,29.8c0.48-0.56,0.97-1.11,1.46-1.65c0.09,1.16,0.2,2.31,0.34,3.44
  c-0.09,0.1-0.18,0.2-0.27,0.31c-0.37,0.44-0.72,0.89-1.07,1.34C21.12,32.11,20.96,30.96,20.83,29.8z M25.34,24.99
  c0.53-0.52,1.06-1.03,1.61-1.53c-0.02,1.17-0.02,2.33,0.02,3.48c-0.5,0.49-1,1-1.49,1.51C25.41,27.3,25.36,26.16,25.34,24.99z
   M35.1,20.12c0.14-1.13,0.3-2.26,0.5-3.4c0.62-0.41,1.24-0.81,1.86-1.2c-0.24,1.14-0.45,2.28-0.63,3.41
  C36.26,19.32,35.67,19.71,35.1,20.12z M34.24,20.76c-0.57,0.42-1.13,0.84-1.68,1.27c0.09-1.14,0.19-2.28,0.34-3.43
  c0.6-0.44,1.2-0.87,1.81-1.29C34.52,18.47,34.36,19.62,34.24,20.76z M31.72,22.7c-0.35,0.28-0.7,0.56-1.05,0.85
  c-0.19,0.16-0.38,0.34-0.57,0.51c0.03-1.14,0.09-2.29,0.18-3.44c0.58-0.47,1.15-0.93,1.75-1.38C31.89,20.4,31.79,21.56,31.72,22.7z
   M30.58,24.72c0.21-0.18,0.41-0.37,0.62-0.55c0.33-0.28,0.66-0.54,1-0.81c1.12,0.17,2.22,0.38,3.31,0.6
  c-0.53,0.4-1.05,0.82-1.57,1.24C32.83,25.02,31.71,24.86,30.58,24.72z M33,22.72c0.55-0.44,1.12-0.86,1.68-1.28
  c1.11,0.22,2.2,0.48,3.28,0.76c-0.55,0.38-1.09,0.77-1.63,1.17C35.24,23.12,34.12,22.91,33,22.72z M35.51,20.83
  c0.57-0.41,1.16-0.8,1.74-1.2c1.1,0.28,2.18,0.58,3.25,0.91c-0.57,0.35-1.13,0.72-1.69,1.09C37.73,21.34,36.63,21.08,35.51,20.83z
   M29.37,21.34c0.02-0.02,0.05-0.04,0.07-0.06c-0.08,1.17-0.13,2.33-0.14,3.48c-0.53,0.47-1.04,0.95-1.56,1.43
  c-0.02-1.14-0.02-2.29,0.02-3.45C28.29,22.28,28.82,21.8,29.37,21.34z M24.57,25.75c0.03,1.17,0.09,2.32,0.18,3.46
  c-0.48,0.52-0.95,1.05-1.41,1.58c-0.13-1.14-0.23-2.29-0.3-3.45C23.54,26.81,24.05,26.28,24.57,25.75z M18.1,33.25
  c0.19,1.14,0.41,2.27,0.66,3.38c-0.4,0.58-0.79,1.17-1.18,1.77c-0.29-1.11-0.55-2.23-0.78-3.37c0,0,0,0,0,0l0.17-0.25
  C17.33,34.26,17.72,33.76,18.1,33.25z M15.25,37.31c0.14-0.21,0.28-0.43,0.42-0.64l0.42-0.64l0.08-0.12
  c0.25,1.14,0.52,2.27,0.82,3.37c-0.38,0.6-0.74,1.2-1.1,1.81c-0.34-1.09-0.65-2.2-0.94-3.32C15.06,37.63,15.16,37.47,15.25,37.31z
   M14.39,38.71c0.3,1.12,0.62,2.23,0.98,3.31c-0.35,0.61-0.69,1.23-1.01,1.85c-0.39-1.07-0.75-2.16-1.09-3.27
  c0.13-0.23,0.25-0.47,0.39-0.7C13.89,39.51,14.15,39.11,14.39,38.71z M12.74,41.57c0.35,1.11,0.73,2.2,1.13,3.27
  c-0.32,0.63-0.63,1.25-0.93,1.89c-0.43-1.05-0.85-2.11-1.24-3.2c0.16-0.31,0.3-0.63,0.47-0.94C12.35,42.24,12.55,41.91,12.74,41.57z
   M11.22,44.5c0.41,1.09,0.83,2.17,1.29,3.22c-0.26,0.59-0.53,1.17-0.77,1.76c-0.02,0.06-0.04,0.11-0.07,0.17
  c-0.48-1.03-0.95-2.07-1.39-3.14c0.18-0.39,0.34-0.79,0.53-1.18C10.94,45.04,11.08,44.77,11.22,44.5z M9.26,48.82
  c0.1-0.24,0.2-0.47,0.29-0.71c0.09-0.21,0.19-0.41,0.28-0.62c0.46,1.07,0.93,2.13,1.44,3.16c-0.21,0.52-0.42,1.04-0.61,1.57
  l-0.12,0.35c-0.01,0.01-0.01,0.03-0.02,0.04c-0.53-1.01-1.05-2.03-1.54-3.09c0,0,0,0,0,0l0,0L9.26,48.82z M11.72,60.39
  c-0.99,0.52-1.97,1.08-2.95,1.66c0.14-0.69,0.29-1.37,0.45-2.06c0.99-0.55,1.99-1.06,2.99-1.54C12.03,59.1,11.87,59.75,11.72,60.39z
   M7.5,53.66c0.56,1.03,1.13,2.04,1.74,3.02C9.13,57.07,9,57.46,8.9,57.85c-0.08,0.29-0.14,0.58-0.21,0.87
  c-0.63-0.95-1.24-1.92-1.83-2.93c0.19-0.65,0.37-1.3,0.58-1.95C7.46,53.79,7.48,53.73,7.5,53.66z M8.43,59.76
  c-0.16,0.69-0.31,1.38-0.45,2.07c-0.67-0.92-1.33-1.87-1.97-2.84c0.17-0.72,0.34-1.44,0.54-2.16C7.16,57.83,7.79,58.81,8.43,59.76z
   M8.43,50.96l0.13-0.36c0.01-0.02,0.02-0.04,0.02-0.06c0.51,1.05,1.03,2.08,1.58,3.08l-0.23,0.69c-0.08,0.23-0.15,0.47-0.22,0.7
  c-0.07,0.21-0.12,0.42-0.18,0.63c-0.58-0.98-1.15-1.98-1.68-3C8.04,52.08,8.23,51.52,8.43,50.96z M7.18,64.85
  c-0.23-0.29-0.46-0.58-0.69-0.88c-0.23-0.29-0.45-0.59-0.68-0.89c-0.06-0.08-0.11-0.15-0.17-0.23l-0.17-0.23l-0.19-0.26
  c0.14-0.78,0.3-1.56,0.47-2.33c0.65,0.97,1.32,1.92,2.01,2.83c-0.05,0.24-0.1,0.49-0.14,0.73l-0.06,0.36l-0.03,0.18l-0.03,0.18
  l-0.12,0.73l-0.01,0.05C7.32,65.02,7.25,64.94,7.18,64.85z M10.63,63.76c-0.08,0.05-0.16,0.09-0.23,0.14
  c-0.31,0.19-0.62,0.38-0.93,0.57c-0.31,0.19-0.61,0.39-0.92,0.59c-0.12,0.08-0.24,0.16-0.36,0.24l0.02-0.12l0.11-0.72l0.03-0.18
  l0.03-0.18l0.06-0.36c0.04-0.22,0.09-0.44,0.13-0.67c0.97-0.6,1.94-1.15,2.92-1.69c-0.16,0.7-0.3,1.41-0.43,2.11l-0.19,0.11
  L10.63,63.76z M12.49,57.47c-1.01,0.47-2.02,0.98-3.03,1.52c0.08-0.31,0.14-0.62,0.23-0.93c0.1-0.37,0.22-0.74,0.32-1.11
  c1.01-0.5,2.03-0.96,3.05-1.4c-0.01,0.03-0.02,0.06-0.03,0.09C12.84,56.25,12.67,56.86,12.49,57.47z M13.4,54.58
  c-1.04,0.42-2.07,0.89-3.1,1.38c0.07-0.23,0.13-0.47,0.2-0.7c0.07-0.23,0.15-0.46,0.22-0.69l0.2-0.61c1.04-0.45,2.09-0.87,3.13-1.26
  L13.95,53C13.75,53.52,13.58,54.05,13.4,54.58z M14.71,51.04l-0.26,0.65l-0.02,0.06c-1.05,0.38-2.11,0.79-3.16,1.23
  c0.02-0.04,0.03-0.09,0.05-0.13l0.12-0.34C11.62,52,11.82,51.5,12.02,51c1.06-0.4,2.12-0.77,3.18-1.11
  c-0.07,0.17-0.15,0.33-0.22,0.5C14.89,50.61,14.79,50.82,14.71,51.04z M15.62,48.95c-1.08,0.33-2.15,0.69-3.23,1.08
  c0.03-0.08,0.06-0.16,0.1-0.25c0.24-0.57,0.49-1.13,0.74-1.7c1.08-0.35,2.16-0.67,3.24-0.96c-0.11,0.23-0.24,0.46-0.35,0.7
  C15.94,48.2,15.78,48.58,15.62,48.95z M16.92,46.23c-1.09,0.28-2.18,0.59-3.28,0.93c0-0.01,0.01-0.02,0.01-0.03
  c0.29-0.63,0.61-1.25,0.92-1.87c1.1-0.3,2.19-0.57,3.28-0.81c-0.16,0.29-0.33,0.58-0.49,0.88C17.21,45.63,17.07,45.93,16.92,46.23z
   M18.35,43.56c-1.1,0.22-2.21,0.49-3.31,0.77c0.33-0.62,0.67-1.24,1.01-1.85c1.11-0.25,2.22-0.46,3.32-0.65
  c-0.21,0.35-0.44,0.7-0.64,1.05C18.6,43.11,18.48,43.34,18.35,43.56z M20.58,39.91c-0.13,0.2-0.25,0.39-0.38,0.59
  c-0.1,0.16-0.19,0.32-0.29,0.47c-1.11,0.17-2.22,0.38-3.34,0.61c0.36-0.61,0.72-1.21,1.09-1.8c1.11-0.2,2.23-0.36,3.33-0.49
  l-0.02,0.04L20.58,39.91z M21.76,38.17l-0.2,0.28c-1.11,0.12-2.22,0.28-3.34,0.46c0.38-0.59,0.77-1.17,1.16-1.75
  c1.13-0.15,2.26-0.25,3.38-0.34C22.43,37.27,22.09,37.71,21.76,38.17z M25.27,33.66c-1.11,0.01-2.24,0.07-3.37,0.14
  c0.36-0.46,0.72-0.93,1.1-1.38c0.07-0.09,0.15-0.17,0.22-0.25c1.14-0.04,2.26-0.04,3.38-0.02C26.15,32.65,25.7,33.15,25.27,33.66z
   M29.41,29.23c-1.11-0.09-2.24-0.14-3.38-0.17c0.49-0.51,0.98-1.01,1.48-1.5c1.13,0.07,2.25,0.17,3.35,0.29
  C30.37,28.3,29.88,28.77,29.41,29.23z M42.31,19.45c-0.32,0.18-0.63,0.38-0.94,0.57c-1.07-0.35-2.16-0.66-3.27-0.96
  c0.59-0.38,1.2-0.75,1.8-1.11c1.09,0.33,2.15,0.69,3.2,1.07C42.85,19.16,42.58,19.3,42.31,19.45z M47.28,16.87
  c-0.2,0.09-0.39,0.19-0.59,0.29c-1.03-0.45-2.08-0.86-3.15-1.26c0.62-0.32,1.25-0.64,1.89-0.94c1.05,0.43,2.08,0.89,3.1,1.37
  C48.11,16.51,47.7,16.68,47.28,16.87z M49.85,15.75c-0.13,0.05-0.26,0.12-0.4,0.17c-1-0.49-2.04-0.96-3.08-1.4
  c0.64-0.29,1.28-0.58,1.92-0.86c1.04,0.48,2.04,0.99,3.03,1.51C50.83,15.37,50.34,15.55,49.85,15.75z M52.46,14.74
  c-0.07,0.02-0.13,0.05-0.2,0.08c-0.98-0.54-1.99-1.05-3.02-1.55l0.61-0.25l0.68-0.26l0.08-0.03l0.09-0.03l0.17-0.06l0.34-0.13
  c1.01,0.53,2,1.09,2.96,1.66C53.6,14.35,53.03,14.53,52.46,14.74z M78.57,11.14l-0.35-0.03l-0.17-0.01c-0.06,0-0.12-0.01-0.17-0.01
  l-0.7-0.05L76.48,11l-0.17-0.01l-0.17-0.01l-0.35-0.02c-0.45-0.02-0.9-0.02-1.35-0.03V10.9c-0.26,0-0.51,0.01-0.77,0.02
  c-0.23,0-0.46-0.01-0.69-0.01v0.03c-0.45,0.01-0.9,0.01-1.35,0.03l-0.35,0.02l-0.17,0.01L70.92,11l-0.7,0.04l-0.7,0.05
  c-0.06,0-0.12,0.01-0.17,0.01l-0.17,0.01l-0.35,0.03c-1.86,0.16-3.72,0.4-5.55,0.72c-0.77,0.13-1.53,0.29-2.29,0.45
  c-0.89-0.67-1.82-1.32-2.76-1.95c0.45-0.11,0.9-0.23,1.35-0.34c0.95-0.21,1.9-0.41,2.85-0.58l-0.15-0.8
  c-0.97,0.17-1.93,0.37-2.89,0.59c-0.44,0.1-0.87,0.22-1.31,0.33c0.55-1,1.12-2.01,1.73-3c0.81-0.17,1.62-0.34,2.44-0.48
  c2.01-0.35,4.03-0.61,6.06-0.79l0.38-0.03l0.19-0.02c0.06-0.01,0.13-0.01,0.19-0.01l0.76-0.05l0.76-0.04l0.19-0.01l0.19-0.01
  l0.38-0.02c0.13-0.01,0.25-0.01,0.38-0.02l0.38-0.01l0.38-0.01c0.13,0,0.25-0.01,0.38-0.01l0.38-0.01l0.19,0c0.04,0,0.07,0,0.11,0
  l0.16,0c0.06,0,0.13,0,0.19,0l0.19,0l0.38,0.01c0.13,0,0.25,0,0.38,0.01l0.38,0.01l0.38,0.01c0.13,0,0.25,0.01,0.38,0.02l0.38,0.02
  l0.19,0.01l0.19,0.01l0.76,0.04l0.76,0.05c0.06,0,0.13,0.01,0.19,0.01l0.19,0.02l0.38,0.03c2.03,0.18,4.05,0.43,6.06,0.79
  c0.84,0.15,1.67,0.32,2.51,0.49c0.61,0.99,1.18,1.99,1.73,3C88.92,9.46,88.46,9.33,88,9.23c-0.96-0.22-1.92-0.41-2.89-0.59
  l-0.15,0.8c0.96,0.17,1.91,0.37,2.85,0.58c0.43,0.1,0.86,0.21,1.29,0.32c-0.94,0.63-1.86,1.28-2.75,1.95l0.01,0.01
  c-0.74-0.16-1.49-0.31-2.24-0.44C82.29,11.54,80.43,11.31,78.57,11.14z M93.16,14.14c0.96-0.57,1.95-1.13,2.96-1.66
  c0.02,0.01,0.04,0.01,0.06,0.02l0.34,0.13l0.17,0.06l0.09,0.03l0.08,0.03l0.68,0.26l0.55,0.22c-1.02,0.5-2.03,1.01-3.01,1.55
  c0,0,0,0.01,0,0.01c-0.05-0.02-0.09-0.04-0.14-0.05C94.35,14.53,93.76,14.33,93.16,14.14z M96.01,15.15c0.99-0.53,2-1.04,3.04-1.52
  c0.64,0.27,1.28,0.56,1.92,0.85c-1.04,0.45-2.07,0.91-3.07,1.4c0,0,0,0.01,0,0.01c-0.12-0.05-0.23-0.1-0.35-0.15
  C97.04,15.54,96.52,15.35,96.01,15.15z M98.81,16.3c1.01-0.48,2.04-0.94,3.1-1.37c0.63,0.3,1.26,0.62,1.89,0.94
  c-1.06,0.4-2.11,0.81-3.14,1.25l0,0.02c-0.18-0.09-0.36-0.18-0.55-0.27C99.68,16.67,99.24,16.49,98.81,16.3z M104.23,18.99
  c1.05-0.38,2.12-0.74,3.21-1.07c0.6,0.36,1.21,0.73,1.8,1.11c-1.1,0.3-2.19,0.61-3.25,0.96c0,0.01,0,0.01,0,0.02
  c-0.3-0.18-0.6-0.37-0.91-0.55C104.8,19.29,104.51,19.14,104.23,18.99z M114.21,25.82c1.1-0.18,2.21-0.33,3.33-0.45
  c0.52,0.47,1.04,0.94,1.55,1.43c-1.13,0.09-2.25,0.19-3.35,0.33c0,0.01,0,0.01,0,0.02c-0.49-0.43-0.98-0.87-1.48-1.29
  C114.25,25.84,114.23,25.83,114.21,25.82z M116.49,27.81c1.11-0.12,2.22-0.23,3.35-0.29c0.5,0.49,0.99,0.99,1.48,1.5
  c-1.13,0.03-2.25,0.08-3.36,0.18c0,0.01,0,0.01,0,0.02C117.48,28.73,116.99,28.26,116.49,27.81z M120.75,32.1
  c1.12-0.02,2.24-0.02,3.38,0.02c0.09,0.1,0.18,0.2,0.27,0.31c0.36,0.43,0.71,0.88,1.06,1.32c-1.13-0.07-2.25-0.13-3.37-0.14
  c0,0,0,0,0,0.01C121.65,33.1,121.2,32.6,120.75,32.1z M124.59,36.77c1.12,0.08,2.25,0.19,3.38,0.34c0.4,0.57,0.78,1.16,1.16,1.74
  c-1.12-0.18-2.23-0.34-3.34-0.45c0,0,0,0,0,0l-0.16-0.23C125.3,37.7,124.94,37.23,124.59,36.77z M126.82,39.91l-0.39-0.58
  l-0.06-0.09c1.11,0.13,2.22,0.29,3.33,0.49c0.37,0.59,0.74,1.19,1.09,1.8c-1.11-0.23-2.22-0.44-3.32-0.61l0,0.01
  c-0.09-0.14-0.17-0.29-0.27-0.43C127.08,40.3,126.95,40.1,126.82,39.91z M127.99,41.77c1.1,0.19,2.21,0.4,3.32,0.65
  c0.35,0.61,0.69,1.23,1.01,1.85c-1.1-0.28-2.2-0.55-3.29-0.77c0,0.01,0,0.01-0.01,0.02c-0.12-0.21-0.23-0.43-0.36-0.64
  C128.45,42.51,128.21,42.14,127.99,41.77z M129.51,44.39c1.09,0.24,2.19,0.5,3.28,0.8c0.32,0.63,0.64,1.26,0.93,1.9
  c-1.09-0.34-2.17-0.65-3.26-0.92c0,0.01-0.01,0.01-0.01,0.02c-0.15-0.29-0.28-0.58-0.43-0.86C129.86,45.01,129.68,44.7,129.51,44.39
  z M130.9,47.08c1.08,0.29,2.16,0.6,3.24,0.96c0.26,0.58,0.53,1.17,0.77,1.76c0.02,0.06,0.05,0.12,0.07,0.18
  c-1.07-0.39-2.14-0.75-3.21-1.07c0,0.01-0.01,0.01-0.01,0.02c-0.16-0.36-0.32-0.73-0.48-1.09C131.16,47.58,131.03,47.33,130.9,47.08
  z M132.42,50.39c-0.08-0.19-0.17-0.38-0.25-0.57c1.06,0.34,2.12,0.7,3.18,1.11c0.21,0.52,0.42,1.04,0.61,1.57l0.12,0.34
  c0.01,0.02,0.02,0.04,0.02,0.07c-1.05-0.44-2.1-0.85-3.16-1.23c0,0,0,0,0,0l0,0l-0.26-0.65C132.6,50.82,132.51,50.61,132.42,50.39z
   M133.45,52.99l-0.12-0.33c0-0.01-0.01-0.02-0.01-0.03c1.05,0.39,2.09,0.81,3.14,1.26l0.22,0.67c0.08,0.23,0.15,0.46,0.22,0.69
  c0.07,0.21,0.12,0.42,0.18,0.63c-1.02-0.48-2.05-0.95-3.08-1.37c0,0.01-0.01,0.01-0.01,0.02C133.81,54.03,133.64,53.51,133.45,52.99
  z M134.36,55.64c-0.02-0.05-0.04-0.1-0.05-0.15c1.02,0.44,2.04,0.9,3.06,1.4c0.11,0.39,0.23,0.78,0.34,1.17
  c0.08,0.29,0.14,0.58,0.21,0.86c-1-0.53-2-1.04-3.01-1.51l-0.01,0.02C134.72,56.83,134.55,56.23,134.36,55.64z M138.01,64.46
  c-0.31-0.19-0.62-0.39-0.93-0.58c-0.08-0.05-0.15-0.09-0.23-0.14l-0.23-0.14l-0.28-0.17c-0.13-0.71-0.27-1.41-0.43-2.11
  c0.98,0.53,1.95,1.09,2.92,1.69c0.05,0.24,0.1,0.49,0.14,0.73l0.06,0.36l0.03,0.18l0.03,0.18l0.11,0.72l0.01,0.06
  c-0.09-0.06-0.18-0.12-0.27-0.18C138.62,64.85,138.31,64.65,138.01,64.46z"
      fill="#FBCC34"
    />
    <g>
      <path
        d="M143.3,125.72l0,0.02l0,0.05l0,0.1l0,0.19l0,0.38c0,0.06,0,0.13,0,0.19l0,0.19l-0.01,0.38
    c0,0.13,0,0.25-0.01,0.38l-0.01,0.38l-0.01,0.38c0,0.13-0.01,0.25-0.02,0.38l-0.02,0.38l-0.01,0.19l-0.01,0.19l-0.04,0.76
    l-0.05,0.76c0,0.06-0.01,0.13-0.01,0.19l-0.02,0.19l-0.03,0.38c-0.18,2.03-0.43,4.05-0.79,6.06c-0.15,0.84-0.32,1.67-0.49,2.51
    c-0.99,0.61-1.99,1.18-3,1.73c0.11-0.46,0.24-0.92,0.34-1.38c0.22-0.96,0.41-1.92,0.59-2.89l-0.8-0.15
    c-0.17,0.96-0.37,1.91-0.58,2.85c-0.1,0.43-0.21,0.86-0.32,1.29c-0.63-0.94-1.28-1.86-1.95-2.75l-0.01,0.01
    c0.16-0.74,0.31-1.49,0.44-2.24c0.32-1.84,0.56-3.69,0.72-5.55l0.03-0.35l0.01-0.17c0-0.06,0.01-0.12,0.01-0.17l0.05-0.7l0.04-0.7
    l0.01-0.17l0.01-0.18l0.02-0.35c0.04-0.93,0.06-1.87,0.06-2.8h-0.82l0,0.69c0,0.06,0,0.12,0,0.17l0,0.17l-0.01,0.35
    c0,0.12,0,0.23-0.01,0.35l-0.01,0.35l-0.01,0.35c0,0.12-0.01,0.23-0.01,0.35l-0.02,0.35l-0.01,0.17l-0.01,0.17l-0.04,0.69
    l-0.05,0.69c0,0.06-0.01,0.11-0.01,0.17l-0.01,0.17l-0.03,0.34c-0.16,1.84-0.39,3.67-0.71,5.48c-0.32,1.82-0.71,3.62-1.19,5.4
    c-0.47,1.78-1.02,3.54-1.65,5.27c-0.31,0.87-0.65,1.73-1,2.58c-0.35,0.85-0.72,1.7-1.11,2.53c-0.78,1.67-1.63,3.31-2.55,4.91
    c-1.84,3.19-3.96,6.23-6.32,9.06c-2.36,2.83-4.97,5.46-7.79,7.84c-1.41,1.19-2.87,2.32-4.37,3.39l-0.28,0.2
    c-0.09,0.07-0.19,0.13-0.28,0.2l-0.57,0.39l-0.58,0.38c-0.19,0.13-0.39,0.25-0.58,0.38c-0.78,0.5-1.56,0.98-2.36,1.45
    c-0.8,0.47-1.6,0.91-2.42,1.34c-0.82,0.43-1.64,0.84-2.47,1.24c-0.83,0.39-1.67,0.77-2.52,1.13c-0.21,0.09-0.43,0.18-0.64,0.26
    l-0.64,0.26l-0.64,0.25c-0.11,0.04-0.22,0.08-0.32,0.12l-0.32,0.12c-0.86,0.32-1.74,0.62-2.61,0.9c-0.88,0.28-1.76,0.55-2.65,0.79
    c-0.89,0.24-1.78,0.47-2.68,0.67c-0.9,0.2-1.8,0.39-2.71,0.55l0.01,0.07c0,0,0,0,0,0c-0.73,1.24-1.51,2.47-2.34,3.68l0,0
    c-0.12,0.02-0.24,0.03-0.35,0.05c-0.96,0.12-1.93,0.23-2.89,0.31c-0.97,0.08-1.94,0.15-2.9,0.19c-0.77,0.03-1.54,0.04-2.31,0.05
    c-0.7-0.01-1.4-0.02-2.1-0.05c-0.97-0.04-1.94-0.1-2.91-0.19c-0.97-0.08-1.93-0.19-2.89-0.31c-0.14-0.02-0.28-0.04-0.42-0.06
    l0.08-0.07c-0.83-1.21-1.61-2.44-2.34-3.68c0,0,0,0,0,0c-0.91-0.16-1.81-0.35-2.71-0.55c-0.9-0.2-1.79-0.43-2.68-0.67
    c-0.89-0.24-1.77-0.51-2.65-0.79c-0.88-0.28-1.75-0.58-2.61-0.9l-0.32-0.12c-0.11-0.04-0.22-0.08-0.32-0.12l-0.64-0.25l-0.64-0.26
    c-0.21-0.09-0.43-0.18-0.64-0.26c-0.85-0.36-1.69-0.73-2.52-1.13c-0.83-0.39-1.66-0.81-2.47-1.24c-0.82-0.43-1.62-0.88-2.42-1.34
    c-0.8-0.47-1.58-0.95-2.36-1.45c-0.19-0.13-0.39-0.25-0.58-0.38l-0.58-0.38l-0.57-0.39c-0.1-0.06-0.19-0.13-0.28-0.2l-0.28-0.2
    c-1.51-1.07-2.96-2.2-4.37-3.39c-2.81-2.38-5.43-5.01-7.79-7.84c-2.37-2.83-4.48-5.87-6.32-9.06c-0.92-1.6-1.77-3.24-2.55-4.91
    c-0.39-0.84-0.76-1.68-1.11-2.53c-0.35-0.85-0.68-1.71-1-2.58c-0.63-1.73-1.18-3.49-1.65-5.27c-0.48-1.78-0.87-3.58-1.19-5.4
    c-0.32-1.82-0.55-3.65-0.71-5.48l-0.03-0.34l-0.01-0.17c0-0.06-0.01-0.11-0.01-0.17l-0.05-0.69l-0.04-0.69l-0.01-0.17l-0.01-0.17
    l-0.02-0.35c0-0.12-0.01-0.23-0.01-0.35l-0.01-0.35l-0.01-0.35c0-0.12,0-0.23-0.01-0.35l-0.01-0.35l0-0.17c0-0.06,0-0.12,0-0.17
    l0-0.69H9.98c0,0.94,0.02,1.87,0.06,2.8l0.02,0.35l0.01,0.18l0.01,0.17l0.04,0.7l0.05,0.7c0,0.06,0.01,0.12,0.01,0.17l0.01,0.17
    l0.03,0.35c0.16,1.86,0.4,3.72,0.72,5.55c0.13,0.77,0.29,1.53,0.45,2.29c-0.67,0.89-1.32,1.82-1.95,2.76
    c-0.11-0.45-0.23-0.9-0.34-1.35c-0.21-0.95-0.41-1.9-0.58-2.85l-0.8,0.15c0.17,0.97,0.37,1.93,0.59,2.89
    c0.1,0.44,0.22,0.87,0.33,1.31c-1-0.55-2.01-1.12-3-1.73c-0.17-0.81-0.34-1.62-0.48-2.44c-0.35-2.01-0.61-4.03-0.79-6.06
    l-0.03-0.38l-0.02-0.19c-0.01-0.06-0.01-0.13-0.01-0.19l-0.05-0.76l-0.04-0.76l-0.01-0.19l-0.01-0.19l-0.02-0.38
    c-0.01-0.13-0.01-0.25-0.02-0.38l-0.01-0.38l-0.01-0.38c0-0.13-0.01-0.25-0.01-0.38l-0.01-0.38l0-0.19c0-0.06,0-0.13,0-0.19l0-0.38
    l0-0.19l0-0.1l0-0.05l0-0.02l-0.82,0l0,0.02l0,0.05l0,0.1l0,0.19l0,0.39c0,0.06,0,0.13,0,0.19l0,0.19l0.01,0.39
    c0,0.13,0,0.26,0.01,0.39l0.01,0.39l0.01,0.39c0,0.13,0.01,0.26,0.02,0.39l0.02,0.39l0.01,0.19l0.01,0.19l0.04,0.77l0.05,0.77
    c0,0.06,0.01,0.13,0.01,0.19l0.02,0.19l0.03,0.39c0.18,2.05,0.44,4.1,0.79,6.13c0.35,2.03,0.79,4.05,1.33,6.04
    c0.53,1.99,1.15,3.96,1.85,5.9c0.35,0.97,0.72,1.93,1.12,2.88c0.39,0.95,0.81,1.9,1.24,2.83c0.87,1.87,1.82,3.7,2.85,5.49
    c2.06,3.57,4.42,6.97,7.07,10.13c2.64,3.17,5.56,6.1,8.7,8.76c1.57,1.33,3.21,2.59,4.89,3.78l0.32,0.22
    c0.11,0.07,0.21,0.15,0.32,0.22l0.64,0.44l0.64,0.43c0.22,0.14,0.43,0.28,0.65,0.42c0.87,0.56,1.75,1.1,2.64,1.62
    c0.89,0.52,1.79,1.02,2.7,1.5c0.91,0.48,1.83,0.94,2.77,1.38c0.93,0.44,1.87,0.86,2.82,1.26c0.24,0.1,0.48,0.2,0.71,0.3l0.72,0.29
    l0.72,0.28c0.12,0.05,0.24,0.09,0.36,0.14l0.36,0.14c0.97,0.36,1.94,0.69,2.92,1.01c0.98,0.32,1.97,0.61,2.97,0.88
    c0.99,0.27,2,0.52,3,0.75c1.01,0.23,2.02,0.44,3.03,0.62l0.01-0.07c0,0,0,0,0,0c1.23-0.89,2.41-1.81,3.55-2.75l0,0
    c0.12,0.02,0.24,0.03,0.36,0.05c0.97,0.13,1.95,0.23,2.93,0.32c0.98,0.09,1.96,0.15,2.94,0.19c0.51,0.02,1.02,0.02,1.54,0.03v0.03
    c0.2,0,0.4-0.01,0.6-0.01c0.27,0,0.54,0.02,0.81,0.02v-0.04c0.51-0.01,1.02-0.01,1.54-0.03c0.98-0.04,1.96-0.1,2.94-0.19
    c0.98-0.08,1.96-0.19,2.93-0.32c0.14-0.02,0.29-0.04,0.43-0.06l-0.06,0.09c1.14,0.95,2.32,1.86,3.55,2.75c0,0,0,0,0-0.01
    c1.01-0.18,2.02-0.39,3.03-0.62c1.01-0.23,2.01-0.48,3-0.75c1-0.27,1.98-0.57,2.97-0.88c0.98-0.32,1.96-0.65,2.92-1.01l0.36-0.14
    c0.12-0.05,0.24-0.09,0.36-0.14l0.72-0.28l0.72-0.29c0.24-0.1,0.48-0.2,0.71-0.3c0.95-0.4,1.89-0.82,2.82-1.26
    c0.93-0.44,1.85-0.9,2.77-1.38c0.91-0.48,1.81-0.98,2.7-1.5c0.89-0.52,1.77-1.06,2.64-1.62c0.22-0.14,0.43-0.28,0.65-0.42
    l0.64-0.43l0.64-0.44c0.11-0.07,0.21-0.15,0.32-0.22l0.32-0.22c1.68-1.19,3.31-2.45,4.89-3.78c3.14-2.66,6.06-5.6,8.7-8.76
    c2.64-3.16,5.01-6.56,7.07-10.13c1.03-1.79,1.98-3.62,2.85-5.49c0.44-0.93,0.85-1.88,1.24-2.83c0.39-0.95,0.77-1.91,1.12-2.88
    c0.7-1.94,1.32-3.91,1.85-5.9c0.53-1.99,0.97-4.01,1.33-6.04c0.36-2.03,0.62-4.08,0.79-6.13l0.03-0.39l0.02-0.19
    c0.01-0.06,0.01-0.13,0.01-0.19l0.05-0.77l0.04-0.77l0.01-0.19l0.01-0.19l0.02-0.39c0.01-0.13,0.01-0.26,0.02-0.39l0.01-0.39
    l0.01-0.39c0-0.13,0.01-0.26,0.01-0.39l0.01-0.39l0-0.19c0-0.06,0-0.13,0-0.19l0-0.39l0-0.19l0-0.1l0-0.05l0-0.02L143.3,125.72z
     M63.55,190.65c0.19,0.31,0.39,0.61,0.59,0.92c0.08,0.12,0.16,0.24,0.24,0.36l-0.12-0.02l-0.72-0.11l-0.18-0.03l-0.18-0.03
    l-0.36-0.06c-0.22-0.04-0.44-0.09-0.67-0.13c-0.6-0.97-1.15-1.94-1.69-2.92c0.7,0.16,1.41,0.3,2.11,0.43l0.11,0.19l0.14,0.23
    c0.05,0.08,0.09,0.16,0.14,0.23C63.16,190.03,63.35,190.34,63.55,190.65z M58.06,194.1c-0.72-0.17-1.44-0.34-2.16-0.54
    c1-0.61,1.98-1.23,2.93-1.88c0.69,0.16,1.38,0.31,2.07,0.45C59.98,192.81,59.04,193.47,58.06,194.1z M59.47,188.4
    c0.52,0.99,1.08,1.97,1.66,2.95c-0.69-0.14-1.37-0.29-2.06-0.45c-0.55-0.99-1.06-1.99-1.54-2.99
    C58.18,188.09,58.82,188.25,59.47,188.4z M56.55,187.63c0.47,1.01,0.98,2.02,1.52,3.03c-0.31-0.08-0.62-0.14-0.93-0.23
    c-0.37-0.1-0.74-0.22-1.11-0.32c-0.5-1.01-0.96-2.03-1.4-3.05c0.03,0.01,0.06,0.02,0.09,0.03
    C55.33,187.28,55.94,187.45,56.55,187.63z M52.07,186.17c0.52,0.19,1.06,0.37,1.59,0.55c0.42,1.04,0.89,2.07,1.38,3.1
    c-0.23-0.07-0.47-0.13-0.7-0.2c-0.23-0.07-0.46-0.15-0.69-0.22l-0.61-0.2c-0.45-1.04-0.87-2.09-1.26-3.13L52.07,186.17z
     M50.12,185.41l0.65,0.26l0.06,0.02c0.38,1.05,0.79,2.11,1.23,3.16c-0.04-0.02-0.09-0.03-0.13-0.05l-0.34-0.12
    c-0.51-0.18-1.01-0.39-1.51-0.59c-0.4-1.06-0.77-2.12-1.11-3.18c0.17,0.07,0.33,0.15,0.5,0.22
    C49.69,185.23,49.9,185.33,50.12,185.41z M48.03,184.5c0.33,1.08,0.69,2.15,1.08,3.23c-0.08-0.03-0.16-0.06-0.25-0.1
    c-0.57-0.24-1.13-0.49-1.7-0.74c-0.35-1.08-0.67-2.16-0.96-3.24c0.23,0.11,0.46,0.24,0.7,0.35
    C47.28,184.18,47.66,184.34,48.03,184.5z M45.31,183.2c0.27,1.09,0.59,2.18,0.93,3.28c-0.01,0-0.02-0.01-0.03-0.01
    c-0.63-0.29-1.25-0.61-1.87-0.92c-0.3-1.1-0.57-2.19-0.81-3.28c0.29,0.16,0.58,0.33,0.88,0.49
    C44.7,182.91,45.01,183.05,45.31,183.2z M42.64,181.77c0.22,1.1,0.49,2.21,0.77,3.31c-0.62-0.33-1.24-0.67-1.85-1.01
    c-0.25-1.11-0.46-2.22-0.65-3.32c0.35,0.21,0.7,0.44,1.05,0.64C42.18,181.52,42.42,181.64,42.64,181.77z M38.99,179.54
    c0.2,0.13,0.39,0.25,0.59,0.38c0.16,0.1,0.32,0.19,0.47,0.29c0.17,1.11,0.38,2.22,0.61,3.34c-0.61-0.36-1.21-0.72-1.8-1.09
    c-0.2-1.11-0.36-2.23-0.49-3.33l0.04,0.02L38.99,179.54z M37.53,178.55c0.12,1.11,0.28,2.22,0.46,3.34
    c-0.59-0.38-1.17-0.77-1.75-1.16c-0.15-1.13-0.25-2.26-0.34-3.38c0.45,0.34,0.89,0.68,1.35,1L37.53,178.55z M28.14,174.09
    c-0.51-0.49-1.01-0.98-1.5-1.48c0.07-1.13,0.17-2.25,0.29-3.35c0.45,0.49,0.91,0.97,1.38,1.45
    C28.22,171.83,28.17,172.96,28.14,174.09z M29.87,176.78c-1.14,0.13-2.29,0.23-3.45,0.3c-0.54-0.5-1.07-1.02-1.59-1.54
    c1.17-0.03,2.32-0.09,3.46-0.18C28.81,175.85,29.34,176.32,29.87,176.78z M31.25,176.9c-0.04-1.14-0.04-2.26-0.02-3.38
    c0.5,0.45,1,0.9,1.51,1.34c0.01,1.11,0.07,2.24,0.14,3.37c-0.46-0.36-0.93-0.72-1.38-1.1C31.42,177.05,31.33,176.97,31.25,176.9z
     M33.7,178.88c-0.09-1.14-0.15-2.27-0.18-3.39c0.52,0.43,1.04,0.85,1.57,1.27c0.07,1.11,0.17,2.24,0.3,3.36
    C34.82,179.71,34.25,179.3,33.7,178.88z M34.85,180.74c-1.12,0.23-2.26,0.45-3.41,0.62l0,0c-0.58-0.45-1.15-0.92-1.72-1.39
    c1.15-0.14,2.29-0.3,3.42-0.5C33.71,179.91,34.28,180.33,34.85,180.74z M30.48,172.83c-0.04,1.12-0.04,2.24-0.02,3.38
    c-0.53-0.46-1.05-0.92-1.57-1.4c0.01-1.14,0.06-2.26,0.14-3.37C29.51,171.91,29.99,172.38,30.48,172.83z M26.25,168.5
    c-0.14,1.11-0.25,2.24-0.33,3.37c-0.48-0.51-0.96-1.02-1.43-1.55c0.12-1.12,0.27-2.23,0.45-3.33C25.36,167.5,25.81,168,26.25,168.5
    z M18.32,168.1c1.16,0.13,2.32,0.24,3.46,0.3c0.28,0.35,0.56,0.7,0.85,1.05c0.16,0.19,0.34,0.38,0.51,0.57
    c-1.14-0.03-2.29-0.09-3.44-0.18C19.23,169.27,18.77,168.69,18.32,168.1z M23.25,168.92c-0.28-0.33-0.54-0.66-0.81-1
    c0.17-1.12,0.38-2.22,0.6-3.31c0.4,0.53,0.82,1.05,1.24,1.57c-0.19,1.1-0.35,2.23-0.49,3.36
    C23.62,169.33,23.43,169.13,23.25,168.92z M21.8,167.12c-0.44-0.55-0.86-1.12-1.28-1.68c0.22-1.11,0.48-2.2,0.76-3.28
    c0.38,0.55,0.77,1.09,1.17,1.63C22.2,164.88,21.99,166,21.8,167.12z M19.91,164.61c-0.41-0.57-0.8-1.16-1.2-1.74
    c0.28-1.1,0.58-2.18,0.91-3.25c0.35,0.57,0.72,1.13,1.09,1.69C20.42,162.39,20.15,163.49,19.91,164.61z M19.2,165.02
    c-1.13-0.14-2.26-0.3-3.4-0.5c-0.41-0.62-0.81-1.24-1.2-1.86c1.14,0.24,2.28,0.45,3.41,0.63C18.4,163.86,18.79,164.45,19.2,165.02z
     M19.84,165.88c0.42,0.57,0.84,1.13,1.27,1.68c-1.14-0.09-2.28-0.19-3.43-0.34c-0.44-0.6-0.87-1.2-1.29-1.81
    C17.55,165.6,18.7,165.76,19.84,165.88z M19.1,158.75c-0.35,1.07-0.66,2.16-0.96,3.27c-0.38-0.59-0.75-1.2-1.11-1.8
    c0.33-1.09,0.69-2.15,1.07-3.2c0.15,0.26,0.28,0.53,0.43,0.8C18.71,158.12,18.91,158.43,19.1,158.75z M11.4,156.88
    c1.11,0.34,2.22,0.66,3.33,0.94c0.33,0.62,0.67,1.24,1.02,1.84c-1.11-0.24-2.22-0.51-3.34-0.82
    C12.06,158.2,11.72,157.54,11.4,156.88z M12.36,143.06c0.18,0.65,0.36,1.3,0.56,1.94c-0.58,0.95-1.14,1.93-1.68,2.92
    c-0.21-0.59-0.42-1.17-0.61-1.76c-0.02-0.07-0.04-0.15-0.07-0.22C11.14,144.95,11.74,143.99,12.36,143.06z M9.85,146.4
    c0.19,0.58,0.39,1.15,0.59,1.72c-1.06-0.45-2.11-0.93-3.16-1.44c0,0,0,0,0,0c-0.22-0.7-0.42-1.41-0.61-2.12
    c1.03,0.55,2.06,1.07,3.09,1.55C9.8,146.21,9.82,146.31,9.85,146.4z M10.79,149.12c0.01,0.02,0.02,0.05,0.02,0.07l0.13,0.35
    l0.06,0.17l0.03,0.09l0.03,0.09l0.27,0.69l0.21,0.53c-1.07-0.4-2.14-0.82-3.2-1.28c-0.02-0.05-0.04-0.1-0.06-0.15
    c-0.23-0.64-0.44-1.29-0.65-1.94C8.68,148.22,9.73,148.69,10.79,149.12z M11.96,152.08c0.28,0.65,0.57,1.3,0.86,1.94
    c-1.09-0.35-2.18-0.72-3.27-1.13c-0.05-0.13-0.11-0.25-0.16-0.38c-0.23-0.55-0.43-1.12-0.65-1.68
    C9.82,151.28,10.89,151.7,11.96,152.08z M13.27,154.99c0.3,0.64,0.62,1.27,0.94,1.89c-1.1-0.3-2.2-0.62-3.31-0.98
    c-0.09-0.2-0.2-0.39-0.29-0.59c-0.22-0.47-0.42-0.95-0.62-1.42C11.09,154.29,12.18,154.66,13.27,154.99z M16.5,159.34
    c-0.35-0.61-0.69-1.23-1.03-1.85c0.38-1.07,0.79-2.12,1.22-3.15c0.3,0.6,0.6,1.2,0.92,1.79C17.21,157.17,16.84,158.25,16.5,159.34z
     M16.24,153.42c-0.45,1.03-0.86,2.08-1.26,3.15c-0.32-0.62-0.64-1.25-0.94-1.89c0.43-1.05,0.89-2.08,1.37-3.1
    c0.18,0.41,0.35,0.83,0.54,1.24C16.04,153.03,16.15,153.23,16.24,153.42z M15,150.67c-0.49,1-0.96,2.04-1.4,3.08
    c-0.29-0.64-0.58-1.28-0.86-1.92c0.48-1.04,0.99-2.04,1.51-3.03c0.19,0.49,0.37,0.99,0.57,1.48C14.88,150.4,14.94,150.53,15,150.67
    z M13.89,147.85c-0.54,0.98-1.05,1.99-1.55,3.02l-0.24-0.61l-0.26-0.68l-0.03-0.08l-0.03-0.09l-0.06-0.17l-0.13-0.34
    c0.53-1.01,1.09-2,1.66-2.96c0.19,0.57,0.37,1.15,0.58,1.71C13.84,147.72,13.87,147.79,13.89,147.85z M9.92,142.55l0.15-0.23
    l0.15-0.23l0.31-0.45l0.15-0.23l0.08-0.11l0.08-0.11c0.21-0.3,0.42-0.59,0.63-0.89c0.05-0.06,0.09-0.12,0.14-0.19
    c0.15,0.66,0.31,1.31,0.48,1.97c-0.63,0.92-1.23,1.87-1.82,2.84c-0.16-0.52-0.32-1.04-0.46-1.56c-0.04-0.15-0.07-0.31-0.11-0.46
    L9.92,142.55z M6.14,141.53c0.33,0.19,0.65,0.38,0.97,0.56l0.12,0.07l0.12,0.07l0.24,0.14l0.49,0.27l0.24,0.13l0.25,0.13l0.3,0.16
    c0.05,0.17,0.08,0.35,0.13,0.52c0.14,0.51,0.3,1.01,0.45,1.52c-1.03-0.5-2.06-1.02-3.08-1.59c-0.19-0.71-0.36-1.42-0.52-2.14
    C5.96,141.42,6.05,141.48,6.14,141.53z M12.93,159.81c1.13,0.29,2.25,0.56,3.37,0.78c0.36,0.6,0.72,1.2,1.1,1.79
    c-1.12-0.19-2.24-0.4-3.37-0.66c-0.2-0.33-0.41-0.65-0.6-0.99C13.26,160.43,13.1,160.12,12.93,159.81z M20.37,170.68
    c1.17,0.08,2.33,0.13,3.48,0.14c0.47,0.53,0.95,1.04,1.43,1.56c-1.14,0.02-2.29,0.02-3.45-0.02c-0.47-0.53-0.95-1.06-1.4-1.61
    C20.4,170.73,20.39,170.7,20.37,170.68z M22.54,173.17c1.17,0.02,2.33,0.02,3.48-0.02c0.49,0.5,1,1,1.51,1.49
    c-1.14,0.07-2.29,0.13-3.45,0.14C23.55,174.25,23.04,173.72,22.54,173.17z M27.23,177.82c1.16-0.09,2.31-0.2,3.44-0.34
    c0.1,0.09,0.2,0.18,0.31,0.27c0.44,0.37,0.89,0.72,1.34,1.07c-1.13,0.18-2.28,0.34-3.44,0.46
    C28.32,178.81,27.77,178.32,27.23,177.82z M33.86,183.16c-0.52-0.37-1.02-0.75-1.53-1.13c1.14-0.19,2.27-0.41,3.38-0.66
    c0.58,0.4,1.17,0.79,1.77,1.18c-1.11,0.29-2.23,0.55-3.37,0.78c0,0,0,0,0,0L33.86,183.16z M35.75,184.45l-0.64-0.42l-0.12-0.08
    c1.14-0.25,2.27-0.52,3.37-0.82c0.6,0.38,1.2,0.74,1.81,1.1c-1.09,0.34-2.2,0.65-3.32,0.94c-0.15-0.1-0.31-0.19-0.47-0.29
    C36.18,184.73,35.96,184.59,35.75,184.45z M37.79,185.73c1.12-0.3,2.23-0.62,3.31-0.98c0.61,0.35,1.23,0.69,1.85,1.01
    c-1.07,0.39-2.16,0.75-3.27,1.09c-0.23-0.13-0.47-0.25-0.7-0.39C38.59,186.23,38.19,185.97,37.79,185.73z M40.65,187.38
    c1.11-0.35,2.2-0.73,3.27-1.13c0.63,0.32,1.25,0.63,1.89,0.93c-1.05,0.43-2.11,0.85-3.2,1.24c-0.31-0.16-0.63-0.3-0.94-0.47
    C41.32,187.77,40.99,187.57,40.65,187.38z M43.58,188.9c1.09-0.41,2.17-0.83,3.22-1.29c0.59,0.26,1.17,0.53,1.76,0.77
    c0.06,0.02,0.11,0.04,0.17,0.07c-1.03,0.48-2.07,0.95-3.14,1.39c-0.39-0.18-0.79-0.34-1.18-0.53
    C44.12,189.18,43.85,189.04,43.58,188.9z M47.9,190.86c-0.24-0.1-0.47-0.2-0.71-0.29c-0.21-0.09-0.41-0.19-0.62-0.28
    c1.07-0.46,2.13-0.93,3.16-1.44c0.52,0.21,1.04,0.42,1.57,0.61l0.35,0.12c0.01,0.01,0.03,0.01,0.04,0.01
    c-1.01,0.53-2.03,1.05-3.09,1.54c0,0,0,0,0,0l0,0L47.9,190.86z M49.68,191.55c-0.02-0.01-0.04-0.01-0.06-0.02
    c1.05-0.51,2.08-1.03,3.08-1.58l0.69,0.23c0.23,0.08,0.47,0.15,0.7,0.22c0.21,0.07,0.42,0.12,0.63,0.18
    c-0.98,0.58-1.98,1.15-3,1.68c-0.56-0.19-1.13-0.38-1.69-0.58L49.68,191.55z M52.74,192.62c1.03-0.56,2.04-1.13,3.02-1.74
    c0.39,0.11,0.77,0.23,1.17,0.34c0.29,0.08,0.58,0.14,0.87,0.21c-0.95,0.63-1.92,1.24-2.93,1.83c-0.65-0.19-1.3-0.37-1.95-0.58
    C52.86,192.66,52.8,192.64,52.74,192.62z M63.05,193.63c-0.29,0.23-0.59,0.45-0.89,0.68c-0.08,0.06-0.15,0.11-0.23,0.17l-0.23,0.17
    l-0.26,0.19c-0.78-0.14-1.56-0.3-2.33-0.47c0.97-0.65,1.92-1.32,2.83-2.01c0.24,0.05,0.49,0.1,0.73,0.14l0.36,0.06l0.18,0.03
    l0.18,0.03l0.73,0.12l0.05,0.01c-0.09,0.07-0.17,0.14-0.26,0.21C63.64,193.17,63.35,193.4,63.05,193.63z M138.89,142.92l0.25-0.13
    l0.24-0.13l0.49-0.27l0.24-0.14l0.12-0.07l0.12-0.07c0.32-0.18,0.65-0.37,0.97-0.56c0.06-0.04,0.12-0.07,0.18-0.11
    c-0.16,0.71-0.33,1.43-0.52,2.14c-1.02,0.56-2.05,1.09-3.08,1.59c0.16-0.53,0.33-1.05,0.47-1.59c0.04-0.15,0.07-0.3,0.11-0.45
    L138.89,142.92z M134.96,158.91c-1.12,0.31-2.23,0.57-3.34,0.82c0.35-0.61,0.69-1.22,1.02-1.84c1.11-0.28,2.22-0.6,3.33-0.94
    C135.65,157.61,135.31,158.26,134.96,158.91z M129.83,156.07l-0.02,0c0.32-0.6,0.63-1.2,0.93-1.81c0.43,1.03,0.84,2.08,1.22,3.16
    c-0.33,0.62-0.67,1.24-1.03,1.85C130.59,158.19,130.23,157.12,129.83,156.07z M136.46,155.97c-1.1,0.36-2.2,0.68-3.31,0.97
    c0.32-0.62,0.64-1.25,0.94-1.89c1.09-0.33,2.19-0.7,3.28-1.1c-0.2,0.45-0.39,0.91-0.59,1.35
    C136.68,155.53,136.56,155.75,136.46,155.97z M137.81,152.95c-1.09,0.41-2.18,0.78-3.26,1.13c0.3-0.64,0.59-1.28,0.86-1.93
    c1.07-0.38,2.15-0.8,3.22-1.25c-0.21,0.54-0.4,1.08-0.62,1.61C137.95,152.66,137.88,152.8,137.81,152.95z M139.11,149.66
    c-0.03,0.07-0.06,0.14-0.08,0.22c-1.06,0.46-2.13,0.88-3.2,1.28l0.24-0.59l0.27-0.69l0.03-0.09l0.03-0.09l0.06-0.17l0.13-0.35
    c0,0,0,0,0,0c1.05-0.43,2.1-0.9,3.15-1.4C139.54,148.42,139.34,149.04,139.11,149.66z M136.86,145.86
    c-0.03,0.1-0.06,0.19-0.09,0.29c-0.18,0.57-0.39,1.13-0.58,1.7c-0.54-0.99-1.1-1.97-1.68-2.92c0,0,0,0-0.01,0
    c0.2-0.64,0.38-1.29,0.56-1.94C135.68,143.92,136.28,144.88,136.86,145.86z M140.09,146.75c-1.05,0.52-2.1,0.99-3.16,1.44
    c0.21-0.59,0.42-1.19,0.62-1.79c0.02-0.07,0.04-0.15,0.07-0.22c1.03-0.48,2.06-1,3.09-1.55
    C140.51,145.34,140.31,146.05,140.09,146.75z M136.01,140.32c0.21,0.29,0.42,0.59,0.63,0.89l0.08,0.11l0.08,0.11l0.15,0.23
    l0.31,0.45l0.15,0.23l0.15,0.23l0.17,0.26c-0.05,0.18-0.08,0.35-0.13,0.53c-0.14,0.5-0.29,1-0.44,1.5
    c-0.59-0.97-1.19-1.92-1.81-2.84c0,0-0.01,0-0.01,0c0.17-0.66,0.33-1.31,0.48-1.98C135.87,140.14,135.94,140.23,136.01,140.32z
     M134.19,145.88c0.57,0.96,1.13,1.95,1.66,2.96c-0.01,0.02-0.01,0.04-0.02,0.06l-0.13,0.34l-0.06,0.17l-0.03,0.09l-0.03,0.08
    l-0.26,0.68l-0.22,0.55c-0.5-1.02-1.01-2.03-1.55-3.01c0,0-0.01,0-0.01,0c0.02-0.05,0.04-0.09,0.05-0.14
    C133.8,147.07,133.99,146.48,134.19,145.88z M133.17,148.73c0.53,0.99,1.04,2,1.52,3.04c-0.27,0.64-0.56,1.28-0.85,1.92
    c-0.45-1.04-0.91-2.07-1.4-3.07c0,0-0.01,0-0.01,0c0.05-0.12,0.1-0.23,0.15-0.35C132.78,149.76,132.97,149.24,133.17,148.73z
     M132.02,151.53c0.48,1.01,0.94,2.04,1.37,3.1c-0.3,0.63-0.62,1.26-0.94,1.89c-0.4-1.06-0.81-2.11-1.25-3.14l-0.02,0
    c0.09-0.18,0.18-0.36,0.27-0.55C131.65,152.4,131.83,151.96,132.02,151.53z M129.34,156.95c0.38,1.05,0.74,2.12,1.07,3.21
    c-0.36,0.6-0.73,1.21-1.11,1.8c-0.3-1.1-0.61-2.19-0.96-3.25c-0.01,0-0.01,0-0.02,0c0.18-0.3,0.37-0.6,0.55-0.91
    C129.03,157.52,129.18,157.23,129.34,156.95z M127.66,169.9c-1.16,0.09-2.3,0.15-3.44,0.18c0.18-0.21,0.37-0.41,0.55-0.63
    c0.27-0.32,0.53-0.66,0.8-0.99c1.15-0.07,2.31-0.17,3.47-0.3C128.59,168.74,128.13,169.32,127.66,169.9z M123.16,166.15
    c-0.01,0-0.02,0-0.02,0c0.43-0.53,0.85-1.05,1.26-1.59c0.23,1.09,0.43,2.19,0.61,3.31c-0.28,0.35-0.56,0.71-0.85,1.05
    c-0.16,0.19-0.34,0.38-0.5,0.57C123.51,168.36,123.35,167.25,123.16,166.15z M126.92,165.38c-0.42,0.57-0.84,1.13-1.27,1.68
    c-0.19-1.12-0.4-2.23-0.65-3.31c-0.01,0-0.02,0-0.02,0c0.4-0.55,0.8-1.1,1.18-1.66C126.44,163.18,126.69,164.27,126.92,165.38z
     M126.71,161.27c0.38-0.57,0.74-1.14,1.1-1.71c0.33,1.07,0.64,2.15,0.92,3.25c-0.39,0.59-0.78,1.17-1.19,1.74
    c-0.24-1.11-0.5-2.21-0.8-3.28C126.72,161.27,126.72,161.27,126.71,161.27z M129.68,167.28c-1.15,0.15-2.29,0.25-3.43,0.34
    c0.43-0.55,0.86-1.11,1.27-1.68c1.14-0.12,2.29-0.28,3.44-0.46C130.55,166.09,130.11,166.68,129.68,167.28z M131.57,164.58
    c-1.14,0.2-2.27,0.36-3.4,0.5c0.41-0.57,0.8-1.15,1.19-1.74c1.13-0.17,2.27-0.39,3.41-0.62
    C132.38,163.34,131.98,163.96,131.57,164.58z M122.47,166.98c0.01-0.02,0.03-0.03,0.04-0.05c0.18,1.1,0.33,2.21,0.45,3.33
    c-0.47,0.52-0.94,1.04-1.43,1.55c-0.09-1.13-0.19-2.25-0.33-3.35c-0.01,0-0.01,0-0.02,0C121.61,167.97,122.05,167.49,122.47,166.98
    z M112.07,180.08c0.13-1.12,0.23-2.25,0.3-3.36l0,0c0.53-0.41,1.05-0.84,1.57-1.27c-0.03,1.12-0.09,2.25-0.18,3.39
    C113.21,179.26,112.64,179.67,112.07,180.08z M114.2,179.52c1.12,0.2,2.26,0.36,3.41,0.5c-0.57,0.47-1.13,0.93-1.71,1.38
    c-1.15-0.18-2.29-0.39-3.41-0.63C113.07,180.37,113.64,179.95,114.2,179.52z M114.72,174.81
    C114.72,174.81,114.71,174.81,114.72,174.81c0.51-0.44,1.01-0.89,1.51-1.34c0.02,1.12,0.02,2.24-0.02,3.38
    c-0.1,0.09-0.2,0.18-0.31,0.27c-0.43,0.36-0.88,0.71-1.32,1.06C114.65,177.05,114.7,175.92,114.72,174.81z M119.06,175.42
    c1.14,0.09,2.3,0.15,3.46,0.18c-0.52,0.52-1.05,1.03-1.59,1.53c-1.16-0.07-2.31-0.18-3.45-0.31
    C118.01,176.36,118.54,175.9,119.06,175.42z M119.14,170.68c-0.01,0-0.01,0-0.02,0c0.47-0.48,0.94-0.97,1.4-1.47
    c0.13,1.11,0.23,2.22,0.29,3.35c-0.49,0.5-0.99,0.99-1.5,1.48C119.28,172.91,119.23,171.79,119.14,170.68z M118.42,171.39
    c0.07,1.11,0.12,2.24,0.14,3.37c-0.51,0.48-1.04,0.94-1.56,1.4c0.02-1.13,0.02-2.26-0.02-3.37l-0.01,0
    C117.46,172.33,117.94,171.87,118.42,171.39z M111.56,177.31c-0.08,1.12-0.19,2.25-0.34,3.38c-0.57,0.4-1.16,0.78-1.74,1.16
    c0.18-1.12,0.34-2.23,0.45-3.34c0,0,0,0,0,0l0.23-0.16C110.63,178.02,111.09,177.66,111.56,177.31z M108.42,179.54l0.58-0.39
    l0.09-0.06c-0.13,1.11-0.29,2.22-0.49,3.33c-0.59,0.37-1.19,0.74-1.8,1.09c0.23-1.11,0.44-2.22,0.61-3.32l-0.01,0
    c0.14-0.09,0.29-0.17,0.43-0.27C108.03,179.79,108.22,179.67,108.42,179.54z M105.44,181.39c0.37-0.22,0.74-0.45,1.11-0.68
    c-0.19,1.1-0.4,2.21-0.65,3.32c-0.61,0.35-1.23,0.69-1.85,1.01c0.28-1.1,0.55-2.2,0.77-3.29c-0.01,0-0.01,0-0.02-0.01
    C105.01,181.62,105.23,181.51,105.44,181.39z M103,182.75c0.32-0.17,0.62-0.35,0.94-0.52c-0.24,1.09-0.5,2.19-0.8,3.28
    c-0.63,0.32-1.26,0.64-1.9,0.93c0.34-1.09,0.65-2.17,0.92-3.26c-0.01,0-0.01-0.01-0.02-0.01C102.42,183.03,102.71,182.9,103,182.75
    z M100.49,184c0.26-0.12,0.5-0.25,0.76-0.38c-0.29,1.08-0.6,2.16-0.96,3.24c-0.58,0.26-1.17,0.53-1.76,0.77
    c-0.06,0.02-0.12,0.05-0.18,0.07c0.39-1.07,0.75-2.14,1.07-3.21c-0.01,0-0.01-0.01-0.02-0.01
    C99.77,184.32,100.13,184.17,100.49,184z M97.29,185.41c0.22-0.09,0.43-0.18,0.65-0.27c0.19-0.08,0.38-0.17,0.57-0.25
    c-0.34,1.06-0.7,2.12-1.11,3.18c-0.52,0.21-1.04,0.42-1.57,0.61l-0.34,0.12c-0.02,0.01-0.04,0.02-0.07,0.02
    c0.44-1.05,0.85-2.1,1.23-3.16c0,0,0,0,0,0l0,0L97.29,185.41z M95.33,186.17l0.33-0.12c0.01,0,0.02-0.01,0.03-0.01
    c-0.39,1.05-0.81,2.09-1.26,3.14l-0.67,0.22c-0.23,0.08-0.46,0.15-0.69,0.22c-0.21,0.07-0.42,0.12-0.63,0.18
    c0.48-1.02,0.95-2.05,1.37-3.08c-0.01,0-0.01-0.01-0.02-0.01C94.3,186.53,94.82,186.36,95.33,186.17z M92.68,187.08
    c0.05-0.02,0.1-0.04,0.15-0.05c-0.44,1.02-0.9,2.04-1.4,3.06c-0.39,0.11-0.78,0.23-1.17,0.34c-0.29,0.08-0.58,0.14-0.86,0.21
    c0.53-1,1.04-2,1.51-3.01l-0.02-0.01C91.49,187.44,92.09,187.27,92.68,187.08z M86.43,192.15c0.69-0.14,1.38-0.29,2.07-0.45
    c0.95,0.65,1.93,1.27,2.93,1.88c-0.72,0.19-1.44,0.37-2.16,0.54C88.3,193.48,87.35,192.82,86.43,192.15z M89.94,187.9
    c-0.48,1-0.99,2-1.54,2.99c-0.68,0.16-1.37,0.31-2.06,0.45c0.58-0.97,1.13-1.95,1.65-2.94c-0.01,0-0.01-0.01-0.02-0.01
    C88.63,188.23,89.29,188.07,89.94,187.9z M83.87,190.72c0.19-0.31,0.39-0.62,0.58-0.93c0.05-0.08,0.09-0.16,0.14-0.23l0.14-0.23
    l0.17-0.28c0.71-0.13,1.41-0.27,2.11-0.43c-0.53,0.98-1.09,1.95-1.69,2.92c-0.24,0.05-0.49,0.1-0.73,0.14l-0.36,0.06l-0.18,0.03
    l-0.18,0.03l-0.72,0.11l-0.06,0.01c0.06-0.09,0.12-0.18,0.18-0.27C83.48,191.34,83.67,191.03,83.87,190.72z M85.26,194.38
    c-0.3-0.22-0.6-0.45-0.89-0.68c-0.3-0.23-0.59-0.46-0.88-0.69c-0.11-0.09-0.23-0.18-0.34-0.27l0.12-0.02l0.73-0.12l0.18-0.03
    l0.18-0.03l0.36-0.06c0.22-0.04,0.44-0.09,0.66-0.13c0.92,0.69,1.86,1.36,2.83,2.01c-0.77,0.17-1.55,0.33-2.33,0.47l-0.18-0.13
    l-0.23-0.17C85.41,194.49,85.33,194.43,85.26,194.38z M92.47,193.28c-1-0.59-1.98-1.2-2.93-1.83c0.31-0.08,0.63-0.15,0.94-0.23
    c0.37-0.1,0.73-0.21,1.1-0.32c0.98,0.6,1.99,1.18,3.02,1.74c-0.04,0.01-0.08,0.03-0.12,0.04
    C93.81,192.89,93.14,193.08,92.47,193.28z M97.37,191.68c-0.58,0.21-1.17,0.41-1.75,0.61c-1.03-0.54-2.03-1.11-3.01-1.69
    c0.23-0.07,0.47-0.13,0.7-0.2c0.23-0.07,0.47-0.15,0.7-0.22l0.62-0.21c1,0.55,2.03,1.08,3.08,1.59L97.37,191.68z M100.21,190.56
    c-0.24,0.1-0.47,0.2-0.71,0.29l-0.71,0.28l-0.06,0.02c-1.05-0.49-2.08-1.01-3.09-1.55c0.04-0.01,0.07-0.03,0.11-0.04l0.35-0.12
    c0.51-0.18,1-0.39,1.51-0.58c1.03,0.51,2.09,0.98,3.16,1.44C100.58,190.39,100.4,190.48,100.21,190.56z M103,189.31
    c-0.41,0.19-0.83,0.37-1.25,0.56c-1.07-0.44-2.12-0.91-3.14-1.39c0.08-0.03,0.16-0.06,0.24-0.09c0.57-0.24,1.13-0.49,1.7-0.74
    c1.05,0.46,2.13,0.88,3.22,1.29C103.51,189.06,103.26,189.19,103,189.31z M105.74,187.95c-0.33,0.17-0.67,0.33-1,0.5
    c-1.09-0.39-2.15-0.8-3.2-1.24c0,0,0.01,0,0.01,0c0.63-0.29,1.25-0.61,1.88-0.92c1.07,0.41,2.16,0.78,3.27,1.14
    C106.37,187.59,106.06,187.78,105.74,187.95z M108.41,186.46c-0.25,0.15-0.51,0.28-0.76,0.42c-1.11-0.34-2.19-0.7-3.26-1.09
    c0.62-0.33,1.24-0.67,1.85-1.01c1.09,0.35,2.19,0.68,3.32,0.98C109.17,185.99,108.79,186.24,108.41,186.46z M111.65,184.45
    c-0.21,0.14-0.43,0.28-0.64,0.42c-0.17,0.11-0.35,0.21-0.53,0.33c-1.13-0.28-2.23-0.6-3.32-0.94c0.61-0.36,1.21-0.72,1.81-1.1
    c1.11,0.3,2.24,0.58,3.38,0.83l-0.06,0.04L111.65,184.45z M113.55,183.15l-0.3,0.21c-1.14-0.23-2.27-0.5-3.38-0.79
    c0.59-0.39,1.18-0.77,1.76-1.18c1.11,0.25,2.24,0.47,3.39,0.66C114.53,182.43,114.04,182.8,113.55,183.15z M118.47,179.33
    c-1.16-0.12-2.31-0.28-3.44-0.47c0.47-0.37,0.94-0.73,1.39-1.12c0.09-0.07,0.17-0.15,0.25-0.22c1.13,0.15,2.28,0.26,3.44,0.34
    C119.58,178.36,119.03,178.85,118.47,179.33z M123.28,174.83c-1.16-0.02-2.31-0.07-3.46-0.14c0.51-0.49,1.01-0.98,1.51-1.48
    c1.15,0.04,2.31,0.04,3.48,0.02C124.31,173.76,123.8,174.3,123.28,174.83z M125.53,172.41c-1.16,0.04-2.31,0.04-3.46,0.02
    c0.49-0.51,0.96-1.03,1.43-1.55c1.15-0.01,2.31-0.07,3.48-0.14c0,0-0.01,0.01-0.01,0.01C126.51,171.31,126.02,171.86,125.53,172.41
    z M133.33,161.78c-1.13,0.25-2.25,0.47-3.37,0.66c0.38-0.59,0.74-1.19,1.1-1.79c1.12-0.23,2.25-0.49,3.38-0.78
    c-0.16,0.29-0.31,0.58-0.47,0.87C133.76,161.09,133.54,161.43,133.33,161.78z"
        fill="#FBCC34"
      />
      <circle cx="73.47" cy="193.04" fill="#FBCC34" r="3.22" />
    </g>
    <text
      fill="#FFFFFF"
      font-family="'ArialMT'"
      font-size="8px"
      id="expiry"
      transform="matrix(1 0 0 1 265.7864 202.5906)"
    >
      12.12.2012
    </text>
    <text
      fill="#FFFFFF"
      font-family="'ArialMT'"
      font-size="7px"
      id="order-id"
      transform="matrix(1 0 0 1 195.1691 203.2975)"
    >
      #{{ orderNumber }}
    </text>
    <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="36.85" width="130.76" x="265.76" y="4.31" />
    <g id="back-text-area-7">
      <rect fill="none" height="61.31" width="124.79" x="270.89" y="8.45" />
      <text id="back-text-container" transform="matrix(1 0 0 1 270.894 13.4312)" />
    </g>
    <path
      d="M25.58,160.31c12.37,15.57,29.36,25.19,48.12,25.19s35.75-9.62,48.12-25.19"
      fill="none"
      id="text4_x5F_1_x5F_"
    />
    <text id="text4">
      <textPath startOffset="50%" text-anchor="middle" xlink:href="#text4_x5F_1_x5F_">
        <tspan font-family="Bebas Neue" font-size="15px">{{ tagline1 }}</tspan>
      </textPath>
    </text>
    <path d="M29.51,131.49h88.38" fill="none" id="text3_7_x5F_1_x5F_" />
    <text id="text3_7">
      <textPath startOffset="50%" text-anchor="middle" xlink:href="#text3_7_x5F_1_x5F_">
        <tspan font-family="Bebas Neue" font-size="100px">{{ tagline3 }}</tspan>
      </textPath>
    </text>
    <g id="text2_7">
      <path d="M19.92,151.27h107.55" fill="none" id="SVGID_x5F_3_x5F_" />
      <text>
        <textPath startOffset="50%" text-anchor="middle" xlink:href="#SVGID_x5F_3_x5F_">
          <tspan font-family="Bebas Neue" font-size="15px">{{ tagline2 }}</tspan>
        </textPath>
      </text>
    </g>
    <path d="M25.26,76.49C26.34,50.28,46.78,29,73.33,29s49.17,21.29,48.07,47.5" fill="none" id="text1_x5F_1_x5F_7" />
    <text id="text1_7">
      <textPath startOffset="50%" text-anchor="middle" xlink:href="#text1_x5F_1_x5F_7">
        <tspan font-family="Bebas Neue" font-size="15px">{{ title }}</tspan>
      </textPath>
    </text>
  </svg>
</template>

<script>
import { BeerLabelBaseMixin } from '@/components/labels/utils'
import { createStyle } from '@/assets/styles/StyleBebasNeue'

export default {
  mixins: [BeerLabelBaseMixin],

  computed: {
    style() {
      return createStyle()
    },
  },
}
</script>
